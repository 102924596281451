import React from "react";
import Layout from "../../components/Layouts/Layouts/Layout";
import Layout2 from "../../components/Layouts/Layouts/Layout2";
import DashboardHeader from "../../components/basic/DashboardHeader";
import { Row, Col, Card, Container,Button } from "react-bootstrap";
import collabimg1 from "../../../src/assets/CollobrationPanel/Collobration.png";
import collabimg2 from "../../../src/assets/CollobrationPanel/CollabImg1.png";
import collabimg3 from "../../../src/assets/CollobrationPanel/CollabImg2.png";
import collabimg4 from "../../../src/assets/CollobrationPanel/CollabImg3.png";


const CollaborationPortal = () => {
  const cardDetails = [
    {
      id: 1,
      date: "5th-6th October 2023",
      colour: "blue",
      imgUrl:  collabimg1 ,
    },
    {
        id: 2,
        date: "October 04,05, 2023",
        colour: "blue",
        imgUrl:  collabimg2 ,
      },
      {
        id: 3,
        date: "November 07-09, 2023",
        colour: "blue",
        imgUrl:  collabimg3 ,
      },
      {
        id: 4,
        date: "November 7-9, 2023",
        colour: "blue",
        imgUrl:  collabimg4 ,
      },
      
      
  ];

  return (
    <Layout>
      <Layout2>
        <DashboardHeader />
        <h4 className="mt-3">
          Collaboration Portal
        </h4>
              <hr />
        <div>
          <Row xs={1} md={2} className="g-4">
            {cardDetails.map((details) => (
              <Col key={details.id} sm={2} lg={4} >
                <Card className="text-center d-flex justify-content-center align-items-center shadow-sm">
                  <Card.Img variant="top" src={details.imgUrl} className="img-responsive h-75  p-2" />
                  <Card.Body
                  >
                    <Card.Text
                      className="font-weight-bold"
                      style={{ fontSize: "15px" }}
                    >
                      {details.date}
                    </Card.Text>
                    <Button
                      variant="light"
                      size="sm"
                      style={{ marginTop: "10px" }}
                    >
                      Read More
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
          
              {/* <Row>
              {cardDetails.map((details) => (
            <Col sm={6} xs={12} md={2} xl={3} lg={3} className="my-2" >
                <Card
                  key={details.id}
                  className="text-center shadow-sm"
                >
                 
                    <Card.Img
                      src={details.imgUrl}
                      alt="Company logo"
                      className="img-responsive p-2 card-img"
                      style={{
                        height: "178px",
                      }}
                    />
             
                  <Card.Body
                  >
                    <Card.Text
                      className="font-weight-bold"
                      style={{ fontSize: "14px" }}
                    >
                      {details.date}
                    </Card.Text>
                    <Button
                      variant="light"
                      size="sm"
                      style={{ marginTop: "10px" }}
                    >
                      Read More
                    </Button>
                  </Card.Body>
                </Card>
            </Col>
              ))}
          </Row> */}
        </div>
      </Layout2>
    </Layout>
  );
};

export default CollaborationPortal;
