import React, { useState } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import Layout2 from "../../../components/Layouts/Layouts/Layout2";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

const agreements = [
  {
    srNo: 1,
    agreementId: "A23-24001",
    title: "M/s. Samson Scientifics and Surgicals Pvt. Ltd. and M/s. Fahad Abdul Rauof Batterjee Co. Ltd. (Distribution Agency Agreement)",
    version: "1'-23",
    printId: "AECCI-4234/23",
    matterNumber: "--",
    industry: "Pharmaceutical products",
    operativeDate: "6th July, 2020",
    expiryDate: "--",
  },
  {
    srNo: 2,
    agreementId: "A23-24002",
    title: "M/s. Sirmaxo Chemicals Pvt. Ltd. and M/s. Egyptian German Company (Agency Contract)",
    version: "1-23",
    printId: "AECCI-1381/23",
    matterNumber: "--",
    industry: "Pharmaceutical & Health care products",
    operativeDate: "The validity of contract is 3 years",
    expiryDate: "--",
  },
  {
    srNo: 3,
    agreementId: "A23-24003",
    title: "M/s. Sirmaxo Chemicals Pvt. Ltd. and M/s. Egyptian German Company (Agent Agreement)",
    version: "1-23",
    printId: "AECCI-1382/23",
    matterNumber: "--",
    industry: "Pharmaceutical & Health care products",
    operativeDate: "13th December, 2021",
    expiryDate: "12th December, 2024",
  },
  {
    srNo: 4,
    agreementId: "A23-24004",
    title: "M/s. Daxal Cosmetics Pvt. Ltd. and M/s. ISW Vendors SRL",
    version: "1-23",
    printId: "AECCI-2021/23",
    matterNumber: "--",
    industry: "Cosmetic products",
    operativeDate: "8th March, 2023",
    expiryDate: "7th March, 2033",
  },
  {
    srNo: 5,
    agreementId: "A23-24005",
    title: "M/s. Geetanjali Woollens Pvt. Ltd. and Mr. Abraham Gebrekirstos GebreMedhin (General POA)",
    version: "1-23",
    printId: "AECCI-1056/23",
    matterNumber: "--",
    industry: "Woollen / Textile",
    operativeDate: "29th April, 2023",
    expiryDate: "Lifetime",
  },
  {
    srNo: 6,
    agreementId: "A23-24006",
    title: "M/s. S V International Holdings Ltd. and Mr. Abraham Gebrekirstos GebreMedhin (General POA)",
    version: "1-23",
    printId: "AECCI-1057/23",
    matterNumber: "--",
    industry: "Stocks, Share, Annuities & Finance",
    operativeDate: "29th April, 2023",
    expiryDate: "Lifetime",
  },
  {
    srNo: 7,
    agreementId: "A23-24007",
    title: "M/s. Aai Maa Khodiyar Industries and M/s. Yizang Trading Co. Ltd.",
    version: "1-23",
    printId: "AECCI-1381/23",
    matterNumber: "--",
    industry: "Crops",
    operativeDate: "04th May, 2023",
    expiryDate: "03rd May, 2026",
  },
  {
    srNo: 8,
    agreementId: "A23-24008",
    title: "M/s. Aruhi Life Sciences Private Limited and M/s. Aster Orion Pharma for Import (Agent Agreement)",
    version: "1-23",
    printId: "AECCI-1381/23",
    matterNumber: "--",
    industry: "Pharmaceutical products",
    operativeDate: "03rd December, 2023",
    expiryDate: "2nd December, 2026",
  }

  // Add more agreements here
];


const ListOfAgreement = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  // Calculate the number of pages
  const totalPages = Math.ceil(agreements.length / rowsPerPage);

  // Get the current rows to display
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = agreements.slice(indexOfFirstRow, indexOfLastRow);

  // Handle page change
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <Layout>
      <Layout2>
        <DashboardHeader />
        <Container className="py-4">
          <Row>
            <Col>
              <h4 className="mb-3">LIST OF AGREEMENTS</h4>
              <hr />
              <div className="text-secondary fw-bold">From 1st April 2023</div>
              <span>Report generated on 13-08-2024</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="table-responsive">
                <Table striped bordered hover className="mt-4" style={{ minWidth: '1200px' }}>
                  <thead className="thead-light">
                    <tr>
                      <th className="text-center">Sr No.</th>
                      <th className="text-center">Agreement ID</th>
                      <th className="text-center">Agreement Title</th>
                      <th className="text-center">Version</th>
                      <th className="text-center">Print ID</th>
                      <th className="text-center">Matter Number</th>
                      <th className="text-center">Industry</th>
                      <th className="text-center">Operative Date</th>
                      <th className="text-center">Expiry Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentRows.map((agreement) => (
                      <tr key={agreement.srNo}>
                        <td className="text-center">{agreement.srNo}</td>
                        <td className="text-center">{agreement.agreementId}</td>
                        <td>{agreement.title}</td>
                        <td className="text-center">{agreement.version}</td>
                        <td className="text-center">{agreement.printId}</td>
                        <td className="text-center">{agreement.matterNumber}</td>
                        <td className="text-center">{agreement.industry}</td>
                        <td className="text-center">{agreement.operativeDate}</td>
                        <td className="text-center">{agreement.expiryDate}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <div className="d-flex justify-content-between mt-3">
                <Button
                  variant="primary"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </Button>
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <Button
                  variant="primary"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout2>
    </Layout>
  );
};

export default ListOfAgreement;