import React from "react";
import { useState } from "react";
import { Row, Col, Modal, Button, Card, Accordion } from "react-bootstrap";
import Layout from "../../../components/Layouts/Layouts/Layout";

import Layout2 from "../../../components/Layouts/Layouts/Layout2";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import { FaFileAlt } from "react-icons/fa";
import { LuAlignJustify } from "react-icons/lu";
import AECCIIAC from "../../../assets/PanelImages/AECCIIAC.pdf";
const RulesPolicies = () => {
  const [showCertificateModal, setShowCertificateModal] = useState(false);
  const [showPdfModal, SetShowPdfModal] = useState(false);
  const [showRulesModal, setShowRulesModal] = useState(false);
  const [showFeesModal, setShowFeesModal] = useState(false);

  const openRulesModal = () => {
    setShowRulesModal(true);
  };

  const openFeemodal = () => {
    setShowFeesModal(true);
  };

  // Function to open the modal and set certificate URL
  const openCertificateModal = () => {
    setShowCertificateModal(true);
  };

  // Function to close the modal
  const closeCertificateModal = () => {
    setShowCertificateModal(false);
  };

  const closeModal = () => {
    setShowRulesModal(false);
    // setShowFormsModal(false);
    setShowFeesModal(false);
    setShowCertificateModal(false);
    // setModalContent(null);
  };

  return (
    <Layout>
      <Layout2>
        <DashboardHeader />
        <h4 className="pt-4 mb-3">RULES, FORMS & FEES</h4>
        <hr />
        <section
          id="block-rulesformsfeestableblock"
          className="block block-adr-blocks block-rules-forms-fees-table-block clearfix"
        >
          <RulesandForm
            title="Commercial Arbitration Rules and Mediation Procedures"
            openRule={() => openRulesModal()}
            openfee={() => openFeemodal()}
            openForm={() => openCertificateModal()}
          />
          <hr className="m-0" />
          <RulesandForm
            title="Construction Industry Arbitration Rules and Mediation Procedures"
            openRule={() => openRulesModal()}
            openfee={() => openFeemodal()}
            openForm={() => openCertificateModal()}
          />
          <hr className="m-0" />
          <RulesandForm title="Consumer Arbitration Rules" />
          <hr className="m-0" />
          <RulesandForm
            title="Employment Arbitration Rules and Mediation Procedures"
            openRule={() => openRulesModal()}
            openfee={() => openFeemodal()}
            openForm={() => openCertificateModal()}
          />
          <hr className="m-0" />
          <RulesandForm
            title="Labor Arbitration Rules"
            openRule={() => openRulesModal()}
            openfee={() => openFeemodal()}
            openForm={() => openCertificateModal()}
          />
          <hr className="m-0" />
          <RulesandForm
            title="International Dispute Resolution Procedures"
            openRule={() => openRulesModal()}
            openfee={() => openFeemodal()}
            openForm={() => openCertificateModal()}
          />
          <hr className="m-0" />
          <RulesandForm
            title="Consumer Mass Arbitration"
            openRule={() => openRulesModal()}
            openfee={() => openFeemodal()}
            openForm={() => openCertificateModal()}
          />
          <hr className="m-0" />
          <RulesandForm
            title="Employment Mass Arbitration"
            openRule={() => openRulesModal()}
            openfee={() => openFeemodal()}
            openForm={() => openCertificateModal()}
          />
          <hr className="m-0" />

          {/* Rules Modal */}
          {showRulesModal && (
            <RulesModal show={showRulesModal} onHide={closeModal} />
          )}

          {showFeesModal && (
            <FeeModal show={showFeesModal} onHide={closeModal} />
          )}

          {showCertificateModal && (
            <CertificateModal
              show={showCertificateModal}
              onHide={closeModal}
              certificate={AECCIIAC}
            />
          )}
        </section>
      </Layout2>
    </Layout>
  );
};

const RulesandForm = ({ title, openRule, openfee, openForm }) => {
  return (
    <div className="d-flex flex-nowrap mb-2 justify-content-between">
      <div className="col-md-6 col-sm-6">
        <p className="mt-2">{title}</p>
      </div>
      <div className="col-md-6 col-sm-6 P-1 d-flex justify-content-end">
        <div className="col-md-3 col-sm-4 col-xs-4 px-3 py-6 text-center border-right">
          <div className="d-block mt-1 mb-2 text-secondary" onClick={openRule}>
            RULES
            <br />
            <FaFileAlt  className="mt-1 text-secondary"
              style={{ fontSize: "24px" }} />{" "}
          </div>
        </div>
        <div className="col-md-3 col-sm-4 col-xs-4 px-2 py-1 text-center border-right">
          <div
            className="d-block mb-1 text-secondary text-decoration-none"
            onClick={openForm}
          >
            FORMS
            <br />
            <LuAlignJustify
              className="mt-1 text-secondary"
              style={{ fontSize: "24px" }}
            />{" "}
          </div>
        </div>
        <div className="col-md-3 col-sm-4 col-xs-4 px-2 py-1 text-center">
          <div
            className="d-block mb-1 text-secondary text-decoration-none"
            onClick={openfee}
          >
            FEES
            <br />
            <FaFileAlt
              className="mt-1 text-secondary"
              style={{ fontSize: "24px" }}
            />{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

const CertificateModal = ({ show, onHide, certificate }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <iframe
            src={certificate}
            title="Membership Certificate"
            width="100%"
            height="700px"
            frameBorder="0"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const RulesModal = ({ show, onHide }) => (
  <Modal
    show={show}
    onHide={onHide}
    size="xl"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title>Rule Document</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {" "}
      <AccordionSection />{" "}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onHide}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
);

const AccordionSection = () => {
 
  return (
    <Row xs={1} md={1} className="g-4 justify-content-center">
      {/* PART I: INTRODUCTION */}

      <Col>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div>PART I: INTRODUCTION</div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="fs-5 fw-normal mt-2 mb-2">Article 1 Introduction</div>
              <div>
                1.1 To frame, amend such Arbitration Rules to hereby govern the
                conduct of Arbitration Proceeding held at AECCI- International
                Arbitration Centre, India relating to International Commercial
                Arbitration and International Maritime Arbitration and for
                matters connected therewith or incidental thereto.{" "}
              </div>
              <div>
                {" "}
                1.2 By consenting to use the above Arbitration Rules to hereby
                govern the arbitration proceedings, to be conducted at the
                AECCI- IAC, Navi Mumbai, India the parties hereby further agree,
                acknowledge, and accept these rules or any revised rules framed
                thereafter approved by Council AECCI- IAC Navi Mumbai, India.
                And in the event of any dispute arising between a relevant rule
                and an express provision of applicable law hereby governing the
                Conduct of Arbitration Proceedings between the Parties to
                Arbitration, then the express provision of applicable/ governing
                law shall prevail over said relevant rule.{" "}
              </div>
              <div>
                {" "}
                1.3 The Arbitration Rules shall hereby deem to commence from
                such date as unanimously agreed upon by the Council AECCI- IAC.
                1.4 These Arbitration Rules shall govern any and all arbitration
                proceedings initiated on or after April, 2023, unless otherwise
                agreed upon by the Council AECCI- IAC, India to fix different of
                commencement of said Rules.
              </div>
              <div className="fs-5 fw-normal mt-2 mb-2">Article 2 Definition</div>
              <div>
                <div>
                  a. “Arbitration” means any arbitration whether or not
                  administered by permanent arbitral institution; any
                  arbitration arising out of International Disputes.
                </div>
                <div>
                  b. “Arbitration agreement” means an arbitration agreement may
                  be in the form of an arbitration clause in a contract or in
                  the form of a separate agreement.
                </div>
                <div>
                  c. “Award” includes any final decision made by the
                  arbitrator(s), including emergency arbitrators.
                </div>
                <div>
                  d. “Tribunal” means a single arbitrator or all arbitrators
                  when there is more than one and includes any tribunal formed
                  under these Rules.
                </div>
                <div>
                  e. “Council” is a group of members appointed by the Chairman
                  of the Centre and governing the AECCI-International
                  Arbitration Centre (IAC), India.
                </div>
                <div>
                  f. “Claimant” is an aggrieved person seeking relief from the
                  other party.
                </div>
                <div>
                  g. “Parties” means a party to an Arbitration Agreement and
                  parties to Proceedings.
                </div>
                <div>
                  h. “Secretary” means the Secretary for the time being
                  appointed by the Committee and includes such other persons as
                  the Council may nominate for carrying out the duties of the,
                  Secretariat under these rules.
                </div>
              </div>
              <div className="fs-5 fw-normal mt-2 mb-2">Article 3 Applicable Law</div>
              <div>
                Amendments: 1. The Tribunal shall apply the Law of Arbitration
                and Conciliation Act, 1996 and/or if the parties determine and
                agree that the arbitrator is relying on morally fair, just &
                commercially accepted rules and principles shall apply for the
                arbitration proceedings. Failing which the Tribunal shall apply
                the law and/or rules of law which it determines to be
                appropriate for an unprejudiced dictum.
              </div>
              <div>
                2. The Tribunal shall decide as amiable compositeur or ex aequo
                et bono only if the parties have expressly authorised the
                Tribunal to do so. (“Amiable compositeur or ex aequo et bono”
                means dispute settlement where parties expressly agree that the
                Arbitrator is not bound by strict rules of law but based on
                fair, just and moral dictum and commercially accepted principles
                only if the parties have expressly authorised the Tribunal to do
                so.)
              </div>
              <div>
                3. In all cases, the Tribunal shall decide in accordance with
                the terms of the contract, if any, and shall take into account
                any usage of trade applicable to the transaction to the extent
                that the Tribunal considers it relevant to the arbitration
                proceedings.
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
      {/* PART II  : ARBITRATION INVOCATION: INITIATING THE PROCESS*/}
      <Col>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div>
                PART II : ARBITRATION INVOCATION: INITIATING THE PROCESS
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="fs-5 fw-normal mt-2 mb-2">
                Article 4 Commencement of Arbitration
              </div>
              <div>
                4.1. Arbitration Agreement-
                <br /> In this Part, “arbitration agreement” means an agreement
                by the parties to submit to arbitration all or certain disputes
                which have arisen or which may arise between them in respect of
                a defined legal relationship, whether contractual or not.
                <br /> An arbitration agreement may be in the form of an
                arbitration clause in a contract or in the form of a separate
                agreement. <br />
                a. An arbitration agreement shall be in writing. <br />
                b. An arbitration agreement is in writing if it is contained in—{" "}
                <br />
                (i) a document signed by the parties; <br />
                (ii) an exchange of letters, telex, telegrams or other means of
                telecommunication [including communication through electronic
                means] which provide a record of the agreement; or <br />
                c. The reference in a contract to a document containing an
                arbitration clause constitutes an arbitration agreement if the
                contract is in writing and the reference is such as to make that
                arbitration clause part of the contract.
                <br /> 4.2. Invocation of Arbitration Proceedings – <br />
                a. Any participant seeking to initiate arbitration (“Claimant”)
                must first send a written request for Arbitration to the
                registrar/council. <br />
                b. The date on which the Request is received by the Council
                shall, for all purposes, be deemed to be the date of the
                commencement of the arbitration.
                <br /> c. The council will notify all involved parties of the
                arbitration’s scheduled commencement date.
                <br /> d. The Request for arbitration shall contain a) the name
                in full, description, address and other contact details of each
                of the parties; b) the name in full, address and other contact
                details of any person(s) representing the claimant in the
                arbitration; c) a description of the nature and circumstances of
                the dispute giving rise to the claims. <br />
                e. The Request for Arbitration should also include the Statement
                of Claim of the Claimant. <br />
                f. Within 14 days of getting the claimant’s request for
                arbitration, Defendant must submit his reply to the Claimant.
                Confirmation or denial of the claims, contact information, a
                description of the conflict and defense, nomination of a judge,
                service of copies of the answer and documents on all parties,
                and evidence of payment, of the filing fee for any complaint are
                all required in the response. Include a statement of defense,
                and a statement of counterclaim in your answer, and feel free to
                revise it as needed within the parameters of the adjudication
                agreement. <br />
                4.3 Receipt of written communications
                <br /> a. Unless otherwise agreed by the parties,— <br />• Any
                written communication is deemed to have been received if it is
                delivered to the addressee personally or at his place of
                business, habitual residence or mailing address, and <br />• If
                none of the places referred to in clause (a) can be found after
                making a reasonable inquiry, a written communication is deemed
                to have been received if it is sent to the addressee’s last
                known place of business, habitual residence or mailing address
                by registered letter or by any other means which provides a
                record of the attempt to deliver it <br />• The communication is
                deemed to have been received on the day it is so delivered.
                <br /> • This Article does not apply to written communications
                in respect of proceedings of any judicial authority.
              </div>
              <hr />
              <div className="fs-5 fw-normal mt-2 mb-2">
                Article 5 Interim measures, etc., by Council
              </div>
              <div>
                5.1A party may apply before the arbitral tribunal for interim
                relief:- <br />
                a. By submitting an application to the arbitral tribunal, with
                reasons stating therein. <br />
                b. The Tribunal may, at the request of a party, issue an order
                granting an interim edict, injunction or any other interim
                relief it deems appropriate. The tribunal may order the party
                requesting interim relief to provide appropriate security in
                connection with the relief sought.
                <br /> c. Any such measure shall take the form of an order,
                giving reasons, or of an award, as the arbitral tribunal
                considers appropriate.
                <br /> d. Before the file is transmitted to the arbitral
                tribunal, and in appropriate circumstances even thereafter, the
                parties may apply to any competent judicial authority for
                interim or conservatory measures. The application of a party to
                a judicial authority for such measures or for the implementation
                of any such measures ordered by an arbitral tribunal shall not
                be deemed to be an infringement or a waiver of the arbitration
                agreement and shall not affect the relevant powers reserved to
                the arbitral tribunal. Any such application and any measures
                taken by the judicial authority must be notified without delay
                to the Registrar and the Registrar shall inform the Arbitral
                Tribunal thereof.
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
      {/* PART III : THE ARBITRAL TRIBUNAL */}
      <Col>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div>PART III: THE ARBITRAL TRIBUNAL</div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="fs-5 fw-normal mt-2 mb-2">
                Article 6 Appointment of Arbitrator
              </div>
              <div>
                <div>6.1 Appointment of Arbitrators</div>
                <div>
                  a. A person of any nationality, location, and language may be
                  an arbitrator, unless otherwise agreed by the parties.
                </div>
                <div>
                  b. The parties are free to agree on a procedure for appointing
                  the arbitrator or arbitrators.
                </div>
                <div>
                  c. Failing any agreement referred to in sub-clause b, or
                  unless it appears to the Council of AECCI-IAC, giving due
                  regard to any proposals by the parties, the complications, the
                  proportion connected with or the relevant circumstances of the
                  dispute that the dispute warrants the appointment of three
                  arbitrators, a sole-arbitrator shall be appointed. In
                  appointing an arbitrator under these Rules, the Council at
                  AECCI-IAC shall have due consideration and appraisal to the
                  nature of the transaction, the nature, substance and
                  circumstances of the dispute, the nationality, location and
                  languages of the parties and (if more than two) the number of
                  parties. Due consideration will further be given to any
                  qualifications required by the arbitrator for the subject
                  matter pertaining to the dispute at hand. The Council at
                  AECCI-IAC shall also consider whether the arbitrator has
                  sufficient time, availability and ability to conduct the case
                  in an instantaneous and coherent manner appropriate for
                  arbitration.
                </div>
                <div>6.2 Sole Arbitrator</div>
                <div>
                  In the event that a sole arbitrator needs to be appointed,
                  either party holds the right to propose one or more
                  individuals to the other party. From these proposed names, one
                  will be selected to act as the sole arbitrator. Should the
                  parties be unable to reach an agreement on this matter, the
                  Council of AECCI-IAC, India, will step in to constitute the
                  Arbitral Tribunal, appointing a sole arbitrator. In situations
                  where the parties are unable to mutually elect an arbitrator,
                  the Council will assume responsibility for selecting a sole
                  arbitrator within a maximum period of 7 days. The entire
                  process of arbitrator selection will be completed within a
                  total timeframe not exceeding 15 days.
                </div>
                <div>6.3 Appointment of Three Arbitrators</div>
                <div>
                  If there are three arbitrators to be appointed in arbitration,
                  each party shall nominate one arbitrator, and the two
                  appointed arbitrators shall appoint the third arbitrator who
                  shall act as the presiding arbitrator.
                </div>
                <div>
                  a. If the appointment procedure in sub-clause 6.3 applies and—
                  <ul>
                    <li>
                      i. A party fails to appoint an arbitrator within thirty
                      days from the receipt of a request to do so from the other
                      party; or
                    </li>
                    <li>
                      ii. If the two appointed arbitrators fail to agree on the
                      third arbitrator within thirty days from the date of their
                      appointment; then the appointment shall be hereby made
                      subject to the Council of AECCI-IAC exercising its
                      discretionary powers.
                    </li>
                  </ul>
                </div>
                <div>
                  b. Where, under an appointment procedure agreed upon by the
                  parties—
                  <ul>
                    <li>
                      i. A party fails to act as required under that procedure;
                      or
                    </li>
                    <li>
                      ii. The parties, or the two appointed arbitrators, fail to
                      reach an agreement expected of them under that procedure;
                      or
                    </li>
                    <li>
                      iii. A person, including an institution, fails to perform
                      any function entrusted to him or it under that procedure,
                    </li>
                  </ul>
                  The Council AECCI-IAC, subject to exercising its discretionary
                  powers, shall take the necessary steps in this regard.
                </div>
              </div>

              <div className="fs-5 fw-normal mt-2 mb-2">
                Article 7 Ground for Challenge of Arbitrators
              </div>
              <div>
                <div>
                  7.1 Basis for Challenge: An arbitrator may be challenged if
                  there are doubts about their impartiality, independence, and
                  qualifications agreed upon by the parties, or if they cannot
                  fulfill their duties as required by the rules or within
                  specified timeframes.
                </div>
                <div>
                  7.2 Challenger Limited: Only the party who nominated an
                  arbitrator can challenge them based on new information arising
                  after the appointment.
                </div>
                <div>
                  7.3 Notification Period: Challengers must notify the
                  Secretariat within 14 days of learning about the grounds for
                  the challenge or of the arbitrator’s appointment.
                </div>
                <div>
                  7.4 Notice of Challenge: The challenge notice must be in
                  writing, submitted to the Registrar, and shared with the other
                  party, the challenged arbitrator, and other Tribunal members.
                </div>
                <div>
                  7.5 Consensual Challenge Resolution: If one party challenges
                  an arbitrator and the other agrees, or if the challenged
                  arbitrator voluntarily withdraws, it does not imply agreement
                  on the validity of the challenge grounds.
                </div>
                <div>
                  7.6 Substitute Arbitrator: In cases covered by Rule 7.5, a
                  substitute arbitrator follows the procedure in Rule 8, even if
                  one party failed to nominate an arbitrator during the
                  challenged arbitrator’s appointment. Time limits in Rule 8
                  start from the date of agreement or withdrawal.
                </div>
                <div>
                  7.7 Council Decision: If the other party does not agree with
                  the challenge within seven days, and the challenged arbitrator
                  doesn’t voluntarily withdraw, the Council decides. The
                  Registrar/Council may request comments/submissions, setting a
                  schedule.
                </div>
                <div>
                  7.8 Successful Challenge: When the Council upholds a
                  challenge, a substitute arbitrator is appointed, even if one
                  party failed to nominate an arbitrator during the initial
                  appointment. Time limits in Rule 8 start from the Registrar’s
                  notification of the Council’s decision.
                </div>
                <div>
                  7.9 Rejected Challenge: If the Council rejects the challenge,
                  the challenged arbitrator continues with arbitration. The
                  challenged arbitrator can proceed.
                </div>
                <div>
                  7.10 Cost Determination: The Council decides the challenge
                  costs, part of AECCI-IAC’s fees. The Registrar may request
                  cost deposits under Rule 35 setting a deadline; failure
                  results in withdrawal of the challenge.
                </div>
                <div>
                  7.11 Final Council Decision: The Council’s decision under this
                  rule is final and binding on the parties.
                </div>
              </div>

              <div className="fs-5 fw-normal mt-2 mb-2">
                Article 8 Replacement of Arbitrators
              </div>
              <div>
                <div>
                  8.1 An arbitrator can be replaced due to death, resignation, a
                  valid challenge accepted by the Council, or a joint written
                  request by all parties.
                </div>
                <div>
                  8.2 The Council can also replace an arbitrator if they are
                  legally or practically unable to fulfill their role or if they
                  are not meeting their obligations under the Rules or within
                  set timeframes.
                </div>
                <div>
                  8.3 If the Council considers replacing an arbitrator based on
                  certain information, all relevant parties have a chance to
                  provide written comments before a decision is made.
                </div>
                <div>
                  8.4 When an arbitrator is replaced, a new one is appointed
                  following the same rules as the original appointment.
                </div>
                <div>
                  8.5 The Council may waive a party’s right to nominate a
                  replacement arbitrator if they don’t do so within a specified
                  time. The reconstituted Tribunal will decide if previous
                  proceedings need to be repeated.
                </div>
                <div>
                  8.6 If the sole or presiding arbitrator is replaced, previous
                  hearings are usually held again unless the parties agree
                  otherwise. In a three-member tribunal, the decision to redo
                  prior hearings depends on the Tribunal’s discretion and
                  consultation with the parties. However, if an interim or
                  partial award has been issued, hearings related solely to that
                  award are not repeated, and the award remains in effect.
                </div>
              </div>

              <div className="fs-5 fw-normal mt-2 mb-2">
                Article 9 Appointment of Emergency Arbitrator
              </div>
              <div>
                <div>
                  9.1 A party that needs urgent interim or conservatory measures
                  that cannot await the constitution of an arbitral tribunal
                  (“Emergency Measures”) may make an application for such
                  measures pursuant to the Application made by either party
                  pursuant to Article 4. Any such application shall be accepted
                  only if it is received by the Registrar prior to the
                  transmission of the file to the arbitral tribunal irrespective
                  of whether the party making the application has already
                  submitted its Request for Arbitration.
                </div>
                <div>
                  9.2 The Application for Emergency Measures shall contain the
                  following information with all relevant documentation:
                  <ul>
                    <li>
                      a. The name in full, description, address and other
                      contact details of each of the parties;
                    </li>
                    <li>
                      b. The name in full, address and other contact details of
                      any person(s) representing the applicant;
                    </li>
                    <li>
                      c. A description of the circumstances giving rise to the
                      Application and of the underlying dispute referred or to
                      be referred to arbitration;
                    </li>
                    <li>d. A statement of the Emergency Measures sought;</li>
                    <li>
                      e. The reasons why the applicant needs urgent interim or
                      conservatory measures that cannot await the constitution
                      of an arbitral tribunal;
                    </li>
                    <li>
                      f. Any relevant agreements and, in particular, the
                      arbitration agreement containing provisions for
                      appointment of Emergency Arbitrator to seek interim or
                      conservatory measures;
                    </li>
                    <li>
                      g. Proof of payment of the Emergency Arbitrator Fees
                      referred to in Schedule of Fees annexed to said Rules and
                      any Request for Arbitration and any other submissions in
                      connection with the underlying dispute, which have been
                      filed with the Registrar by any of the parties to the
                      emergency arbitrator proceedings prior to the making of
                      the said Application.
                    </li>
                  </ul>
                  The Council at AECCI-IAC shall determine the application as
                  soon as possible in the circumstances and if granted shall
                  seek to appoint an Emergency Arbitrator within one business
                  day of receipt by the Registrar. The emergency arbitrator’s
                  decision shall take the form of an order. The parties
                  undertake to comply with any order made by the emergency
                  arbitrator.
                </div>
                <div>
                  9.3 The emergency arbitrator’s order shall not bind the
                  arbitral tribunal with respect to any question, issue or
                  dispute determined in the order. The arbitral tribunal may
                  modify, terminate or annul the order or any modification
                  thereto made by the emergency arbitrator.
                </div>
                <div>
                  9.4 The arbitral tribunal shall decide upon any party’s
                  requests or claims related to the emergency arbitrator
                  proceedings, including the reallocation of the costs of such
                  proceedings and any claims arising out of or in connection
                  with the compliance or noncompliance with the order.
                </div>
                <div>
                  9.5 The Emergency Arbitrator Provisions shall not apply if:
                  <ul>
                    <li>
                      a. The arbitration agreement under the Rules was concluded
                      prior to date of commencement of said Arbitration Rules of
                      AECCI-IAC Navi Mumbai, India
                    </li>
                    <li>
                      b. The parties have agreed to opt out of the Emergency
                      Arbitrator Provisions; or
                    </li>
                    <li>
                      c. The arbitration agreement upon which the application is
                      based arises from an International treaty to which India
                      is not a signatory.
                    </li>
                  </ul>
                </div>
                <div>
                  9.6 The Emergency Arbitrator Provisions are not intended to
                  prevent any party from obtaining urgent interim or
                  conservatory measures from a competent judicial authority at
                  any time prior to making an application for such measures,
                  under the said Rules and in appropriate circumstances even
                  thereafter, pursuant to the Rules. Any application to obtain
                  any interim or conservatory measures from a competent judicial
                  authority shall not be deemed to be an infringement or a
                  waiver of the arbitration agreement. Any such application and
                  any measures taken by the judicial authority must be notified
                  without delay to the Council AECCI-IAC.
                </div>
              </div>

              <div className="fs-5 fw-normal mt-2 mb-2">
                Article 10 Conduct of the Arbitral Tribunal
              </div>
              <div>
                <div>10.1 Independence, Impartiality, and Availability</div>
                <div>
                  a. Any arbitrator chosen by either the parties or the council
                  must maintain an impartial and independent stance in relation
                  to the parties engaged in the arbitration and provide his
                  disclosure in writing to the parties.
                </div>
                <div>
                  b. Prior to their appointment or confirmation, a prospective
                  arbitrator is required to sign a statement confirming their
                  acceptance, availability, impartiality, and independence. The
                  prospective arbitrator must also disclose in writing to the
                  Secretariat any facts or circumstances that could potentially
                  raise doubts about their independence in the eyes of the
                  involved parties or give rise to reasonable concerns about
                  their impartiality.
                </div>
                <div>
                  c. If, at any point during the arbitration proceedings, the
                  arbitrator becomes aware of information that could reasonably
                  cast doubt on their neutrality or independence, they must
                  promptly inform the parties, fellow arbitrators, and the
                  registry about such information.
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
      {/* PART IV : ARBITRATION PROCESS: A COMPREHENSIVE OVERVIEW*/}

      <Col>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div>PART IV: ARBITRATION PROCESS: A COMPREHENSIVE OVERVIEW</div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="fs-5 fw-normal mt-2 mb-2">
                Article 11 Seat and Venue of Arbitration
              </div>
              <div>
                <div>11.1 Seat of Arbitration</div>
                <div>
                  The designated arbitration venue shall be India. In the
                  absence of any pre-established agreement, the choice of
                  arbitration location shall be determined by the AECCI-IAC
                  council, taking into consideration the circumstances of the
                  case and the convenience of the involved parties. Irrespective
                  of the conditions outlined in sub-Article (a), the AECCI-IAC
                  council located in Navi Mumbai, India, unless otherwise agreed
                  upon by the parties, reserves the right to convene at any
                  location it deems appropriate for member consultations,
                  witness testimonies, expert examinations, parties’
                  interactions, or the examination of documents, merchandise, or
                  other assets.
                </div>
                <div>11.2 Venue Arbitration</div>
                <div>
                  The Venue of Arbitration shall be in Navi Mumbai, India, since
                  one of the parties is of Indian origin.
                </div>
              </div>

              <div className="fs-5 fw-normal mt-2 mb-2">
                Article 12 Consolidation Mechanism
              </div>
              <div>
                <div>
                  12.1 The AECCI-IAC Council in India has the authority, upon
                  request from either party involved in arbitration proceedings,
                  submitted in writing through one of the specified methods in
                  Article 2, to combine two or more ongoing arbitration cases
                  into a single arbitration proceeding when:
                  <ul>
                    <li>
                      a. All parties have provided written consent for
                      consolidation.
                    </li>
                    <li>
                      b. All claims in the arbitrations stem from the same
                      contract or contracts.
                    </li>
                    <li>
                      c. The claims in the arbitrations do not originate from
                      the same contract or contracts, but the arbitrations
                      involve the same parties, the disputes are related to the
                      same legal relationship, and the AECCI-IAC Council in Navi
                      Mumbai, India, upon reviewing the arbitration agreements,
                      deems them compatible.
                    </li>
                  </ul>
                </div>
                <div>
                  12.2 Following the receipt of a written consolidation request
                  from either party, the AECCI-IAC Council in Navi Mumbai,
                  India, during a duly convened meeting, shall either approve or
                  reject the consolidation request while documenting detailed
                  reasons in writing. This decision takes into account:
                  <ul>
                    <li>
                      a. The specific facts and circumstances necessitating
                      consolidation, which may vary from case to case.
                    </li>
                    <li>
                      b. Whether the interests of all parties involved are
                      adequately protected through consolidation.
                    </li>
                    <li>
                      c. Whether consolidation is essential to ensure justice
                      for all parties in the proceedings.
                    </li>
                    <li>
                      d. Whether the subject matter of the arbitration
                      proceedings allows for consolidation.
                    </li>
                    <li>
                      e. Whether the initiation of consolidation proceedings
                      will not adversely affect the rights of any parties,
                      whether contractual or otherwise.
                    </li>
                  </ul>
                </div>
                <div>
                  12.3 The AECCI-IAC Council, exercising its discretionary
                  powers, will also determine whether the same arbitrator will
                  continue or if a new arbitrator should be appointed for the
                  consolidated hearing.
                </div>
                <div>
                  12.4 Additionally, the AECCI-IAC Council, using its
                  discretionary authority, will decide whether all arbitration
                  proceedings should be consolidated into the arbitration
                  proceeding that commenced first.
                </div>
                <div>
                  12.5 The decision made by the AECCI-IAC Council is final,
                  conclusive, and binding upon all parties involved in the
                  arbitration regarding the conduct of consolidation
                  proceedings, subject to the parties’ payment of the specified
                  fees as outlined in the Schedule of Fees (Article 34).
                </div>
              </div>

              <div className="fs-5 fw-normal mt-2 mb-2">
                Article 13 Conduct of the Arbitration
              </div>
              <div>
                <div>
                  13.1 Considering the intricacy and significance of the
                  dispute, the arbitration process should prioritize efficiency
                  and cost-effectiveness, involving both the arbitral tribunal
                  and the involved parties.
                </div>
                <div>
                  13.2 The arbitral tribunal must, in consultation with the
                  parties, implement any necessary procedural measures for
                  effective case management, as long as they do not contradict
                  any prior agreements made by the parties. These measures may
                  involve various case management strategies.
                </div>
                <div>
                  13.3 Upon request from any party, the arbitral tribunal may
                  issue orders regarding the confidentiality of the arbitration
                  proceedings or any related matters, taking steps to protect
                  trade secrets and confidential information.
                </div>
                <div>
                  13.4 In all instances, the arbitral tribunal must operate in
                  accordance with the law and maintain impartiality, ensuring
                  that both parties are treated equitably and have ample
                  opportunity to present their respective cases.
                </div>
                <div>
                  13.5 Parties are obligated to adhere to any orders issued by
                  the arbitral tribunal.
                </div>
                <div>
                  13.6 If the original Agreement is in a language other than
                  English, it must be translated into English, and the duly
                  translated copy must be submitted to the centre by the
                  parties.
                </div>
                <div>
                  13.7 The presiding arbitrator may issue procedural rulings
                  independently, with the possibility of review by the Tribunal.
                </div>
                <div>
                  13.8 The Tribunal may proceed with the arbitration process
                  even in cases where any party fails or refuses to comply with
                  these Rules, the Tribunal’s orders or directions, any interim
                  or partial Award, or fails to attend meetings or hearings. The
                  Tribunal may impose appropriate sanctions in such
                  circumstances.
                </div>
                <div>
                  13.9 If any applicable law requires it, the Tribunal shall
                  strive to deliver its final Award within 12 months from the
                  date it receives written notice of its formation. If the
                  Tribunal determines that it cannot reasonably issue the final
                  Award within this 12-month period, it must promptly inform the
                  parties in writing, providing brief reasons and estimating the
                  additional time needed. By agreeing to arbitrate under these
                  Rules, the parties expressly consent to extending the time
                  prescribed by applicable law for rendering the Award by up to
                  6 months, if the Tribunal deems such an extension necessary.
                </div>
                <div>
                  13.10 For matters not explicitly addressed in these Rules, the
                  Council and the Tribunal will operate in accordance with the
                  underlying principles and intent of these Rules. They will
                  make every effort to ensure that the Award complies with the
                  law of the arbitration seat and is legally enforceable.
                </div>
              </div>

              <div className="fs-5 fw-normal mt-2 mb-2">Article 14 Language</div>
              <div>
                <div>
                  14.1 The parties have the liberty to mutually decide on the
                  language or languages to be employed in the Arbitral
                  Proceedings, unless the agreement specifies otherwise.
                </div>
                <div>
                  14.2 If any document is composed in a language different from
                  the arbitration’s designated language, the Tribunal reserves
                  the right to instruct that party to provide a translation,
                  following a format determined by the Tribunal.
                </div>
                <div>
                  14.3 In cases where the parties have not reached an agreement
                  regarding the language(s) of the arbitration, English will
                  serve as the default language, or languages, taking into
                  careful consideration all pertinent factors, including the
                  language used in the contract.
                </div>
              </div>

              <div className="fs-5 fw-normal mt-2 mb-2">
                Article 15 Statement of Claims & Defences
              </div>
              <div>
                <div>
                  15.1 The Claimant must, within the timeline agreed upon by the
                  parties or determined by the arbitral tribunal, provide a
                  detailed account of the facts supporting their claim, the
                  issues in contention, and the specific relief or remedy
                  sought. The Respondent, on the other hand, should present
                  their defence concerning these particulars, unless the parties
                  have mutually agreed upon different requirements for these
                  statements.
                </div>
                <div>
                  15.2 Both parties are permitted to include any documents they
                  deem relevant with their statements or make references to
                  documents and other evidence they intend to submit later.
                </div>
                <div>
                  15.3 Additionally, the Respondent has the option to introduce
                  a counterclaim or assert a set-off in support of their
                  position. The arbitral tribunal will adjudicate on these
                  matters if they fall within the scope of the arbitration
                  agreement.
                </div>
                <div>
                  15.4 Unless otherwise stipulated by the parties, either party
                  has the right to modify or augment their claim or Defence as
                  the arbitral proceedings progress, unless such amendments or
                  supplements are deemed inappropriate by the arbitral tribunal
                  due to undue delay.
                </div>
                <div>
                  15.5 The submission of the statement of claim and defence, as
                  outlined in this Article, must be completed within a six-month
                  period from the date when the arbitrator or the entire panel
                  of arbitrators, as applicable, formally received written
                  notice of their appointment.
                </div>
              </div>

              <div className="fs-5 fw-normal mt-2 mb-2">Article 16 Statement of Claim</div>
              <div>
                <div>
                  16.1 The Claimant must file its claim in compliance with the
                  rules of AECCI-IAC.
                </div>
                <div>
                  16.2 If the Claimant does not submit its Statement of Claim
                  within the specified timeframe, the Tribunal may issue an
                  order to conclude the arbitration proceedings or provide other
                  appropriate instructions, unless a Respondent has filed a
                  counterclaim and desires to continue the arbitration.
                </div>
              </div>

              <div className="fs-5 fw-normal mt-2 mb-2">Article 17 Further Pleadings</div>
              <div>
                <div>
                  17.1 All statements, documents or other information supplied
                  to the Tribunal and the Registrar by both the parties and the
                  final decision shall lie with the tribunal.
                </div>
              </div>

              <div className="fs-5 fw-normal mt-2 mb-2">
                Article 18 Submission of Statement of Defence and Counter Claims
              </div>
              <div>
                <div>
                  18.1 Unless previously presented as per the relevant Rule, the
                  Respondent must, within a timeline established by the Tribunal
                  during the initial procedural meeting in accordance with the
                  Rule, provide the Claimant and the Tribunal with a
                  comprehensive Statement of Defence. This statement should
                  outline the Respondent’s complete defence against the
                  Statement of Claim, encompassing both factual and legal
                  arguments. Additionally, the Statement of Defence should
                  indicate any counterclaim, adhering to Rule requirements.
                </div>
                <div>
                  18.2 If, as determined during the initial procedural meeting,
                  the Tribunal deems it necessary, the Respondent should also
                  append witness statements supporting its defence and any
                  counterclaim to the Statement of Defence.
                </div>
                <div>
                  18.3 In the event of a counterclaim, the Claimant must, within
                  a timeline set by the Tribunal during the initial procedural
                  meeting as per the Rule, furnish a Statement of Defence
                  against the Counterclaim. This statement should encompass the
                  Claimant’s comprehensive defence against the counterclaim,
                  including factual details and legal arguments.
                </div>
                <div>
                  18.4 Should the Respondent fail to submit a Statement of
                  Defence, or if, at any point, any party neglects the
                  opportunity to present its case as instructed by the Tribunal,
                  the Tribunal retains the authority to proceed with the
                  arbitration nonetheless.
                </div>
              </div>

              <div className="fs-5 fw-normal mt-2 mb-2">Article 19 Evidence</div>
              <div>
                <div>
                  19.1 The arbitral Tribunal may possess the authority to
                  mandate that any documentary evidence must be accompanied by
                  translations into languages that have been mutually agreed
                  upon by the involved parties or designated by the Tribunal
                  itself.
                </div>
                <div>
                  19.2 Furthermore, the Tribunal’s authority extends beyond what
                  is explicitly articulated within these Rules, and it does not
                  undermine the imperative legal principles governing the
                  arbitration process. The Tribunal is empowered to:
                  <ul>
                    <li>
                      a. Undertake inquiries deemed necessary for prudent by the
                      Tribunal.
                    </li>
                    <li>
                      b. Issue directives to the parties, compelling them to
                      make any property or item accessible for inspection.
                    </li>
                    <li>
                      c. Instruct any party to submit to the Tribunal and share
                      with the other parties, documents within their possession,
                      custody, or control that the Tribunal deems pertinent to
                      the case and integral to its resolution. Copies of these
                      documents must also be supplied.
                    </li>
                    <li>
                      d. Adjudicate on matters pertaining to legal privilege or
                      any other privileges asserted.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="fs-5 fw-normal mt-2 mb-2">Article 20 Witnesses</div>
              <div>
                <div>
                  20.1 Prior to the commencement of the hearing, the arbitral
                  tribunal holds the authority to request that the parties
                  furnish a notice identifying the witnesses, which may include
                  expert witnesses, along with a description of their intended
                  testimonies and how they relate to the pertinent issues.
                </div>
                <div>
                  20.2 The Tribunal retains the discretion to determine whether
                  witnesses should be allowed to provide oral testimonies during
                  a hearing and may impose limitations on the number of
                  witnesses to streamline proceedings.
                </div>
                <div>
                  20.3 The Tribunal possesses the authority to decide the manner
                  in which witnesses will be examined, which may include the
                  acceptance of written testimonies.
                </div>
                <div>
                  20.4 Parties, or their duly authorized representatives, may,
                  with the Tribunal’s prior approval, conduct questioning of
                  their own potential witnesses before their appearance at the
                  hearing.
                </div>
                <div>
                  20.5 In the event that the parties involved formally request,
                  in writing, to solely base the arbitration proceedings on
                  documentary evidence, the tribunal may dispense with the
                  necessity of examining witnesses before the hearing commences.
                </div>
                <div>
                  20.6 It is important to note that the arbitral tribunal is
                  bound by the procedures outlined in the Indian Evidence Act of
                  1872 or any Evidence Act applicable, concerning witness
                  examinations or documentary evidence. Instead, the Tribunal is
                  entitled to establish its own procedures, provided they align
                  with the principles of natural justice.
                </div>
              </div>

              <div className="fs-5 fw-normal mt-2 mb-2">
                Article 21 Tribunal Appointed Expert
              </div>
              <div>
                <div>
                  21.1 The arbitral tribunal, considering the unique
                  circumstances of a particular case and unless otherwise
                  stipulated by the parties, has the authority to:
                  <ul>
                    <li>
                      a. Nominate one or more experts to provide insights on
                      specific matters under the tribunal’s consideration.
                    </li>
                    <li>
                      b. Call upon a party to furnish the expert with pertinent
                      information or grant access to relevant documents, goods,
                      or other assets for examination.
                    </li>
                  </ul>
                </div>
                <div>
                  21.2 The arbitral tribunal must instruct the appointed expert
                  to submit a written report within a specified timeframe. In
                  cases where it deems it necessary, the tribunal may also
                  require the expert’s presence at an oral hearing, during which
                  the parties have the opportunity to cross-examine the expert
                  and seek clarifications on the issues in question.
                </div>
                <div>
                  21.3 With prior approval from the Arbitral Tribunal, the
                  parties can direct the expert to allow the arbitration parties
                  to examine documents, goods, or other assets that were in the
                  expert’s possession and contributed to the conclusions
                  presented in his written report, which was submitted to the
                  Tribunal.
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>

      {/* PART V : ARBITRAL AWARD */}

      <Col>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div>PART V: ARBITRAL AWARD</div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="fs-5 fw-normal mt-2 mb-2">
                Article 22 Time Frame to Determine Arbitral Award
              </div>
              <div>
                <div>
                  22.1 The Arbitral Tribunal is expected to deliver the final
                  award within a span of six months from the date of the last
                  signature, either by the tribunal itself or by the parties, on
                  the Terms of Reference governing the arbitration. However, the
                  tribunal retains the flexibility to establish a different time
                  limit in accordance with the procedural schedule it has
                  established. Additionally, if deemed necessary, the tribunal
                  can extend the procedural time limit, either at its own
                  initiative or in response to a formal written request
                  submitted by the parties involved in the arbitration.
                </div>
                <div>
                  22.2 In the event that the parties reach a settlement
                  subsequent to the transmission of the case file to the
                  arbitral tribunal, the tribunal, after recording the
                  underlying reasons in writing, is empowered to issue a
                  decision in the form of an award confirming the settlement.
                  This is contingent upon the Arbitral Tribunal’s agreement and
                  the prior request for such action, either orally or in
                  writing, by the parties engaged in the arbitration process.
                </div>
              </div>

              <div className="fs-5 fw-normal mt-2 mb-2">
                Article 23 Form and Contents of Arbitral Award
              </div>
              <div>
                <div>
                  23.1 An Arbitral Award must be composed in a written format
                  and bear the signatures of all the members of the arbitral
                  tribunal.
                </div>
                <div>
                  23.2 In cases involving multiple arbitrators, it is acceptable
                  for the signatures of the majority of the arbitral tribunal’s
                  members to suffice, provided that any omitted signatures are
                  accompanied by a stated reason.
                </div>
                <div>
                  23.3 The Arbitral Award is required to elucidate the grounds
                  on which it is based, unless either:
                  <ul>
                    <li>
                      A. the parties have mutually agreed to forego the
                      requirement of providing reasons, or
                    </li>
                    <li>
                      B. the award is a result of arbitral proceedings settled
                      on agreed terms in accordance with Article 32.
                    </li>
                  </ul>
                </div>
                <div>
                  23.4 The Arbitral Award should specify the date and the place
                  of arbitration. This designation establishes that the award is
                  considered to have been rendered at that specified location.
                </div>
                <div>
                  23.5 Subsequent to the completion of the Arbitral Award, a
                  signed copy must be delivered to each of the involved parties.
                </div>
                <div>
                  23.6 The Arbitral Award has the authority, at any point during
                  the arbitral proceedings, to issue an interim arbitral award
                  regarding any matter over which it retains the power to make a
                  final arbitral award.
                  <ul>
                    <li>
                      a. Unless otherwise agreed upon by the parties, if the
                      Arbitral Award entails a monetary payment, the arbitral
                      tribunal is entitled to include interest, at a reasonable
                      rate determined by its discretion, on the entirety or any
                      portion of the monetary award. This interest accrues from
                      the date on which the cause of action originated until the
                      date the award is officially issued.
                    </li>
                    <li>
                      b. Any monetary sum directed to be paid by an Arbitral
                      Award shall, unless explicitly directed otherwise by the
                      award, accrue interest at a rate two percent higher than
                      the prevailing rate of interest as of the date of the
                      award, from the date of the award until the date of
                      payment.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="fs-5 fw-normal mt-2 mb-2">
                Article 24 Relevant Factors in Determining Final Award
              </div>
              <div>
                <div>
                  24.1 In cases involving multiple arbitrators, the award is
                  contingent upon a majority decision. In the absence of
                  multiple arbitrators, the president of the tribunal is tasked
                  with rendering the award individually, accompanied by a
                  written explanation of this decision.
                </div>
                <div>
                  24.2 The award issued by the arbitral tribunal must be
                  comprehensive, providing detailed reasons for arriving at the
                  decision. It should also include information about the date
                  and location of the arbitration proceedings.
                </div>
                <div>
                  24.3 If both parties unanimously agree that no further
                  relevant evidence or pleadings are to be presented, the
                  tribunal is entitled to close the proceedings.
                </div>
                <div>
                  24.4 Within 30 days of concluding the proceedings, the
                  tribunal must submit all draft awards to the Registrar, except
                  in exceptional circumstances. While the Registrar can propose
                  changes to the format and content of the draft award, the
                  tribunal has the final say.
                </div>
                <div>
                  24.5 The final award is expected to be delivered within 30
                  days of the tribunal submitting the draft award to the
                  Registrar, with the possibility of an extension under
                  exceptional circumstances, not exceeding a total period of 60
                  days.
                </div>
                <div>
                  24.6 The tribunal has the discretion to issue separate awards
                  on different issues and at different times.
                </div>
                <div>
                  24.7 In cases where an arbitrator fails to cooperate, the
                  remaining arbitrators can proceed to make the award without
                  their participation.
                </div>
                <div>
                  24.8 An award can exist in multiple counterparts, with each
                  considered as an original. If an arbitrator fails to sign, the
                  reason for their absence must be documented. If the majority
                  of the tribunal signs, the award is deemed final and binding,
                  provided all arbitrators had the opportunity to sign.
                </div>
                <div>
                  24.9 The Registrar is responsible for delivering certified
                  copies of the award upon the full payment of arbitration
                  costs.
                </div>
                <div>
                  24.10 The tribunal holds the authority to grant simple or
                  compound interest at rates agreed upon or determined.
                </div>
                <div>
                  24.11 In the event of a settlement, the tribunal may issue a
                  consent award either with or without reasons upon request by
                  the parties, or the parties can inform the Registrar of the
                  settlement.
                </div>
                <div>
                  24.12 By opting for arbitration under these Rules, the parties
                  commit to promptly executing the award and relinquish their
                  rights to appeal or seek judicial remedies. The award becomes
                  final and binding upon its issuance.
                </div>
                <div>
                  24.13 The AECCI – IAC has the option to publish an award with
                  redacted names and identifying information and maintains it in
                  its registry.
                </div>
              </div>

              <div className="fs-5 fw-normal mt-2 mb-2">
                Article 25 Correction of Awards and Additional Awards
              </div>
              <div>
                <div>
                  25.1 Within a period of 30 days following the receipt of an
                  Award, any party may submit a written notice to the Registrar
                  and all other involved parties, requesting the Tribunal to
                  rectify any computational, clerical, typographical, or similar
                  errors present in the Award. Other parties are entitled to
                  provide their comments within 15 days upon receiving such a
                  request. If the Tribunal deems the request justified, it must
                  effect the necessary correction within 30 days from the date
                  of receiving the request. Any corrections, whether included in
                  the original Award or a separate memorandum, shall be
                  considered integral components of the Award.
                </div>
                <div>
                  25.2 The Tribunal retains the authority to rectify any errors
                  of the type mentioned in Rule 25.1, independently and without
                  external request, within a timeframe of 30 days from the date
                  of issuing the Award.
                </div>
                <div>
                  25.3 Within 30 days of receiving an Award, a party may, via
                  written notice to the Registrar and all other parties
                  involved, request the Tribunal to render an additional Award
                  concerning claims presented during the arbitral proceedings
                  but not addressed in the initial Award. In such cases,
                  notwithstanding Rule 25.1, the Tribunal may reopen the
                  proceedings solely for the purpose of adjudicating requests
                  made under this Rule. Other parties have the right to provide
                  their comments within 15 days upon receiving such a request.
                  If the Tribunal deems the request justified, it must issue the
                  additional award within 60 days from the date of receiving the
                  request.
                </div>
                <div>
                  25.4 Within 30 days of receiving an Award, a party may submit
                  a written notice to the Registrar and all other parties,
                  seeking an interpretation of the Award. Other parties are
                  permitted to comment on this request within 15 days upon
                  receipt. If the Tribunal finds the request warranted, it must
                  provide a written interpretation within 30 days of receiving
                  the request. This interpretation will become an integral part
                  of the Award.
                </div>
                <div>
                  25.5 The Registrar holds the authority to extend the time
                  limits established by this Rule.
                </div>
                <div>
                  25.6 The provisions outlined in Article 23 shall be applied,
                  with necessary or appropriate adjustments, in the context of
                  corrections to an Award, interpretations of an Award, and the
                  issuance of additional Awards.
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>

      {/* PART VI : MISCELLANEOUS PROVISIONS*/}
      <Col>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div>PART VI: MISCELLANEOUS PROVISIONS</div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="fs-5 fw-normal mt-2 mb-2">Article 26 Jurisdiction</div>
              <div>
                <div>
                  26.1 Unless otherwise specified in the agreement, the
                  Tribunal’s primary jurisdiction should be in Navi Mumbai,
                  India.
                </div>
                <div>
                  26.2 In the event of a dispute regarding jurisdiction, the
                  determination of the Seat of Arbitration, the location of
                  Arbitration proceedings, and the conclusion of such
                  proceedings shall be communicated through written
                  correspondence by the parties who have entered into the
                  agreement.
                </div>
              </div>

              <div className="fs-5 fw-normal mt-2 mb-2">
                Article 27 Secrecy and Confidentiality
              </div>
              <div>
                In addition to any provisions explicitly outlined in any other
                currently applicable laws governing arbitration proceedings
                between the parties involved, it is required that the
                arbitrator, the AECCI – IAC, and the parties who are signatories
                to the arbitration agreement maintain rigorous confidentiality
                throughout all phases of the arbitration process until its
                conclusion. The only exception to this rule is in the case of
                the final arbitral award issued by the Arbitral Tribunal, where
                disclosure becomes necessary for the purpose of implementing and
                enforcing the said final arbitral award.
              </div>

              <div className="fs-5 fw-normal mt-2 mb-2">
                Article 28 Waiver and Limited Liability
              </div>
              <div>
                <div>
                  28.1 If either party involved in the arbitration proceedings
                  proceeds with the arbitration without raising objections to
                  non-compliance with any mandatory provisions within the AECCI
                  – IAC Rules, or any other explicit provisions of the law
                  relevant to the arbitration proceedings, any directives issued
                  by the Arbitral Tribunal, or any requirements outlined in the
                  arbitration agreement pertaining to the composition of the
                  Arbitral Tribunal or the course of the proceedings, it shall
                  be presumed that this party has willingly waived its right to
                  object.
                </div>
                <div>
                  28.2 The arbitrators, individuals appointed by the Arbitral
                  Tribunal, the emergency arbitrator, the AECCI – IAC, and any
                  of its members, employees, as well as members of the AECCI IAC
                  Council, including its Chairman, shall not be held liable to
                  any party for any actions or omissions carried out in Good
                  Faith during the arbitration process. This limitation of
                  liability shall apply, except in cases where such limitation
                  is prohibited by the applicable laws governing the arbitration
                  proceedings conducted between the parties.
                </div>
              </div>

              <div className="fs-5 fw-normal mt-2 mb-2">
                Article 29 Termination of Proceedings
              </div>
              <div>
                <div>
                  29.1 The arbitral proceedings may conclude either through the
                  issuance of a final arbitral award or by an order from the
                  arbitral tribunal as specified in sub-Article (ii).
                </div>
                <div>
                  29.2 The arbitral tribunal is responsible for issuing an order
                  to terminate the arbitral proceedings in the following
                  circumstances:
                  <ul>
                    <li>
                      a. If the claimant withdraws their claim, unless the
                      respondent raises an objection and the arbitral tribunal
                      acknowledges a legitimate interest on the respondent’s
                      part in achieving a final resolution of the dispute.
                    </li>
                    <li>
                      b. When the parties mutually agree to terminate the
                      proceedings.
                    </li>
                    <li>
                      c. If the arbitral tribunal determines that the
                      continuation of the proceedings has become unnecessary or
                      impossible for any other valid reason.
                    </li>
                  </ul>
                </div>
                <div>
                  29.3 The authority of the arbitral tribunal ends concurrently
                  with the termination of the arbitral proceedings.
                </div>
              </div>

              <div className="fs-5 fw-normal mt-2 mb-2">Article 30 Default of a Party</div>
              <div>
                <div>
                  Unless otherwise stipulated by the parties, in cases where,
                  without presenting sufficient justification:
                </div>
                <div>
                  <ul>
                    <li>
                      a. The claimant fails to submit their statement of claim
                      as prescribed; the arbitral tribunal shall bring the
                      proceedings to a close.
                    </li>
                    <li>
                      b. The respondent neglects to furnish their statement of
                      defense as required, the arbitral tribunal shall proceed
                      with the proceedings without interpreting this failure as
                      an admission of the claimant’s allegations [and may
                      exercise discretion in considering the forfeiture of the
                      respondent’s right to submit such a statement of defense].
                    </li>
                    <li>
                      c. Either party fails to attend an oral hearing or provide
                      documentary evidence; the arbitral tribunal may continue
                      with the proceedings and render an arbitral award based on
                      the available evidence.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="fs-5 fw-normal mt-2 mb-2">Article 31 Settlement</div>
              <div>
                <div>
                  31.1 An arbitral tribunal is not precluded from actively
                  promoting the settlement of the dispute under an arbitration
                  agreement. With the consent of the parties, the arbitral
                  tribunal may utilize mediation, conciliation, or other
                  procedures at any stage of the arbitration proceedings to
                  facilitate a settlement.
                </div>
                <div>
                  31.2 Should the parties reach a settlement during the course
                  of the arbitral proceedings, the arbitral tribunal shall
                  terminate the proceedings and, upon request from the parties
                  and without objection from the arbitral tribunal, memorialize
                  the settlement in the form of an arbitral award reflecting the
                  agreed-upon terms.
                </div>
                <div>
                  31.3 An arbitral award reflecting agreed terms shall adhere to
                  the principles outlined in and explicitly state its nature as
                  an arbitral award.
                </div>
                <div>
                  31.4 An arbitral award on agreed terms shall carry the same
                  legal status and impact as any other arbitral award addressing
                  the merits of the dispute.
                </div>
              </div>

              <div className="fs-5 fw-normal mt-2 mb-2">
                Article 32 Arbitration Agreement Not to be Discharged by Death
                of Parties
              </div>
              <div>
                The death of a party to an arbitration agreement does not result
                in the agreement being terminated. Instead, it remains valid and
                can be enforced by or against the deceased party’s legal
                representative. If an arbitrator has been appointed by a party
                who subsequently passes away, the arbitrator’s role and
                authority are not terminated as a result of the party’s death.
                It’s important to note that this section does not affect any
                laws that may exist, which could extinguish a person’s right to
                take legal action upon their death.
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>

      {/* PART VII : ARBITRATION PROCESS: A COMPREHENSIVE OVERVIEW*/}
      <Col>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div>PART VII: ARBITRATION PROCESS: A COMPREHENSIVE OVERVIEW</div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="fs-5 fw-normal mt-2 mb-2">
                Article 33 Payment of Arbitration Costs
              </div>
              <div>
                <div>
                  33.1 In relation to any arbitration proceeding under the said
                  Rules, the Arbitral Tribunal shall have the discretion to
                  determine—
                  <ul>
                    <li>
                      a. Whether costs are payable by one party to another;
                    </li>
                    <li>b. The amount of such costs; and</li>
                    <li>c. When such costs are to be paid.</li>
                  </ul>
                  Explanation.—For the purpose of this sub-Article, “costs”
                  means reasonable costs relating to—
                  <ul>
                    <li>
                      i. The fees and expenses of the Arbitrators, Expert, and
                      Witnesses;
                    </li>
                    <li>ii. Legal fees and expenses;</li>
                    <li>
                      iii. Administration fees of the AECCI International
                      Arbitration Centre supervising the arbitration; and
                    </li>
                    <li>
                      iv. Any other expenses incurred in connection with the
                      arbitral award.
                    </li>
                  </ul>
                </div>
                <div>
                  33.2 If the arbitral tribunal decides to make an order as to
                  payment of costs—
                  <ul>
                    <li>
                      a. The general rule is that the unsuccessful party shall
                      be ordered to pay the costs of the successful party; or
                    </li>
                    <li>
                      b. The Arbitral Tribunal may make a different order for
                      reasons to be recorded in writing.
                    </li>
                  </ul>
                </div>
                <div>
                  33.3 In determining the costs, the Arbitral Tribunal shall
                  have regard to all the circumstances, including—
                  <ul>
                    <li>a. The conduct of all the parties;</li>
                    <li>
                      b. Whether a party has succeeded partly in the case;
                    </li>
                    <li>
                      c. Whether the party had made a frivolous counterclaim
                      leading to delay in the disposal of the arbitral
                      proceedings; and
                    </li>
                    <li>
                      d. Whether any reasonable offer to settle the dispute is
                      made by a party and refused by the other party.
                    </li>
                  </ul>
                </div>
                <div>
                  33.4 The Arbitral Tribunal may make any order under this
                  Article including the order that a party shall pay—
                  <ul>
                    <li>a. A proportion of another party’s costs;</li>
                    <li>
                      b. A stated amount in respect of another party’s costs;
                    </li>
                    <li>c. Costs from or until a certain date only;</li>
                    <li>d. Costs incurred before proceedings have begun;</li>
                    <li>
                      e. Costs relating to particular steps taken in the
                      proceedings;
                    </li>
                    <li>
                      f. Costs relating only to a distinct part of the
                      proceedings; and
                    </li>
                    <li>g. Interest on costs from or until a certain date.</li>
                  </ul>
                </div>
                <div>
                  33.5 An agreement which has the effect that a party is to pay
                  the whole or part of the costs of the arbitration in any event
                  shall be only valid if such agreement is made after the
                  dispute in question has arisen.
                </div>
              </div>

              <div className="fs-5 fw-normal mt-2 mb-2">Article 34 Schedule of Fee</div>
              <div>
                34.1 To know about the schedule of fees, kindly click on the
                above link SCHEDULE FEE.
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Row>
  );
};

const FeeModal = ({ show, onHide }) => (
  <Modal
    show={show}
    onHide={onHide}
    size="xl"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title>Fee Document</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <FeeModalContent />
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onHide}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
);

const FeeModalContent = () => {
 

  return (
    <div className="p-2 m-2">
      <p>
        As an institution that offers impartial and efficient dispute resolution
        services, the AECCI International Arbitration Center provides a
        transparent fee schedule to ensure that parties involved in the
        arbitration process have a clear understanding of the costs involved.
      </p>
      <p>
        The fee schedule covers the costs associated with the administration of
        arbitration proceedings, including filing case fees, administration
        fees, arbitrator fees, and emergency interim relief fees.
      </p>
      <p>
        It is important to note that the fees outlined in the schedule are
        subject to change and may vary depending on the specific circumstances
        of the dispute. Parties involved in the arbitration process are
        encouraged to consult with the AECCI International Arbitration Center
        directly to obtain the most up-to-date fee information.
      </p>
      <p>
        This Schedule of Fees is effective as of 13.04.2023 and is applicable to
        all arbitrations commenced on or after 13.04.2023. This Schedule of Fees
        may be amended from time to time and any revised Schedule of Fees shall
        take effect as of the date determined by the AECCI Management.
      </p>
      <h5 className="text-center fs-5 fw-bold text-decoration-underline">Filing Case Fee</h5>
      <p className="text-center">
        USD 300 (FOR AECCI-MEMBERS)*
        <br />
        USD 400 (FOR AECCI-NON-MEMBERS)
        <br />
        <strong>
          {" "}
          *Client/Applicant must be registered as Member of AECCI, if Non-Member
          then Fees shall be applicable as above.
        </strong>
      </p>
      <h4 className="text-center fs-5 fw-bold text-decoration-underline">Administration Fees</h4>
      <div className="text-center">
        The administration fees calculated in accordance with the Schedule below
        apply to all arbitrations administered by AECCI-IAC and is the maximum
        amount payable to AECCI-IAC.
      </div>

      <table className="table w-100 mb-4 table-bordered">
        <thead>
          <tr>
            <th className="border-bottom p-2 bg-light">Quantum of Claim (USD)</th>
            <th className="border-bottom p-2 bg-light">Fee (USD)</th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-light">
            <td className="border-bottom p-2">UP TO 75,000</td>
            <td className="border-bottom p-2">1,375</td>
          </tr>
          <tr className="bg-white">
            <td className="border-bottom p-2">75,001 to 200,000</td>
            <td className="border-bottom p-2">2,000 + 1.5% over 75,000</td>
          </tr>
          <tr className="bg-light">
            <td className="border-bottom p-2">200,001 to 700,000</td>
            <td className="border-bottom p-2">2,800 + 1.4% over 200,001</td>
          </tr>
          <tr className="bg-white">
            <td className="border-bottom p-2">700,001 to 1,200,000</td>
            <td className="border-bottom p-2">7,000 + 1.3% over 700,001</td>
          </tr>
          <tr className="bg-light">
            <td className="border-bottom p-2">1,200,001 to 2,200,000</td>
            <td className="border-bottom p-2">10,000 + 1% over 1,200,001</td>
          </tr>
          <tr className="bg-white">
            <td className="border-bottom p-2">2,200,001 to 6,200,000</td>
            <td className="border-bottom p-2">12,000 + 0.80% over 2,200,001</td>
          </tr>
          <tr className="bg-light">
            <td className="border-bottom p-2">6,200,001 to 12,500,000</td>
            <td className="border-bottom p-2">20,000 + 0.75% over 6,200,001</td>
          </tr>
          <tr className="bg-white">
            <td className="border-bottom p-2">Above 12,500,001</td>
            <td className="border-bottom p-2">50,000 + 0.50% over 12,500,001</td>
          </tr>
        </tbody>
      </table>
      <div>
        The administration fees do not include the following:
        <br />• Interpretation, correction or additional Award: US$ 100.00
        <br />• Scrutiny of Award: US$ 250.00
        <br />• Administrative and Filling charges to be paid along with the
        Application to appointment Arbitrator.
        <br />• Usage cost of facilities and support services for and in
        connection with any hearing (i.e. hearing rooms and equipment,
        transcription and interpretation services etc.)
        <br />• The Administrative and Filling charges are payable one time and
        are non-refundable.
        <br />• Taxes as applicable
      </div>
      <h3>Additional Costs</h3>
      <ul className="my-2 ps-4">
        <li>Interpretation, correction or additional Award: US$ 100.00</li>
        <li>Scrutiny of Award: US$ 250.00</li>
        <li>
          Administrative and Filing charges to be paid along with the
          Application to appoint Arbitrator.
        </li>
        <li>
          Usage cost of facilities and support services for and in connection
          with any hearing (i.e. hearing rooms and equipment, transcription and
          interpretation services etc.)
        </li>
        <li>
          The Administrative and Filing charges are payable one time and are
          non-refundable.
        </li>
        <li>Taxes as applicable</li>
      </ul>
      <h4 className="text-center fs-5 fw-bold text-decoration-underline">Arbitrator’s Fees</h4>
      <div className="text-center">
        The fee calculated in accordance with the Schedule below is the maximum
        amount payable to arbitrator and Additional Fees for the Arbitral
        Tribunal (Per Arbitrator).
      </div>
      <table className="table w-100 mb-4 table-bordered">
        <thead>
          <tr>
            <th className="border-bottom p-2 bg-light">Quantum of Claim (USD)</th>
            <th className="border-bottom p-2 bg-light">Fee (USD)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border-bottom p-2">UP TO 75,000</td>
            <td className="border-bottom p-2">1,218</td>
          </tr>
          <tr>
            <td className="border-bottom p-2">75,001 to 200,000</td>
            <td className="border-bottom p-2">2,343 + 3% over 75,001</td>
          </tr>
          <tr>
            <td className="border-bottom p-2">200,001 to 700,000</td>
            <td className="border-bottom p-2">4,218 + 1% over 200,001</td>
          </tr>
          <tr>
            <td className="border-bottom p-2">700,001 to 1,200,000</td>
            <td className="border-bottom p-2">9,218 + 1% over 700,001</td>
          </tr>
          <tr>
            <td className="border-bottom p-2">1,200,001 to 2,200,000</td>
            <td className="border-bottom p-2">15,468 + 0.75% over 1,200,001</td>
          </tr>
          <tr>
            <td className="border-bottom p-2">2,200,001 to 6,200,000</td>
            <td className="border-bottom p-2">24,843 + 0.5% over 2,200,001</td>
          </tr>
          <tr>
            <td className="border-bottom p-2">6,200,001 and above</td>
            <td className="border-bottom p-2">(Contact Chamber Desk)</td>
          </tr>
          <tr>
            <td className="border-bottom p-2">Additional Fees for the Arbitral Tribunal</td>
            <td className="border-bottom p-2"></td>
          </tr>
          <tr>
            <td className="border-bottom p-2">Up to 75,000</td>
            <td className="border-bottom p-2">500</td>
          </tr>
          <tr>
            <td className="border-bottom p-2">Up to 75,001 to 1,200,000</td>
            <td className="border-bottom p-2">1,000</td>
          </tr>
          <tr>
            <td className="border-bottom p-2">1,200,001 and above</td>
            <td className="border-bottom p-2">2,000</td>
          </tr>
        </tbody>
      </table>

      <h4 className="text-center fs-5 fw-bold text-decoration-underline">Emergency Interim Relief Fees</h4>
      <p className="text-center">
        The following fees shall be payable in an Emergency Interim Relief
        application under Article 07 of the Arbitration Rules of the AECCI-IAC:
      </p>
      <ul className="my-2 ps-4">
        <li>
          Administration Fee for Emergency Arbitrator Applications: USD 800*
        </li>
        <li>
          Emergency Arbitrator’s Fees: The Emergency Arbitrator’s fees shall be
          capped at 15% of a sole arbitrator’s maximum fee calculated in
          accordance with the Schedule of Fees in force at the time of
          commencement of the arbitration.
        </li>
        <li>Taxes as applicable*</li>
      </ul>
      <h4>Payment Schedule</h4>
      <ol className="my-2 ps-4">
        <li>Filing Case Fee</li>
        <li>
          Administrative fee to be paid by the Claimant on the filing of Notice
          of Arbitration.
        </li>
        <li>Arbitral Tribunal Fee Schedule</li>
      </ol>
      <ul>
        <li>
          At the time of filing the Claim statement by the Claimant, 20% shall
          be deposited.
        </li>
        <li>
          Respondent at the time of filing the Response or Statement of Defense
          should deposit 20%.
        </li>
        <li>
          Parties [Claimant and Respondent(s)] should deposit 25% equally within
          10 days of the completion of pleadings.
        </li>
        <li>
          Parties [Claimant and Respondent(s)] should deposit 20% equally before
          the hearing date.
        </li>
        <li>
          Parties [Claimant and Respondent(s)] should deposit 15% Balance within
          10 days after the matter is reserved for award.
        </li>
      </ul>
    </div>
  );
};

export default RulesPolicies;
