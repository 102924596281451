import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import EPlatformHeader from "../../../components/basic/EPlatformHeader";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { getEmail } from "../../../store/userStore";
import { getURLbyEndPoint } from "../../../store/api";
import infoImg from "../../../assets/infoimg.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  country_list,
  howDidYouKnowAboutUs,
  role,
  states_list,
} from "./AllRegiPageLists";
import { toast } from "react-hot-toast";
import { titleOptions } from "../../../store/AllListData";
const email = getEmail();
const initialFormData = {
  selectMembership: "",
  companyName: "",
  inputNumber: "",
  gstNo: "",
  websiteAdd: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  country: "",
  pinCode: "",
  businessCategory: "",
  title: "",
  numberOfEmployees: "",
  firstName: "",
  surName: "",
  role: "",
  email: "",
  password: "",
  phoneNo: "",
  telephoneNo: "",
  registeredBank: "",
  registeredAccountNo: "",
  IFSCCode: "",
  throughEmail: false,
  throughCalls: false,
  howDidYouKnowAboutUs: "",
  chamberBenefits: "",
  accountStatus: "New",
  reqType: "",
};

const bankNames = [
  "Bank of Baroda",
  "Bank of India",
  "Bank of Maharashtra",
  "Canara Bank",
  "Central Bank of India",
  "Indian Bank",
  "Indian Overseas Bank",
  "Punjab and Sind Bank",
  "Punjab National Bank",
  "State Bank of India",
  "UCO Bank",
  "Union Bank of India",
  "Axis Bank Ltd.",
  "City Union Bank Ltd.",
  "DCB Bank Ltd",
  "Federal Bank Ltd.",
  "HDFC Bank Ltd.",
  "ICICI Bank Ltd.",
  "IDBI Bank Ltd.",
  "IDFC FIRST Bank Ltd",
  "IndusInd Bank Ltd",
  "Jammu and Kashmir Bank Ltd. *",
  "Karnataka Bank Ltd.",
  "Karur Vysya Bank Ltd.",
  "Kotak Mahindra Bank Ltd.",
  "RBL Bank Ltd",
  "South Indian Bank Ltd.",
  "Yes Bank Ltd.",
  "Dhanlaxmi Bank Ltd.",
  "Bandhan Bank Ltd.",
  "CSB Bank Ltd.",
  "Jammu and Kashmir Bank Ltd.",
  "SVC CO-OPERATIVE BANK LTD",
  "BHARAT CO-OP BANK MUMBAI LTD",
];

export const businessCategories = [
  "Export Consultant",
  "Organic products",
  "Agriculture and forestry",
  "Livestock and fish",
  "Food",
  "Agricultural and forestry machinery and equipment",
  "Beverages",
  "Food, drink, tobacco and catering industry machinery and equipment",
  "Chemical base materials",
  "Plastic products",
  "Chemical products",
  "Chemical industry plant and equipment",
  "Rubber and plastic industry plant and equipment",
  "Health, medical and pharmaceutical",
  "Rubber products",
  "Furniture and linen",
  "Metal constructions for the building industry",
  "Heating, ventilation, air conditioning (HVAC) and refrigeration equipment",
  "Metal pipework, valves and containers",
  "Security equipment",
  "Hardware, ironmongery, cutlery and tools",
  "Timber, wooden products, machinery and equipment for the woodworking industry",
  "Building industry",
  "Civil engineering and building machinery and equipment",
  "Civil and marine engineering contractors",
  "Energy, fuel and water",
  "Environmental services, renewable energies",
  "Oil and gas industry plant and equipment",
  "Education and training",
  "Banking Sector",
  "International organisations, administrations and associations",
  "Social care, personal services",
  "Research and testing",
  "Information technology (IT) and Internet",
  "Technical offices and engineering consultancies, architects",
  "Courier services, telecommunications, radio and television",
  "Hospitality, tourism, hotel and catering industries",
  "Leisure, culture and entertainment",
  "Sports and leisure equipment",
  "Measuring and testing equipment",
  "Services to businesses",
  "Sourcing Agent",
  "Intending Agent",
  "Merchant Exporters",
  "Wholesale & Retail Traders",
  "Hire and rental services",
  "Hygiene and cleaning",
  "Financial and insurance services",
  "Charitable/Social Organisations",
  "Textile, clothing, leather, and shoemaking machinery and equipment",
  "Leathers, furs, and their products",
  "Clothing and footwear",
  "Textiles",
  "Precious stoneworking, watchmaking, and jewellery",
  "Transportation and logistics services",
  "Handling and storage plant and equipment",
  "Packaging machinery, equipment",
  "Electronic equipment",
  "Telecommunications equipment",
  "Electrical equipment",
  "Nuclear equipment",
  "Optical, photographic, and cinematographic equipment",
  "Industrial subcontractors",
  "Basic metal products",
  "Engines and mechanical parts",
  "Machinery and equipment for metalworking",
  "General traders, department and retail stores",
  "Paper and board making plant and equipment",
  "Printing and publishing",
  "Printing equipment",
  "Office and shop equipment",
  "Paper and board",
  "Glass, cement, and ceramics",
  "Quarried stone",
  "Mining, quarrying, and stoneworking plant and equipment",
  "Building industry",
  "Ores and minerals",
];

const chamberBenefits = [
  "Business Information and Advice",
  "Chamber Brand Affiliation",
  "Events and Networking",
  "Export Documentation Services",
  "International Trade Support",
  "Peer to Peer Development",
  "Policy, Lobbying and Advocacy",
  "Previous Chamber Member",
  "Raise Businesses Profile/ Services or Products",
  "Savings on Business Essentials",
  "Sector Specific Activities/ Campaigns",
  "Sharing Best Practice",
  "Strong & Trusted Business Relationships",
  "Training and Team Development",
];

const RegisterPage3 = () => {
  const navigate = useNavigate();
  let { state } = useLocation();
  state = state ?? {};
  const location = useLocation();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formSuccesful, setFormSuccesful] = useState(false);
  const [regiFormSuccess, setRegiFormSuccess] = useState(false);

  const handleConfirmChange = (e) => {
    const { value } = e.target;
    setConfirmPassword(value);
  };
  initialFormData.email = state.email ?? "";
  initialFormData.inputNumber = state.gst ?? "";

  //taking bith varibales here

  // const parsedEmail = new URLSearchParams(location.search).get("email");
  // const email = parsedEmail;
  //object for country

  //hear about us function
  const handleHearAboutUsChange = (event) => {
    const selectedOption = event.target.value;
    setFormData({
      ...formData,
      howDidYouKnowAboutUs: selectedOption,
    });
  };

  //handle country chnage
  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setFormData({
      ...formData,
      country: selectedCountry,
    });
  };

  //role
  const handleRoleChange = (event) => {
    const selectedRole = event.target.value;
    setFormData({
      ...formData,
      role: selectedRole,
    });
  };

  //alternative way
  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    console.log(formData)
  };

  //calling for data fetching (API)
  const handleRegiFormSubmit = async (e) => {
    setRegiFormSuccess(true);

    e.preventDefault();
    try {
      formData.pinCode = parseInt(formData.pinCode);
      formData.numberOfEmployees = parseInt(formData.numberOfEmployees);
      formData.phoneNo = parseInt(formData.phoneNo);
      formData.reqType = formData.reqType

      formData.reqType = formData.reqType === "B2BInterest" ? formData.reqType : "ePlatformReq";


      // form validation
      // ==================
      // ==================

      //for gst
      const gstNoRegex =
        /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1})$/g;

      // Define a regular expression pattern for website address

      const websiteRegex =
        /^(https?:\/\/)?([a-zA-Z0-9.-]+(\.[a-zA-Z]{2,})+)(\/\S*)?$/;

      // Form validation
      const validateField = (field, fieldName, regex) => {
        if (
          field === undefined ||
          (typeof field === "string" && field.trim() === "")
        ) {
          console.log("Invalid Form, field:", field);
          alert(`Invalid Form, ${fieldName} can not be empty`);
          return false;
        }
        if (regex && !regex.test(field)) {
          console.log("Invalid Form, field:", field);
          alert(`Invalid Form, ${fieldName} is not in the correct format`);
          return false;
        }

        return true;
      };

      // Validate website address
      const validateWebsite = (website, regex) => {
        // If the website address is empty, it's considered valid
        if (!website) {
          return true;
        }

        // Check if the website address matches the regex pattern
        if (regex && !regex.test(website)) {
          console.log("Invalid Form, website address:", website);
          alert("Invalid Form, Website Address is not in the correct format");
          return false;
        }

        return true;
      };

      // Validate password
      const validatePassword = (password) => {
        if (password === undefined || typeof password !== "string") {
          return false;
        }
        const passwordRegex =
          /^(?=.*[a-z])(?=.*\d)(?=.*[A-Z]?)(?=.*[!@#$%^&*()_+[\]{}|;:',.<>?~\\/-]).{8,15}$/;

        return passwordRegex.test(password);
      };

      // Validate non-zero number
      const validateNonZeroNumber = (value, fieldName) => {
        if (parseInt(value) === 0) {
          console.log(`Invalid Form, ${fieldName} cannot be 0`);
          alert(`Invalid Form, ${fieldName} cannot be 0`);
          return false;
        }
        return true;
      };

      // Validate each field
      if (!validateField(formData.selectMembership, "Membership Type")) return;
      if (!validateField(formData.companyName, "Company Name")) return;
      if (!validateField(formData.inputNumber, "CIN No./IEC No.")) return;
      if (!validateField(formData.gstNo, "GST No.", gstNoRegex)) return;
      // if (!validateWebsite(formData.websiteAdd, websiteRegex)) {
      //   return;
      // }
      if (!validateWebsite(formData.websiteAdd)) return;

      if (!validateField(formData.address1, "Address (Line 1)")) return;
      if (!validateField(formData.address2, "Address (Line 2)")) return;
      if (!validateField(formData.state, "State")) return;
      if (!validateField(formData.pinCode.toString(), "PIN Code")) return;
      if (!validateNonZeroNumber(formData.pinCode, "PIN Code")) return;

      if (!validateField(formData.businessCategory, "Business Category"))
        return;
      if (!validateField(formData.title, "Title")) return;
      if (
        !validateField(
          formData.numberOfEmployees.toString(),
          "Number of Employees"
        )
      )
        return;
      if (
        !validateNonZeroNumber(
          formData.numberOfEmployees,
          "Number of Employees"
        )
      )
        return;

      if (!validateField(formData.firstName, "First Name")) return;
      if (!validateField(formData.surName, "Surname")) return;
      if (!validateField(formData.role, "Role")) return;
      if (!validateField(formData.email, "Email")) return;
      if (!validatePassword(formData.password)) {
        alert(
          "Password must be 8-15 characters, contain at least one lowercase letter, one number, and may include one uppercase letter and one special character."
        );
        return;
      }
      if (!validateField(confirmPassword, "Confirm Password")) return;
      if (formData.password !== confirmPassword) {
        alert("Passwords do not match");
        return;
      }

      if (
        !validateField(
          formData.phoneNo.toString(),
          "Phone No.",
          /^(?:\d{10,15})$/
        )
      )
        return;
      if (!validateNonZeroNumber(formData.phoneNo, "Phone No.")) return;

      // if (!validateField(formData.registeredBank, "Registered Bank")) return;
      // if (
      //   !validateField(formData.registeredAccountNo.toString(), "Account No.")
      // )
      //   return;
      // if (!validateNonZeroNumber(formData.registeredAccountNo, "Account No."))
      //   return;

      // if (!validateField(formData.IFSCCode, "IFSC Code")) return;
      if (!formData.throughEmail && !formData.throughCalls) {
        alert("Please select at least one communication preference");
        return;
      }
      if (formData.howDidYouKnowAboutUs === "") {
        alert("Please select how you heard about us");
        return;
      }
      if (!formData.throughEmail && !formData.throughCalls) {
        alert("Please select at least one communication preference");
        return;
      }

      //seding api here----------------------------------------
      const res = await axios.post(getURLbyEndPoint("createClient"), {
        ...formData,
      });

      //here
      if (res.data.status) {
        toast.success(res.data.message);
        setFormSuccesful(true);
        setRegiFormSuccess(false);

        // setTimeout(() => {
        //   navigate(window.location.state || "/login");
        // }, 1000);
      } else {
        toast.error(res.data.message);
      }
    } catch (e) {
      // console.log(e);
      // console.log(e.response.data.message);
      console.log("worng enteries");
      console.log(e);

      toast.error(e.response.data.message);
      // toast.error(e.response.data.message);
    } finally {
      setRegiFormSuccess(false);
    }
  };

  //basic fuctions
  const handleMemberGuidClick = () => {
    // Check if the click event originated from the button
    window.open(
      "https://www.aecci.org.in/our-services/membership/guidelines-and-form/",
      "_blank"
    );
  };

  const handleMemberBenClick = () => {
    // Check if the click event originated from the button
    window.open(
      "https://www.aecci.org.in/membership-and-its-benefits/",
      "_blank"
    );
  };

  const handleClose = () => {
    setFormSuccesful(false);
    navigate("/login"); // Navigate to the login page
  };



  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const initialData = {};

    if (state.companyName || params.get("companyName")) {
      initialData.companyName = state.companyName ?? params.get("companyName");
    }
    if (state.email || params.get("email")) {
      initialData.email = state.email ?? params.get("email");
    }
    if (state.firstName || params.get("firstName")) {
      initialData.firstName = state.firstName ?? params.get("firstName");
    }
    if (state.surName || params.get("lastName")) {
      initialData.surName = state.surName ?? params.get("lastName");
    }
    if (state.country || params.get("country")) {
      initialData.country = state.country ?? params.get("country");
    }
    if (state.pinCode || params.get("pinCode")) {
      initialData.pinCode = state.pinCode ?? params.get("pinCode");
    }
    if (state.phoneNo || params.get("phoneNo")) {
      initialData.phoneNo = state.phoneNo ?? params.get("phoneNo");
    }
    if (state.gst || params.get("inputNumber")) {
      initialData.inputNumber = state.gst ?? params.get("inputNumber");
    }
    if (state.selectMembership || params.get("selectMembership")) {
      initialData.selectMembership = state.selectMembership ?? params.get("selectMembership");
    }

    if (params.get("reqType")) {
      initialData.reqType =  params.get("reqType");
    }

    setFormData((prevData) => ({ ...prevData, ...initialData }));
  }, [location.search]);

  return (
    <>
      <Layout>
        <EPlatformHeader
          text="Embark on a new era of seamless business solutions! AECCI proudly unveils its upgraded e-Platform, now featuring digital
services, expert consultancy, shipping document attestation, visa recommendation letter issuance, and robust support for
Trade disputes &amp; Arbitration matters—all under one roof. Elevate your experience and streamline success with AECCI&#39;s
enhanced capabilities!"
        />
        <div className="d-flex justify-content-center">
          {" "}
          <form
            className="main-width my-3 d-flex flex-column justify-content-start align-items-start"
            onSubmit={handleRegiFormSubmit}
          >
            <h1>JOIN NOW</h1>
            <p>
              You&#39;re just one step away from unlocking a world of
              opportunities. Please complete this form with precision using your
              accurate details. We&#39;re thrilled to see your interest in
              joining the AECCI Community! For Further assistance Contact our
              Chamber desk:
              <br />
              Board Line 022-41271145/46 or 8433720996
            </p>
            <h3 className="my-4">MEMBERSHIP TYPE</h3>
            {/* membership start */}
            <div className="w-100 mb-3 d-flex justify-content-between align-items-start">
              <div className="d-flex align-items-center">
                {" "}
                <label className="fw-semibold fs-5">
                  *SELECT YOUR MEMBERSHIP
                </label>{" "}
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>
                      As per the categories specified under the Types of
                      Membership for each company, kindly make your selection
                      accordingly. For details on membership categories, click
                      the &#39;Membership Benefits&#39; button. If Non- Member,
                      please select “Digital User” Option.
                    </Tooltip>
                  }
                >
                  <img
                    src={infoImg}
                    style={{ height: "6vh" }}
                    alt=""
                    srcset=""
                  />
                </OverlayTrigger>
              </div>

              <div className="d-flex flex-column">
                <select
                  style={{ width: "35vw" }}
                  type="email"
                  className="form-control rounded-0 "
                  onChange={handleInputChange}
                  value={formData.selectMembership}
                  name="selectMembership"
                  id="exampleInputEmail1"
                  required
                >
                  <option selected>Open this select menu</option>
                  <option value="Small Business (Associate)">
                    Small Business (Associate)
                  </option>
                  <option value="Start-Up">Start-Up</option>
                  <option value="Corporate">Corporate</option>
                  <option value="Corporate +">Corporate +</option>
                  <option value="Overseas">Overseas</option>
                  <option value="Non-Profit Organization">
                    Non-Profit Organization
                  </option>
                  <option value="Digital User">Digital User</option>
                </select>
                <span className="fs-6 my-4">
                  *Membership to the Chamber is valid for 12 months of a
                  financial year.
                </span>
                <div className="d-flex justify-content-around">
                  <button
                    type="button"
                    className="bg-color-2 m-1 px-3 py-2 rounded-1"
                    onClick={() => handleMemberGuidClick()}
                  >
                    MEMBERSHIP GUIDELINES
                  </button>
                  <button
                    type="button"
                    className="bg-color-2 m-1 px-3 py-2 rounded-1"
                    onClick={() => handleMemberBenClick()}
                  >
                    MEMBERSHIP BENEFITS
                  </button>
                </div>
              </div>
            </div>

            {/* Main Form====
          ============
          ============ */}

            <div className="w-100">
              {/* form 1========
            =============
            ============== */}
              <div>
                <div className="d-flex align-items-center">
                  {" "}
                  <h3 className="py-4">ABOUT COMPANY</h3>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip>
                        Input your correct company details based on the
                        incorporation and GST certificates. Our chamber
                        officials will verify this information for automated
                        inclusion on the member's login.
                      </Tooltip>
                    }
                  >
                    <img
                      src={infoImg}
                      style={{ height: "6vh" }}
                      alt=""
                      srcset=""
                    />
                  </OverlayTrigger>
                </div>
                <div className=" mb-3 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">*COMPANY NAME</label>
                  <div className="d-flex flex-column">
                    <input
                      style={{ width: "35vw" }}
                      className="form-control rounded-0 ms-5"
                      onChange={handleInputChange}
                      value={formData.companyName}
                      name="companyName"
                      type="text"
                      maxLength={50}
                      required
                    />
                  </div>
                </div>
                <div className="mb-3 py-2 d-flex justify-content-between align-items-start">
                  <label
                    className="fw-semibold fs-6
                
                "
                  >
                    *IEC CODE/ CIN NO.
                  </label>
                  <input
                    style={{ width: "35vw" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={formData.inputNumber}
                    name="inputNumber"
                    type="text"
                    required
                    disabled
                  />
                </div>
                <div className="mb-3 py-2 d-flex justify-content-between align-items-start">
                  <label
                    className="fw-semibold fs-6
                
                "
                  >
                    *GST NO.
                  </label>
                  <input
                    style={{ width: "35vw" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={formData.gstNo}
                    name="gstNo"
                    type="text"
                    required
                    maxLength={20}
                  />
                </div>

                <div className="w-100 mb-3 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">WEBSITE LINK</label>
                  <input
                    style={{ width: "35vw" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={formData.websiteAdd}
                    name="websiteAdd"
                    type="text"
                    maxLength={50}
                  />
                </div>
                <div className="w-100 mb-3 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">
                    *NUMBER OF EMPLOYEES
                  </label>
                  <input
                    onInput={(e) => {
                      e.target.value = Math.max(
                        0,
                        parseInt(e.target.value) || 0
                      )
                        .toString()
                        .slice(0, 5);
                    }}
                    style={{ width: "35vw" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={formData.numberOfEmployees}
                    name="numberOfEmployees"
                    type="text"
                    maxLength={5}
                    inputMode="numeric"
                    pattern="[0-9]*"
                    required
                  />
                </div>
                <div className="w-100 mb-3 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">*ADDRESS 1</label>
                  <div>
                    <input
                      style={{ width: "35vw" }}
                      className="form-control rounded-0"
                      onChange={handleInputChange}
                      value={formData.address1}
                      name="address1"
                      type="text"
                      pattern="[a-zA-Z0-9,. ]*"
                      title="Only alphabets, numbers, commas, periods, and spaces are allowed"
                      required
                      maxLength={50}
                    />
                    <p className="text-danger mb-0">
                      Only alphabets, numbers, commas, periods, and spaces are
                      allowed.
                    </p>
                  </div>
                </div>

                <div className="w-100 mb-3 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">*ADDRESS 2</label>
                  <input
                    style={{ width: "35vw" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={formData.address2}
                    name="address2"
                    type="text"
                    pattern="[a-zA-Z0-9,. ]*"
                    title="Only alphabets, numbers, commas, and spaces are allowed"
                    required
                    maxLength={50}
                  />
                </div>
                <div className="w-100 mb-3 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">*CITY</label>
                  <input
                    style={{ width: "35vw" }}
                    className="form-control rounded-0 ms-5"
                    type="text"
                    onChange={handleInputChange}
                    value={formData.city}
                    name="city"
                    required
                    maxLength={30}
                  />
                </div>
                {/* <div className="w-100 mb-3 py-2 d-flex justify-content-between align-items-start">
                <label className="fw-semibold fs-6">ADDRESS (LINE 4)</label>
                <input
                  style={{ width: "35vw" }}
                  className="form-control rounded-0 ms-5"
                  type="text"
                />
              </div> */}
                <div className="w-100 mb-3 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">*STATE </label>

                  <input
                    style={{ width: "35vw" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={formData.state}
                    name="state"
                    type="text"
                    required
                    maxLength={30}
                  />
                </div>

                <div className="w-100 mb-3 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">*COUNTRY</label>

                  <select
                    style={{ width: "35vw" }}
                    className="form-control rounded-0 ms-5"
                    required
                    value={formData.country}
                    onChange={handleCountryChange}
                    name="country"
                  >
                    <option value="">Select a country...</option>
                    {country_list.map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                  {/* {selectedCountry && <p>You selected: {formData.country}</p>} */}
                </div>

                <div className="w-100 mb-3 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">*PINCODE </label>
                  <input
                    onInput={(e) => {
                      e.target.value = Math.max(
                        0,
                        parseInt(e.target.value) || 0
                      )
                        .toString()
                        .slice(0, 6);
                    }}
                    style={{ width: "35vw" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={formData.pinCode}
                    name="pinCode"
                    type="text"
                    required
                    pattern="[0-9]*"
                    inputMode="numeric"
                    minLength={6}
                    maxLength={6}
                  />
                </div>
                <div className="w-100 mb-3 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">*BUSINESS CATEGORY</label>
                  <select
                    style={{ width: "35vw" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={formData.businessCategory}
                    name="businessCategory"
                    required
                  >
                    <option value="" disabled>
                      Select a business category
                    </option>
                    {businessCategories.map((category, index) => (
                      <option key={index} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-100 mb-3 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">
                    * HOW DID YOU HEAR ABOUT US ?
                  </label>
                  <select
                    style={{ width: "35vw" }}
                    className="form-control rounded-0 ms-5"
                    value={formData.howDidYouKnowAboutUs}
                    onChange={handleHearAboutUsChange}
                    name="hearAboutUs"
                    required
                  >
                    <option value="">Select an option...</option>
                    {howDidYouKnowAboutUs.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-100 mb-3 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">
                    *WHY YOU WANT TO JOIN AS A MEMBER OF THE AECCI ?
                  </label>
                  <select
                    style={{ width: "35vw" }}
                    className="form-control rounded-0 ms-5"
                    value={formData.chamberBenefits}
                    onChange={handleInputChange}
                    name="chamberBenefits"
                    required
                  >
                    <option value="">Select an option...</option>
                    {chamberBenefits.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {/* ============
            second form=
            ============ */}
              <div>
                <div className="d-flex align-items-center">
                  {" "}
                  <h3 className="py-4">ABOUT YOU</h3>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip>
                        Enter the details of the company’s Stakeholders or
                        partners, including correct bank and contact information
                        of the company.
                      </Tooltip>
                    }
                  >
                    <img
                      src={infoImg}
                      style={{ height: "6vh" }}
                      alt=""
                      srcset=""
                    />
                  </OverlayTrigger>
                </div>
                <div className=" mb-3 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">*TITLE</label>
                  <div className="d-flex flex-column">
                    <select
                      style={{ width: "35vw" }}
                      className="form-select rounded-0 ms-5"
                      onChange={handleInputChange}
                      value={formData.title}
                      name="title"
                      required
                    >
                      <option value="">Select a title</option>
                      {titleOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="w-100 mb-3 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">*FIRST NAME</label>
                  <input
                    style={{ width: "35vw" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={formData.firstName}
                    name="firstName"
                    type="text"
                    required
                    maxLength={15}
                  />
                </div>

                <div className="w-100 mb-3 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">*SURNAME</label>
                  <input
                    style={{ width: "35vw" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={formData.surName}
                    name="surName"
                    type="text"
                    required
                    maxLength={15}
                  />
                </div>
                <div className="w-100 mb-3 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">*ROLE</label>

                  <select
                    style={{ width: "35vw" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleRoleChange}
                    value={formData.role}
                    name="role"
                    required
                  >
                    <option value="">Select a role...</option>
                    {role.map((role, index) => (
                      <option key={index} value={role}>
                        {role}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-100 mb-3 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">*EMAIL</label>
                  <input
                    style={{ width: "35vw" }}
                    className="form-control rounded-0 ms-5"
                    value={formData.email}
                    name="email"
                    type="email"
                    disabled
                  />
                </div>
                <div className="w-100 mb-3 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">*PASSWORD</label>
                  <input
                    style={{ width: "35vw" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={formData.password}
                    name="password"
                    type="password"
                    required
                    maxLength={30}
                  />
                </div>
                <div className="w-100 mb-3 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">*CONFIRM PASSWORD</label>
                  <input
                    style={{ width: "35vw" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleConfirmChange}
                    value={confirmPassword}
                    name="confirmPassword"
                    type="text"
                    required
                    maxLength={30}
                  />
                </div>

                <div className="w-100 mb-3 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">*MOBILE NO.</label>
                  <input
                    onInput={(e) => {
                      e.target.value = Math.max(
                        0,
                        parseInt(e.target.value) || 0
                      )
                        .toString()
                        .slice(0, 10);
                    }}
                    style={{ width: "35vw" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={formData.phoneNo}
                    name="phoneNo"
                    type="text"
                    required
                  />
                </div>
                <div className="w-100 mb-3 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">COMPANY TEL NO.</label>
                  <input
                    onInput={(e) => {
                      e.target.value = Math.max(
                        0,
                        parseInt(e.target.value) || 0
                      )
                        .toString()
                        .slice(0, 20);
                    }}
                    style={{ width: "35vw" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={formData.telephoneNo}
                    name="telephoneNo"
                    type="text"
                    required
                  />
                </div>
                <div className="d-flex align-items-center">
                  {" "}
                  <h3 className="py-4">BANK DETAILS</h3>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip>
                        Provide the bank details of the company responsible for
                        service payments. Payments will only be accepted from
                        this registered bank account.
                      </Tooltip>
                    }
                  >
                    <img
                      src={infoImg}
                      style={{ height: "6vh" }}
                      alt=""
                      srcset=""
                    />
                  </OverlayTrigger>
                </div>
                <div className="w-100 mb-3 py-2 d-flex justify-content-between align-items-start">
                  <div>
                    <label className="fw-semibold fs-6">REGISTERED BANK</label>
                    <p className="text-danger">
                      Do not fill personal bank details.
                    </p>
                  </div>

                  <select
                    style={{ width: "35vw" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={formData.registeredBank}
                    name="registeredBank"
                  >
                    <option value="">Select a bank</option>
                    {bankNames.map((bank, index) => (
                      <option key={index} value={bank}>
                        {bank}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="w-100 mb-3 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">ACCOUNT NO. </label>
                  <input
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/\D/g, "");
                      e.target.value = e.target.value.slice(0, 20);
                    }}
                    style={{ width: "35vw" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={formData.registeredAccountNo}
                    name="registeredAccountNo"
                    type="text"
                  />
                </div>
                <div className="w-100 mb-3 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">IFSC CODE</label>
                  <input
                    style={{ width: "35vw" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={formData.IFSCCode}
                    name="IFSCCode"
                    type="text"
                    maxLength={15}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              {" "}
              <h3 className="py-4">COMMUNICATION PREFERENCES</h3>
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip>
                    Indicate your preferred mode of communication for receiving
                    important updates.
                  </Tooltip>
                }
              >
                <img src={infoImg} style={{ height: "6vh" }} alt="" srcset="" />
              </OverlayTrigger>
            </div>
            <span className="my-2 pb-2">
              How you would like to communicate with us for important updates
            </span>
            <div className="d-flex justify-content-between align-items-center w-40 my-2">
              <h6>THROUGH EMAILS ?</h6>
              <div>
                <input
                  style={{ height: "32px", width: "32px" }}
                  className="form-check-input"
                  type="checkbox"
                  id="checkboxNoLabel"
                  value={formData.throughEmail}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    setFormData({
                      ...formData,
                      throughEmail: isChecked,
                    });
                  }}
                  aria-label="..."
                />
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center w-40 my-2">
              <h6>THROUGH TELEPHONE CALLS ?</h6>
              <div>
                <input
                  style={{ height: "32px", width: "32px" }}
                  className="form-check-input"
                  type="checkbox"
                  id="checkboxNoLabel"
                  value={formData.throughCalls}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    setFormData({
                      ...formData,
                      throughCalls: isChecked,
                    });
                  }}
                  aria-label="..."
                />
              </div>
            </div>
            <div className="d-flex justify-content-around align-items-center my-3 gap-4">
              <div>
                <input
                  style={{ height: "32px", width: "32px" }}
                  className="form-check-input"
                  type="checkbox"
                  id="checkboxNoLabel"
                  defaultValue
                  aria-label="..."
                  required
                />
              </div>
              <h6>*I AGREE TO RECEIVE INFORMATION FROM AECCI</h6>
            </div>
            <div className="d-flex justify-content-center align-items-center my-3 gap-4">
              <div>
                <input
                  style={{ height: "32px", width: "32px" }}
                  className="form-check-input"
                  type="checkbox"
                  id="checkboxNoLabel"
                  defaultValue
                  aria-label="..."
                  required
                />
              </div>
              <h6>
                *I ACCEPT THE TERMS OF USE AND THE PERSONAL DATE PRIVACY USE.
              </h6>
            </div>
            <div className="">
              <p>
                <b className=""> Note: </b> After the submission of the form,
                the AECCI team will review the information provided and endeavor
                to respond within 48 hours. It&#39;s important to note that
                approval is not guaranteed and is subject to the accuracy and
                completeness of the details submitted, so please ensure that all
                details are accurately filled to facilitate a seamless
                processing experience.
              </p>
            </div>
            <div className="my-4 w-100 d-flex justify-content-center gap-5">
              <button
                type="button"
                className="bg-color-2 m-1 px-3 py-2 rounded-1"
              >
                CANCEL
              </button>
              <button
                type="submit"
                className="setup-bg-color m-1 px-3 py-2 rounded-1 border-0"
                disabled={regiFormSuccess}
              >
                {!regiFormSuccess ? "REGISTER" : "Processing..."}
              </button>
            </div>
          </form>
        </div>

        <Modal
          show={formSuccesful}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <h1 className="text-center">
              <b>THANK YOU</b>
            </h1>
            <p className="text-center">
              Your registration form is in Process. AECCI team will contact you
              within 48 hours.
            </p>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
          {/* <Modal.Header closeButton>
            <Modal.Title>Successfully Registerd!!!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Our Team will get back to you in next 48 hours.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer> */}
        </Modal>
      </Layout>
    </>
  );
};

export default RegisterPage3;
