import React, { useState, useEffect } from "react";
import chiefimg from "../../assets/chief.png";
import DigLib from "../../assets/DIgpub.jpg";
import Prof1 from "../../assets/profile1.png";
import Prof2 from "../../assets/profile2.png";
import Prof3 from "../../assets/profile3.png";
import Prof4 from "../../assets/profile4.png";
import Prof5 from "../../assets/profile5.png";
import Prof6 from "../../assets/profile6.png";
import Layout from "../../components/Layouts/Layouts/Layout";
import Layout2 from "../../components/Layouts/Layouts/Layout2";
import WelcomeBanner from "../../components/basic/WelcomeBanner";
import { IoWallet, IoLogOut } from "react-icons/io5";
import { useNavigate } from "react-router";
import { FaEdit } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";
import { FaTriangleExclamation } from "react-icons/fa6";
import { IoCallSharp } from "react-icons/io5";
import { MdAttachEmail } from "react-icons/md";
import { ImLocation2 } from "react-icons/im";
import { LuActivitySquare } from "react-icons/lu";
import { FaBoxOpen } from "react-icons/fa";
import { FaCalendarDays } from "react-icons/fa6";
import { RiErrorWarningFill } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import NoticePopup from "../../components/basic/NoticePopup";


import { Link } from "react-router-dom";
import { getAuthUserlocal } from "../../store/services";
import { useAuth } from "../../context/auth";
import axios from "axios";
import { getURLbyEndPoint } from "../../store/api";

import DashboardHeader from "../../components/basic/DashboardHeader";
import BookCarousel from "../test/BookCarousel";
import Contactinfo from "../../components/basic/Contactinfo";
import congratsGif from "../../assets/gifs/congrats.gif";
import alertGif from "../../assets/gifs/alert.gif";

import QuickLinks from "../../components/basic/QuickLinks";
import DemoModal from "../../components/basic/DemoModal";
const UserDashboard = () => {
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();
  const userId = auth ? auth.user : null;

  const handleLogout = () => {
    localStorage.removeItem("authUser");
    localStorage.removeItem("auth");

    navigate("/login");
  };

  const handleCompanyProfileEdit = () => {
    navigate("/commercial-directory");
  };

  const [comDirData, setComDirData] = useState("");


  const [showDemo, setShowDemo] = useState(false);
  const handleCloseDemo = () => setShowDemo(false);
  const handleShowDemo = () => setShowDemo(true);



  const [welcomeBanner, setWelcomeBanner] = useState(false);

  useEffect(() => {
    const isPopupShown = localStorage.getItem('isLoginPopupShown');
    if (!isPopupShown) {
      setWelcomeBanner(true);
      localStorage.setItem('isLoginPopupShown', 'true');
    }
  }, []);

  const toggleModal = () => setWelcomeBanner(!welcomeBanner);


  useEffect(() => {
    // Make the GET API call here
    const fetchData = async () => {
      try {
        const res = await axios.get(
          getURLbyEndPoint("getComDirDetails") + userId,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        const data = res.data.data;

        if (res.data.status) {
          setComDirData(data);
        }
      } catch (error) {
        console.error("something went Wrong!!");
      }
    };
    if (getAuthUserlocal().selectMembership !== "Digital User") {
    fetchData();
  }
  }, [auth.token]);

  const [showModal, setShowModal] = useState(false);
  const [showMemModal, setShowMemModal] = useState(false);
  const [showTrialModal, setShowTrialModal] = useState(false);
  const [showCollabModal, setShowCollabModal] = useState(false);
  const [showTrialExpModal, setShowTrialExpModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const handleMemClose = () => setShowMemModal(false);
  const handleMemShow = () => setShowMemModal(true);
  const handleTrialClose = () => setShowTrialModal(false);
  const handleTrialShow = () => setShowTrialModal(true);
  const handleCollabClose = () => setShowCollabModal(false);
  const handleCollabShow = () => setShowCollabModal(true);
  const handleTrialExpClose = () => setShowTrialExpModal(false);
  const handleTrialExpShow = () => setShowTrialExpModal(true);

  useEffect(() => {
    // Check conditions and open the modal if necessary
    const isTrial = getAuthUserlocal()?.isTrial
      ? getAuthUserlocal()?.isTrial
      : null;

    const shouldShowModal = () => {
      const userBalance = getAuthUserlocal().balance;

      if (
        !localStorage.getItem("modalShown") &&
        userBalance < 3000 &&
        !isTrial && getAuthUserlocal().reqType !== "collaborationReq"
      ) {
        handleShow();
        localStorage.setItem("modalShown", "true");
      }
    };

    const shouldShowMemModal = () => {
      const currentDate = new Date();
      let userValidity = getAuthUserlocal().validUpTo;

      // If userValidity is a string, parse it into a Date object
      if (typeof userValidity === "string") {
        userValidity = new Date(userValidity);
      }
      const daysDifference =
        (userValidity - currentDate) / (1000 * 60 * 60 * 24);

      if (
        !localStorage.getItem("MemmodalShown") &&
        daysDifference <= 30 &&
        !isTrial
      ) {
        handleMemShow();
        localStorage.setItem("MemmodalShown", "true");
      }
    };

    const shouldShowTrialModal = () => {
      const user = getAuthUserlocal();
      const modalShowTrial = user.isTrial && user?.clientLogs?.length === 1;

      if (modalShowTrial) {
        handleTrialShow();
      }
    };


    const shouldShowCollabModal = () => {
      const user = getAuthUserlocal();
      const modalShowCollab = user.reqType === "collaborationReq" && user?.clientLogs?.length === 1;

      if (modalShowCollab) {
        handleCollabShow();
      }
    };

    const shouldShowTrialExpModal = () => {
      const user = getAuthUserlocal();
      const modalShowTrial = user.isTrial && user?.validUpTo;

      if (modalShowTrial) {
        const currentDate = new Date();
        const validUpToDate = new Date(user.validUpTo);
        if (currentDate >= validUpToDate) {
          handleTrialExpShow();
        }
      }
    };



    shouldShowMemModal();
    shouldShowModal();
    shouldShowTrialModal();
    shouldShowCollabModal();
    shouldShowTrialExpModal();
  }, []); // Empty dependency array ensures the effect runs only once on mount

  return (
    <>
      <Layout>
        <Layout2>
          <DashboardHeader />
          <NoticePopup isOpen={welcomeBanner} url={"/b2b-connect"} toggle={toggleModal} />
          {/* 
        =====================
        Dashboard-body=======
        ===================== */}
          <div className="d-flex flex-column w-100">
            <hr className="mt-4 mb-4" />
            {getAuthUserlocal().selectMembership !== "Digital User" ? (
              <>
              <div className="d-flex justify-content-between align-items-end">
                <h4>
                  You are currently our
                  <span className="text-support">
                    {" "}
                    {/* Small Business (Associate) Member. */}
                    {getAuthUserlocal().selectMembership
                      ? getAuthUserlocal().selectMembership === "Small Business"
                        ? "Small Business (Associate)"
                        : getAuthUserlocal().selectMembership
                      : null}{" "}
                    {""}
                      {getAuthUserlocal().selectMembership === "Collaboration" ? "Partner" : "Member"}.
                  </span>
                </h4>
                <h6 style={{ fontSize: "0.9rem", cursor: "pointer", color: "#5495ff" }}  onClick={handleShowDemo}>Show Demo Tour 
      </h6>
      <DemoModal show={showDemo} handleCloseDemo={handleCloseDemo} heading="AECCI e-Platform Demo Tour" videoUrl="https://aecci-bucket-new.s3.amazonaws.com/aecci+assets/DashboardIntro.mp4" />

                </div>  
                <div className="square border mt-2">
                  <div className="d-flex align-items-center justify-content-between mx-5 mt-1">
                    <div
                      className="d-flex align-items-center"
                      style={{ height: "3rem" }}
                    >
                      <img
                        src={comDirData?.companyLogo}
                        height="100%"
                        width="auto"
                        alt=""
                        srcset=""
                      />{" "}
                      <h4 className=" ms-3 mb-0 text-center text-support text-color-heading">
                        {comDirData?.companyName
                          ? comDirData.companyName
                          : "Company Name"}
                      </h4>
                    </div>
                    <button
                      className="border-0 bg-white cursor-pointer"
                      onClick={handleCompanyProfileEdit}
                    >
                      <h5 className="glowText">Edit</h5>
                      
                    </button>
                    {/* <FaEdit
                      className="font-size cursor-pointer"
                      onClick={handleCompanyProfileEdit}
                    /> */}
                  </div>
                  <hr className="m-2" />

                  <div className="d-flex justify-content-between mx-5 my-2">
                    <div className="text-start text-secondary mb-2">
                      <h4 className="text-support">
                        Owner's Name : {comDirData.ownersName}
                      </h4>
                      <h6>
                        {" "}
                        <MdAttachEmail className="me-2 text-support" />
                        Email : {comDirData.email}
                      </h6>
                      <h6>
                        <FaCalendarDays className="me-2 text-support" />
                        Year of Establishment : {comDirData.establishmentYear}
                      </h6>
                      <h6>
                        <ImLocation2 className="me-2 text-support" />
                        Company Address : {comDirData.companyAdd}
                      </h6>

                      <h6>
                        <IoCallSharp className="me-2 text-support" />
                        Company Mobile No. : {comDirData.mobileNo}
                      </h6>
                      <h6>
                        <FaBoxOpen className="me-2 text-support" />
                        Company Product : {comDirData.companyProduct}
                      </h6>
                      <h6>
                        <LuActivitySquare className="me-2 text-support" />
                        Company Activity : {comDirData.companyActivity}
                      </h6>
                    </div>
                  </div>
                </div>
                {/* <span>This is for you</span> */}
              </>
            ) : (
              <>
                <h4>
                  You are currently our
                  <span className="text-support"> Digital User</span>
                </h4>

                <div className="d-flex w-100 justify-content-between gap-5 mt-4 mb-2">
                  <Button
                    style={{ background: "#0b3b5d" }}
                    className="w-50 fw-semibold"
                    onClick={() => navigate("/membership-services")}
                  >
                    KNOW MORE ABOUT OUR DIGITAL USER
                  </Button>{" "}
                  <Button
                    variant="secondary"
                    className="w-50 fw-semibold"
                    onClick={() => navigate("/upgrade-membership")}
                  >
                    UPGRADE YOUR MEMBERSHIP
                  </Button>{" "}
                </div>
              </>
            )}
            <Contactinfo />

            <div>
              <BookCarousel />
            </div>

            <QuickLinks />
          </div>

          <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModal}
            onHide={handleClose}
          >
            <Modal.Body className="mb-3">
              <div className="text-center">
                <div className="text-end">
                  <IoClose
                    className="icon-size text-danger"
                    onClick={handleClose}
                  />
                </div>
                <div>
                  <RiErrorWarningFill
                    className="text-danger"
                    style={{ fontSize: "8rem" }}
                  />
                </div>
                <h4>Your wallet account balance is low!!!</h4>
                <h6 className="text-secondary">
                  *For service access, please make a deposit into your account
                  in advance.
                </h6>
                <Button
                  className="setup-bg-color"
                  onClick={() => navigate("/wallet")}
                >
                  Deposit Funds
                </Button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showMemModal}
            onHide={handleMemClose}
          >
            {getAuthUserlocal().selectMembership === "Digital User" ? (
              <>
                <Modal.Body className="mb-3">
                  <div className="text-center">
                    <div className="text-end">
                      <IoClose
                        className="icon-size text-danger"
                        onClick={handleMemClose}
                      />
                    </div>
                    <div>
                      <RiErrorWarningFill
                        className="text-danger"
                        style={{ fontSize: "8rem" }}
                      />
                    </div>
                    <h4>Upgrade Your Membership!</h4>
                    <h6 className="text-secondary">
                      To enjoy the benefits of our services, please Upgrade Your
                      Membership.
                    </h6>
                    <Button
                      className="setup-bg-color"
                      onClick={() => navigate("/upgrade-membership")}
                    >
                      Upgrade Membership Now
                    </Button>
                  </div>
                </Modal.Body>
              </>
            ) : (
              <>
                <Modal.Body className="mb-3">
                  <div className="text-center">
                    <div className="text-end">
                      <IoClose
                        className="icon-size text-danger"
                        onClick={handleMemClose}
                      />
                    </div>
                    <div>
                      <RiErrorWarningFill
                        className="text-danger"
                        style={{ fontSize: "8rem" }}
                      />
                    </div>
                    <h4>Your Membership Has Expired!</h4>
                    <h6 className="text-secondary">
                      *To continue enjoying all the benefits of our services,
                      please renew your membership.
                    </h6>
                    <Button
                      className="setup-bg-color"
                      onClick={() => navigate("/membership-services")}
                    >
                      Renew Membership Now
                    </Button>
                  </div>
                </Modal.Body>
              </>
            )}
          </Modal>

          <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showTrialModal}
            onHide={handleTrialClose}
          >
            <Modal.Body className="mb-3">
              <div className="text-center">
                <div className="text-end">
                  <IoClose
                    className="icon-size text-danger"
                    onClick={handleTrialClose}
                  />
                </div>
                <img
                  src={congratsGif}
                  alt="Congrats GIF"
                  width="250px"
                  height="100%"
                />
                <h4>CONGRATULATION!!!</h4>
                <h6 className="text-secondary">
                  You've Unlocked your Trial version Start exploring and take
                  your business to new heights! For any assistance, feel free to
                  contact our support team.
                </h6>
              </div>
            </Modal.Body>
          </Modal>



          <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showCollabModal}
            onHide={handleCollabClose}
          >
            <Modal.Body className="mb-3">
              <div className="text-center">
                <div className="text-end">
                  <IoClose
                    className="icon-size text-danger"
                    onClick={handleCollabClose}
                  />
                </div>
                <img
                  src={congratsGif}
                  alt="Congrats GIF"
                  width="250px"
                  height="100%"
                />
                <h4>CONGRATULATION!!!</h4>
                <h5 className="text-secondary">
                You Are On-boarding process has been Approved! 
                </h5>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showTrialExpModal}
            onHide={handleTrialExpClose}
          >
            <Modal.Body className="mb-3">
              <div className="text-center">
                <div className="text-end">
                  <IoClose
                    className="icon-size text-danger"
                    onClick={handleTrialExpClose}
                  />
                </div>
                <img
                  src={alertGif}
                  alt="alert GIF"
                  width="250px"
                  height="100%"
                />
                <h4>ALERT!!!</h4>
                <h6 className="text-secondary">
                  Your Trial Version access is expiring, You can extend the
                  period call chember desk for more information.
                </h6>
              </div>
            </Modal.Body>
          </Modal>
        </Layout2>
      </Layout>
    </>
  );
};

export default UserDashboard;
