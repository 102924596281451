import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import EventBanner from "../../assets/popupBanners/B2bBanner.png"
import { Link } from 'react-router-dom';


const NoticePopup = (props) => {
 return (
    <div>
      <Modal size="lg" show={props.isOpen} onHide={props.toggle}  centered>
      
        <Modal.Body>
          <Link to={props.url? props.url : "#"}>
            <img className="img-fluid" src={EventBanner} alt='' width={800}/>
          </Link>
        </Modal.Body>
     
      </Modal>
    </div>
 );
};

export default NoticePopup;