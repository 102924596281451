import React, { useState, useEffect } from "react";
import { SlLogout, SlWallet } from "react-icons/sl";
import { useNavigate } from "react-router";
import { FaPowerOff } from "react-icons/fa6";

import { Link } from "react-router-dom";
import { getAuthUserlocal } from "../../store/services";
import axios from "axios";
import { getURLbyEndPoint } from "../../store/api";
import { FaTriangleExclamation } from "react-icons/fa6";
import { FcMoneyTransfer } from "react-icons/fc";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const DashboardHeader = () => {
  const navigate = useNavigate();

  //functions
  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };


  return (
    <>
      <div className="w-100">
        <Row>
          <Col xs={8}>
            <Link
              to="/User-dashboard"
              className="text-spacing fs-1 fw-medium text-support"
              style={{ textDecoration: "none" }}
            >
              DASHBOARD
            </Link>{" "}
          </Col>
          <Col className="d-flex align-items-center">
            {getAuthUserlocal().balance < 3000 &&
              !getAuthUserlocal().specialControl?.accessList?.includes(
                "walletExceed"
              ) &&
              !getAuthUserlocal().isTrial && (
                <>
                  <FaTriangleExclamation className="fs-5 cursor-pointer text-danger pulse" />
                </>
              )}

            <div className="text-center me-1">
              <FcMoneyTransfer
                className="icon-size red-shade cursor-pointer"
                onClick={() => navigate("/wallet")}
              />
            </div>
            <h5 className="text-success me-5 mb-0">
              ₹{getAuthUserlocal().balance}
            </h5>
            <FaPowerOff
              className="icon-size cursor-pointer text-danger"
              onClick={handleLogout}
            />
          </Col>{" "}
        </Row>

        <Row>
          <Col xs={7}>
            <h3 className="text-secondary">
              {" "}
              {getAuthUserlocal().companyName}
            </h3>
          </Col>

          <Col>
  {getAuthUserlocal().selectMembership !== "Digital User" ? (
    getAuthUserlocal().selectMembership === "Collaboration" ? (
      <h5>
        Collaboration No. :-{" "}
        <span className="text-secondary">
          {getAuthUserlocal().memberShipNo}
        </span>
      </h5>
    ) : (
      <h5>
        Membership No. :-{" "}
        <span className="text-secondary">
          {getAuthUserlocal().memberShipNo}
        </span>
      </h5>
    )
  ) : (
    <h5>
      AECCI Code No. :-{" "}
      <span className="text-secondary">
        {getAuthUserlocal().memberShipNo}
      </span>
    </h5>
  )}
</Col>

        </Row>

        <Row>
          <Col xs={7}>
            <h5>
              {window.location.pathname === "/User-dashboard" && (
                <>
                  WELCOME, <br />
                </>
              )}
              {getAuthUserlocal().email}
            </h5>
          </Col>
          <Col>
            <h5>
              Valid Upto –{" "}
              <span className="text-secondary ">
                {" "}
                {getAuthUserlocal().validUpTo}
              </span>
            </h5>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DashboardHeader;

{
  /* <div className="d-flex justify-content-between align-items-center mt-2">
<div className="">
  <Link
    to="/User-dashboard"
    className="text-spacing fs-1 fw-medium"
    style={{ textDecoration: "none", color: "#2c2392" }}
  >
    DASHBOARD
  </Link>{" "}
  <h3 className="text-secondary">
    {getAuthUserlocal().companyName}
  </h3>
  <h5>WELCOME, {getAuthUserlocal().email}</h5>
</div>
<div className="d-flex flex-column justify-content-center align-items-end">
  <div className="mb-4 d-flex float-end">
    <div className="d-flex">
      <FaTriangleExclamation className="fe-5 cursor-pointer text-danger pulse" />
      <div>
        <FcMoneyTransfer className="icon-size red-shade me-5" />
        <h6 className="text-success">
          {getAuthUserlocal().balance}
        </h6>
      </div>
    </div>
    <FaPowerOff
      className="icon-size cursor-pointer primary-color"
      onClick={handleLogout}
    />
  </div>
  <div>
    {getAuthUserlocal().selectMembership !== "Digital User" ? (
      <h5>
        Membership No. :-{" "}
        <span className="text-secondary ">
          {getAuthUserlocal().memberShipNo}
        </span>
      </h5>
    ) : (
      <h5>
        AECCI Code No. :-{" "}
        <span className="text-secondary ">
          {getAuthUserlocal().memberShipNo}
        </span>
      </h5>
    )}

    <h5>
      Valid Upto :–{" "}
      <span className="text-secondary ">
        {getAuthUserlocal().validUpTo}
      </span>
    </h5>
  </div>
</div>
</div>{" "} */
}
