import React, { useState, useRef, useEffect } from "react";
import Layout from "../../components/Layouts/Layouts/Layout";
import EPlatformHeaderimg from "../../assets/EplatformHeaderimg.avif";
import { Button, Table, Row, Col, Form, Spinner } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import InfoImg from "../../assets/infoimg.png";
import axios from "axios";
import { getURLbyEndPoint } from "../../store/api";
import { BiSolidFilePdf } from "react-icons/bi";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BsEye } from "react-icons/bs";

import { LuCheckCircle } from "react-icons/lu";
import "./style.css";
// import {
//   loadCaptchaEnginge,
//   LoadCanvasTemplate,
//   validateCaptcha,
// } from "react-simple-captcha";

const RegisterDocumentUpload = () => {
  const hiddenFileInputs = {
    membershipForm: useRef(null),
    coverLetter: useRef(null),
    indemnityBond: useRef(null),
    iecLicenseCopy: useRef(null),
    gstCertificate: useRef(null),
    certificateOfRegistration: useRef(null),
    passportSizePhoto: useRef(null),
    paymentProof: useRef(null),
  };

  const [email, setEmail] = useState("");
  const [emailVerified, setEmailVerified] = useState(false);
  const [docUploadSuccess, setDocUploadSuccess] = useState(false);
  const [membershipType, setMembershipType] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [documentFiles, setDocumentFiles] = useState({});
  const [tips, setTips] = useState([]);
  const [pdfLinks, setPdfLinks] = useState([]);
  const [formSubmissionSucessful, setFormSubmissionSucessful] = useState(true);
  const [joiningFees, setJoiningFees] = useState("");
  const [annualFees, setAnnualFees] = useState("");

  const acceptedFileTypes = [
    "application/pdf",
    "application/x-pdf",
    "application/acrobat",
    "applications/vnd.pdf",
  ];
  const handleClick = (documentType) => {
    const fileInput = hiddenFileInputs[documentType];
    if (fileInput && fileInput.current) {
      fileInput.current.click();
      setDocumentType(documentType);
    }
  };

  const handleChange = (e, documentType) => {
    const fileInput = e.target;
    if (fileInput && fileInput.files.length > 0) {
      const selectedFile = fileInput.files[0];

      // Check file type
      if (!selectedFile.type.includes("image")) {
        alert("Please upload an image file.");
        return;
      }

      // Check file size (5MB limit)
      const maxSize = 5 * 1024 * 1024; // 3MB in bytes
      if (selectedFile.size > maxSize) {
        alert("File size exceeds the limit of 5MB.");
        return;
      }

      setDocumentFiles((prevFiles) => ({
        ...prevFiles,
        [documentType]: selectedFile,
      }));

      console.log(`${documentType} File Selected:`, selectedFile);
    }
  };
  const handleEmailCheck = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(getURLbyEndPoint("uploadDocCheckEmail"), {
        email,
      });

      if (res.data.status) {
        alert("Email Verified Successfully");
        setEmailVerified(true);

        const receivedMembershipType = res.data.membershipType;

        setMembershipType(receivedMembershipType);

        const formFields = { ...getFormFields(receivedMembershipType) };
        setDocumentFiles(formFields.values);
        setTips(formFields.tips);
        setPdfLinks(formFields.pdfValues);
        setJoiningFees(formFields.joiningFees);
        setAnnualFees(formFields.annualFees);
      } else {
        console.log("Email Doesn't exist. Please try a different email.");
        alert("Email doesn't exist. Please try a different email.");
      }

      return res.data.status;
    } catch (error) {
      console.error("Error checking email:", error);

      if (error.response) {
        console.log(error.response.data.message);
        alert(error.response.data.message);
      } else if (error.request) {
        console.log("Server not responding. Please try again later.");
        alert("Server not responding. Please try again later.");
      } else {
        console.error("Error:", error.message);
        alert("An error occurred. Please try again.");
      }

      return false;
    }
  };

  // const initializeFileInputs = (type) => {
  //   const newFileInputs = {};

  //   membershipWithType
  //     .find((m) => m.name === type)
  //     .options.forEach((option) => {
  //       newFileInputs[option] = {
  //         ref: useRef(null),
  //         file: null,
  //       };
  //     });

  //   setFileInputs(newFileInputs);
  // };

  // //capcha setup
  // const [isCaptchaCorrect, setIsCaptchaCorrect] = useState(false);
  // const [captchaValue, setCaptchaValue] = useState("");
  // const [enteredCaptcha, setEnteredCaptcha] = useState("");
  // const captcha_length = 6;

  // // Initial captcha generation on component mount
  // useEffect(() => {
  //   window.onload = () => {
  //     loadCaptchaEnginge(6);
  //   };
  // }, []);

  const [formSubmit, setFormSubmit] = useState(false);
  const FormSubmit = async (e) => {
    e.preventDefault();

    // Validate if all fields are filled
    const emptyFields = Object.entries(documentFiles).filter(
      ([fieldName, fieldValue]) => fieldValue === null
    );

    if (emptyFields.length > 0) {
      // If any field is not filled, display an alert message for each empty field
      const fieldNames = emptyFields.map(([fieldName]) => fieldName);
      const alertMessage = `Please fill out the following fields: ${fieldNames.join(
        ", "
      )}`;
      alert(alertMessage);
      return; // Stop form submission
    }

    //for payment screenshot
    if (!documentFiles.paymentProof) {
      alert("Please fill out the following fields: payment proof");
      return;
    }

    // Append email to the URL as a query parameter
    const url =
      getURLbyEndPoint("uploadDocumentRegistration") +
      `?email=${encodeURIComponent(email)}`;

    // Create a FormData object and append documentFiles
    const formData = new FormData();
    Object.keys(documentFiles).forEach((key) => {
      formData.append(key, documentFiles[key]);
    });

    try {
      setFormSubmit(true);

      const res = await axios.put(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (res.data.status) {
        alert("Form Submitted Successfully");
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        console.log(error.response.data.message);
        alert(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        console.log("Server not responding. Please try again later.");
        alert("Server not responding. Please try again later.");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error:", error.message);
        alert("An error occurred. Please try again.");
      }
      return false;
    } finally {
      setFormSubmit(false);
    }

    //changing screen content on succesful form submission
    setFormSubmissionSucessful(false);
    setEmailVerified(false);
    setDocUploadSuccess(true);

    // Continue with form submission logic here
  };

  const hand = function newFileHandler(event) {
    // console.log(event.target.files);

    if (event.target.files.length == 1) {
      const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB in bytes
      let file = event.target.files[0];

      if (acceptedFileTypes.findIndex((e) => e == file.type) < 0) {
        documentFiles[event.target.id] = null;
        alert("Please Upload PDF file");
        return;
      } else if (file.size > maxSizeInBytes) {
        documentFiles[event.target.id] = null;
        alert("Upload File size less then 5 MB");
        return;
      }

      documentFiles[event.target.id] = file;
    }
    setDocumentFiles({ ...documentFiles });
  };

  //setup for payment file handling
  // const getTipForDocument = (doc) => {
  //   const tipIndex = tips.findIndex((tip) => tip.startsWith(doc));
  //   return tipIndex !== -1 ? tips[tipIndex] : "No tip available";
  // };

  return (
    <>
      <Layout>
        <div
          className="d-flex justify-content-center text-light"
          style={{
            backgroundImage: `url(${EPlatformHeaderimg})`,
            backgroundSize: "cover",
          }}
        >
          <div
            className="main-width"
            style={{ marginBottom: "10vh", marginTop: "10vh" }}
          >
            <h1 className="font1 mb-4">Submission of Document</h1>
            <span>
              You are just one step away from completing your registration with
              AECCI. Kindly proceed by uploading all the required documents as
              specified.
            </span>
          </div>
        </div>{" "}
        <div className="d-flex justify-content-center text-light">
          <div className="main-width ">
            {formSubmissionSucessful && (
              <div>
                <h5 className="fw-bold text-dark mt-3 mb-4">
                  Thank you for choosing AECCI! You are now at the Document
                  Submission Portal. Please follow the instructions below to
                  ensure a smooth upload process.
                </h5>

                <Form className="mb-5" onSubmit={handleEmailCheck}>
                  <Row>
                    <Col>
                      <Form.Control
                        placeholder="Enter your Registered email id"
                        type="email"
                        required
                        onChange={(e) => setEmail(e.currentTarget.value)}
                      />
                    </Col>
                    <Col>
                      <Button variant="primary" type="submit">
                        Submit
                      </Button>{" "}
                    </Col>
                  </Row>
                </Form>
              </div>
            )}

            {emailVerified && (
              <Form className="d-flex gap-3" onSubmit={FormSubmit}>
                <div style={{ width: "70%" }}>
                  <h2 className=" text-black mb-4 text-center">
                    {membershipType}

                    {membershipType !== "Digital User" && " Membership"}
                  </h2>{" "}
                  <Table bordered striped style={{ textAlign: "center" }}>
                    <thead>
                      <tr>
                        <th style={{ width: "60%" }}>DOCUMENT REQUIRED</th>
                        <th style={{ width: "40%" }}>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(documentFiles)
                        .filter((doc) => doc !== "paymentProof") // Exclude paymentProof
                        .map((doc) => (
                          <tr key={doc}>
                            <td className="fw-bold text-secondary align-middle text-start">
                              <div className="d-flex justify-content-between align-items-center">
                                <div>
                                  <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                      <Tooltip>
                                        {tips[doc]
                                          ? tips[doc]
                                          : "No tip available"}
                                      </Tooltip>
                                    }
                                  >
                                    <img
                                      src={InfoImg}
                                      style={{ height: "48px" }}
                                      alt=""
                                      srcSet=""
                                    />
                                  </OverlayTrigger>
                                  *{doc}
                                </div>
                                {pdfLinks[doc] ? (
                                  <BiSolidFilePdf
                                    className={
                                      pdfLinks[doc]
                                        ? "icon-size text-danger clickable cursor-pointer"
                                        : "hidden"
                                    }
                                    onClick={() =>
                                      pdfLinks[doc] &&
                                      window.open(pdfLinks[doc], "_blank")
                                    }
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </td>

                            <td className="align-middle">
                              <div className="d-flex justify-content-center align-items-center">
                                <div className="me-3">
                                  <label
                                    className={
                                      documentFiles[doc]
                                        ? "btn btn-success"
                                        : "btn btn-primary"
                                    }
                                    htmlFor={doc}
                                  >
                                    {documentFiles[doc]
                                      ? "Uploaded"
                                      : "Upload File"}
                                  </label>
                                  <input
                                    style={{ display: "none" }}
                                    type="file"
                                    accept=".pdf"
                                    name={doc}
                                    id={doc}
                                    onChange={hand}
                                  />
                                </div>
                                {documentFiles[doc] && (
                                  <a
                                    href={URL.createObjectURL(
                                      documentFiles[doc]
                                    )}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <BsEye className="icon-size" />
                                  </a>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={2} className="text-center">
                          <h4>Payment Process</h4>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <div className="w-100 align-baseline text-start ms-5">
                            <h6 className="fw-bold text-secondary">
                              Payment Structure-
                            </h6>
                            {membershipType === "Digital User" ? (
                              <span className="d-block w-75 text-info">
                                Your Non-member access will be available for 3
                                months only.
                              </span>
                            ) : (
                              <span className="d-block w-75 text-info">
                                *Membership to the Chamber is valid for 12
                                months of a financial year.
                              </span>
                            )}

                            <div className="d-flex w-75 justify-content-between mt-3">
                              <div className="d-flex justify-content-between w-100">
                                <h6 className="fw-bold text-secondary">
                                  →Joining Fee
                                </h6>
                                <h6 className="fw-bold text-secondary">
                                  =INR {joiningFees}/-
                                </h6>{" "}
                              </div>
                            </div>

                            <div className="d-flex w-75 justify-content-between">
                              <div className="d-flex justify-content-between w-100">
                                <h6 className="fw-bold text-secondary">
                                  →Annual Fee
                                </h6>
                                <h6 className="fw-bold text-secondary">
                                  =INR {annualFees}/-
                                </h6>{" "}
                              </div>
                            </div>
                            <div className="d-flex w-75 justify-content-between mt-3">
                              <div className="d-flex justify-content-between w-100">
                                <h6 className="fw-bold text-secondary">
                                  →Total Fee
                                </h6>
                                <h6 className="fw-bold text-secondary">
                                  =INR{" "}
                                  {annualFees
                                    ? `${
                                        parseInt(annualFees) +
                                        parseInt(joiningFees)
                                      }/-`
                                    : "N/A"}
                                </h6>{" "}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="align-middle">
                          {" "}
                          <div>
                            <Button
                              variant={
                                documentFiles.paymentProof ? "success" : "info"
                              }
                              type="button"
                              onClick={() => handleClick("paymentProof")}
                            >
                              PAYMENT SCREENSHOT
                            </Button>
                            <Form.Control
                              type="file"
                              name="paymentProof"
                              accept="image/*"
                              onChange={(e) => handleChange(e, "paymentProof")}
                              ref={hiddenFileInputs["paymentProof"]}
                              style={{ display: "none" }}
                            />
                          </div>
                          {documentFiles.paymentProof && (
                            <a
                              href={URL.createObjectURL(
                                documentFiles.paymentProof
                              )}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <BsEye className="icon-size" />
                            </a>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div className="d-flex flex-column align-items-start">
                            <div className="d-flex align-items-center">
                              <h5 style={{ borderBottom: "2px solid #000" }}>
                                BANK DETAILS
                              </h5>

                              <div>
                                <OverlayTrigger
                                  placement="right"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={
                                    <Tooltip>
                                      Payment of Membership must be done from
                                      the Company Registered Bank Account Only.
                                    </Tooltip>
                                  }
                                >
                                  <img
                                    src={InfoImg}
                                    style={{ height: "48px" }}
                                    alt=""
                                    srcset=""
                                  />
                                </OverlayTrigger>
                              </div>
                            </div>
                            <span>
                              Account Name : Asian Exporters Chamber of Comm and
                              Ind
                            </span>
                            <span>Bank Name: YES Bank</span>
                            <span>
                              A/C No: 02168 87000 00780 ( Current Account)
                            </span>
                            <span>Branch Name: CBD Belapur</span>
                            <span>IFSC Code: YESB0000216</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div className="d-flex gap-5 justify-content-center">
                            <Button
                              variant="success"
                              type="submit"
                              disabled={formSubmit}
                            >
                              {formSubmit ? (
                                <>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ marginRight: "5px" }}
                                  />
                                  PROCESSING...
                                </>
                              ) : (
                                "SUBMIT"
                              )}
                            </Button>{" "}
                          </div>
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </div>
                <div style={{ width: "30%" }}>
                  <Card style={{ width: "w-100" }}>
                    <Card.Body>
                      <Card.Title className="mb-4">INSTRUCTIONS</Card.Title>
                      <Card.Text>
                        <ol>
                          <li className="mb-2">
                            Ensure each document is in PDF format and does not
                            exceed 5MB.
                          </li>
                          <li className="mb-2">
                            Avoid scanning in grayscale; documents should be in
                            color.
                          </li>
                          <li className="mb-2">
                            Scan in portrait orientation, not landscape.
                          </li>
                          <li className="mb-2">
                            Ensure documents are clearly scanned and visibly
                            legible.
                          </li>
                          <li className="mb-2">
                            Do not set any security restrictions on the uploaded
                            documents.
                          </li>
                          <li className="mb-2">
                            Document approval will be completed within 48
                            working hours.
                          </li>
                          <li className="mb-2">
                            Refer to the provided column for the required format
                            of the membership form, cover letter, and indemnity.
                            .
                          </li>
                          <li className="mb-2">
                            All uploaded documents must be self-attested by the
                            company
                          </li>
                          <li>
                            Review the provided guidelines attentively prior to
                            proceeding with the document upload.
                          </li>
                          <li>
                            Membership payments are to be initiated exclusively
                            from the bank registered under your company, as
                            specified during the sign-up process.
                          </li>
                          <li>
                            Upon successful submission of documents, kindly
                            dispatch the physical copies to AECCI&#39;s
                            registered address.
                          </li>
                        </ol>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </Form>
            )}

            {docUploadSuccess && (
              <Card
                style={{ width: "100%", height: "15rem" }}
                className="d-flex flex-column justify-content-center align-items-center mt-3"
              >
                <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                  <h1 style={{ fontSize: "4rem" }}>THANK YOU!!</h1>
                  <Card.Title className="fs-2 text-success">
                    Your documents have been successfully submitted..!!
                  </Card.Title>
                  <Card.Text>
                    Our team will conduct a review and respond within 48 hours.
                  </Card.Text>
                </Card.Body>
              </Card>
            )}
            <hr style={{ borderTop: "2px solid #000" }} />
            <div className="text-center">
              <h3 className="text-center text-success mt-3">
                How we can help?
              </h3>
              <p className="text-dark">
                A Trusted and Recognized Trade Organization Supporting
                Buissnesses Across Every Industry and Sector, All Consolidated
                within a Unified Platform.
              </p>
              <Button variant="success" className="mb-4">
                FIND OUT MORE ABOUT OUR SERVICES
              </Button>{" "}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default RegisterDocumentUpload;

function getFormFields(name) {
  const membershipWithType = [
    {
      name: "Small Business (Associate)",
      options: [
        "Membership Form",
        "Covering Letter",
        "Indemnity Bond",
        "GST Certificate + PAN Copy",
        "IEC Code/ CHA License Copy",
        "Certificate of Registration",
        "2 Passport Size Photo",
      ],
      joiningFees: "2000",
      annualFees: "6000",
    },
    {
      name: "Start-Up",
      options: [
        "Membership Form",
        "Covering Letter",
        "Indemnity Bond",
        "GST Certificate + PAN Copy",
        "IEC Code/ CHA License Copy",
        "Certificate of Registration",
        "2 Passport Size Photo",
      ],
      joiningFees: "2000",
      annualFees: "6000",
    },
    {
      name: "Corporate",
      options: [
        "Membership Form",
        "Covering Letter",
        "Indemnity Bond",
        "GST Certificate + PAN Copy",
        "IEC Code/ CHA License Copy",
        "Certificate of Registration",
        "Certificate of Incorporation/AOA/MOA",
        "2 Passport Size Photo",
      ],
      joiningFees: "2000",
      annualFees: "10000",
    },
    {
      name: "Corporate +",
      options: [
        "Membership Form",
        "Covering Letter",
        "Indemnity Bond",
        "GST Certificate + PAN Copy",
        "IEC Code/ CHA License Copy",
        "Certificate of Registration",
        "Certificate of Incorporation/AOA/MOA",
        "2 Passport Size Photo",
      ],
      joiningFees: "2000",
      annualFees: "20000",
    },
    // {
    //   name: "Overseas",
    //   options: [
    //     "Membership Form",
    //     "Covering Letter",
    //     "Indemnity Bond",
    //     "2 Passport Size Photo",
    //     "Self Attested Copy",
    //     "IEC Certificate",
    //     "Certificate of Registration",
    //     "Passport Copies of Authorized Signatures Must",
    //   ],
    //   annualFees: "6000",
    // },
    {
      name: "Non-Profit Organization",
      options: [
        "Membership Form",
        "Covering Letter",
        "Indemnity Bond",
        "Certificate of Registration",
        "Certificate of Incorporation",
        "2 Passport Size Photo",
      ],
      joiningFees: "2000",
      annualFees: "5500",
    },
    {
      name: "Digital User",
      options: [
        "Non-Member Form",
        "Covering Letter",
        "Indemnity Bond",
        "KYC",
        "GST Certificate + PAN Copy",
        "IEC Code/ CHA License Copy",
        "2 Passport Size Photo",
      ],
      joiningFees: "1000",
      annualFees: "0",
    },
  ];

  const allTips = {
    "Membership Form":
      "Ensure completeness by filling in, signing, and stamping the Membership Application Form",
    "Covering Letter":
      "Cover Letter (Format enclosed), to be taken on your company letterhead",
    "Indemnity Bond":
      "Print the enclosed Indemnity Bond (refer to provided format) on Rs.200/- bond paper, sign, and notarize as required",
    "GST Certificate + PAN Copy":
      "Submit a copy of your company's GST or PAN as displayed in the preview",
    "IEC Code/ CHA License Copy":
      "If Exporter: Upload IEC Copy; If CHA, Freight Forwarders, Shipping and Logistics, Clearing Agents: Submit License Copy or Gumasta",
    "Certificate of Registration":
      "Upload relevant registration certificates such as RCMC, Gumasta, MSME, or any other registration document here",
    "Certificate of Incorporation/AOA/MOA":
      "Any one of the following documents from 3 can be submitted",
    "2 Passport Size Photo":
      "Upload the photo of the company's stakeholder, such as director, proprietor, Partners, owner, or founders, here",
    "Non-Member Form":
      "Ensure completeness by filling in, signing, and affixing rubber stamp on the Application Form",
    KYC: "KYC (format enclosed), to be taken on your company letter head",
  };

  const coveringLetter = {
    "Start-Up":
      "https://aecci-bucket-new.s3.amazonaws.com/Registration+documents/TemplateRegistrationDocs/start-up-cover.pdf",
    "Small Business (Associate)":
      "https://aecci-bucket-new.s3.amazonaws.com/Registration+documents/TemplateRegistrationDocs/small-business-membership.pdf",
    Corporate:
      "https://aecci-bucket-new.s3.amazonaws.com/Registration+documents/TemplateRegistrationDocs/corparate-cover.pdf",
    "Corporate +":
      "https://aecci-bucket-new.s3.amazonaws.com/Registration+documents/TemplateRegistrationDocs/Corporate-%2B-cover.pdf",
    "Digital User":
      "https://aecci-bucket-new.s3.amazonaws.com/Registration+documents/TemplateRegistrationDocs/Non+Member+CoverLetter.pdf",
  };

  const pdfWithLink = {
    "Membership Form":
      "https://aecci-bucket-new.s3.amazonaws.com/Registration+documents/TemplateRegistrationDocs/MEMBERSHIP-FORM.pdf",
    "Covering Letter": "",
    "Indemnity Bond":
      "https://aecci-bucket-new.s3.amazonaws.com/Registration+documents/TemplateRegistrationDocs/FORMAT-INDEMNITY-BOND.pdf",
    "Non-Member Form":
      "https://aecci-bucket-new.s3.amazonaws.com/Registration+documents/TemplateRegistrationDocs/NOn+member.pdf",
    KYC: "https://aecci-bucket-new.s3.amazonaws.com/Registration+documents/TemplateRegistrationDocs/Non+Member+KYC+Format.pdf",
  };

  const paymentStructure = {};

  // return Object.fromEntries(
  //   membershipWithType
  //     .find((ty) => ty.name == name)
  //     .options.map((e) => [e, null])
  // );
  const selectedMembershipType = membershipWithType.find(
    (ty) => ty.name === name
  );

  if (!selectedMembershipType) {
    // Handle the case where the membership type is not found
    return {};
  }

  pdfWithLink["Covering Letter"] =
    pdfWithLink[name] || coveringLetter[name] || "";

  const formFields = {
    values: Object.fromEntries(
      selectedMembershipType.options.map((e) => [e, null])
    ),
    tips: Object.fromEntries(
      selectedMembershipType.options.map((e) => [e, allTips[e]])
    ),
    pdfValues: Object.fromEntries(
      selectedMembershipType.options
        .filter((e) => pdfWithLink[e]) // Filter only those with links
        .map((e) => [e, pdfWithLink[e]])
    ),
    annualFees: selectedMembershipType.annualFees,
    joiningFees: selectedMembershipType.joiningFees,

    coveringLetterLink: pdfWithLink["Covering Letter"] || null,
  };

  return formFields;
}
