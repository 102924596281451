import React, { useState, useEffect } from "react";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import toast from "react-hot-toast";
import Layout2 from "../../../components/Layouts/Layouts/Layout2";
import Layout from "../../../components/Layouts/Layouts/Layout";
import Nav from "react-bootstrap/Nav";
import B2bConnectHeader from "../../../assets/headerImgs/b2bConnectHeader.png";
import QuickLinks from "../../../components/basic/QuickLinks";
import html2pdf from "html2pdf.js";
import { collaboration_countrys } from "../../../pages/auth/Register/AllRegiPageLists";
import infoImg from "../../../assets/infoimg.png";
import InfoImg from "../../../assets/infoimg.png";
import { BiSolidFilePdf } from "react-icons/bi";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import { AiFillCheckCircle } from "react-icons/ai";
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Image,
  OverlayTrigger,
  Pagination,
  Row,
  Table,
  Tooltip,
  Modal,
} from "react-bootstrap";
import { getURLbyEndPointV2 } from "../../../store/api";
import { getAuthUserlocal } from "../../../store/services";
import {
  CollaborationPopUpModalButton,
  PopupModalText,
} from "../../../components/basic/ModalComponent";
import { FaInfoCircle, FaQuestionCircle } from "react-icons/fa";
import axios from "axios";
import { industries } from "../../../store/AllListData";
import { BsEye } from "react-icons/bs";

const getCountryCollaborationComponent = (country) => {
  switch (country) {
    case "Brazil":
      return "https://www.aecci.org.in/wp-content/uploads/2023/11/ABDO-ADVAGADOS-2.png";
    case "Cambodia":
      return "https://www.aecci.org.in/wp-content/uploads/2023/11/BNG-LEgal-4.png";
    case "Denmark":
      return "https://www.aecci.org.in/wp-content/uploads/2023/11/Fabritius-Tengnagel-Heine.png";
    case "Egypt":
      return "https://www.aecci.org.in/wp-content/uploads/2023/09/sadany-khalifa-4.png";
    case "Mexico":
      return "https://www.aecci.org.in/wp-content/uploads/2023/11/New-Markets-3.png";
    case "Tanzania":
      return "https://www.aecci.org.in/wp-content/uploads/2023/11/Victory-Attorneys-Consultants.png";
    case "Bahamas":
      return "https://www.aecci.org.in/wp-content/uploads/2024/01/PARRISWHITTAKER.png";
    case "Bosnia":
      return "https://www.aecci.org.in/wp-content/uploads/2024/03/1-1.png";
    case "Bangladesh":
      return "https://www.aecci.org.in/wp-content/uploads/2024/03/2-2.png";
    case "Algeria":
      return "https://www.aecci.org.in/wp-content/uploads/2024/03/3.png";
    case "Nigeria":
      return "https://www.aecci.org.in/wp-content/uploads/2024/04/VAZI-LEGAL.png";
    case "Vietnam":
      return "https://www.aecci.org.in/wp-content/uploads/2024/04/HMP-LAW-FIRM.png";
    case "United Kingdom":
      return "https://www.aecci.org.in/wp-content/uploads/2024/06/Go-Exporting-2.png";
    case "China":
      return "https://www.aecci.org.in/wp-content/uploads/2024/06/QINGDAO-DAYING-LEGAL-SERVICES-CO.-LTD-1.png";
    case "Pakistan":
      return "https://www.aecci.org.in/wp-content/uploads/2024/06/International-Collbrations-Tamp-6.png";
    case "Oman":
      return "https://www.aecci.org.in/wp-content/uploads/2024/06/International-Collbrations-Tamp-2.png";
    case "Turkey":
      return "https://www.aecci.org.in/wp-content/uploads/2024/06/For-social-Media-NMR.png";
    case "Greece":
      return "https://www.aecci.org.in/wp-content/uploads/2024/06/International-Collbrations-Tamp.png";
    case "Thailand":
      return "https://www.aecci.org.in/wp-content/uploads/2024/07/International-Collbrations-Tamp-23.png";
    case "Ethiopia":
      return "https://www.aecci.org.in/wp-content/uploads/2024/07/International-Collbrations-Tamp-22.png";
    case "UAE":
      return "https://www.aecci.org.in/wp-content/uploads/2024/08/UAE-collaboration-1.png";
    case "Sri Lanka":
      return "http://www.aecci.org.in/wp-content/uploads/2024/08/Sri-lanka-collaboration.png";
    default:
      return "#";
  }
};

const B2bConnect = () => {
  const [selectedTab, setSelectedTab] = useState("about");
  const [activePage, setActivePage] = useState("b2bConnect");

  const handleTabSelect = (tabKey) => {
    setSelectedTab(tabKey);
  };

  return (
    <Layout>
      <Layout2>
        <DashboardHeader />
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="mt-4 mb-3">MEGA EVENT B2B CONNECT 2024</h4>
          <div>
            {activePage === "b2bRequestTrack" ? (
              <Button
                size="sm"
                variant="secondary"
                className="p-2 m-2"
                onClick={() => setActivePage("b2bConnect")}
              >
                Back
              </Button>
            ) : getAuthUserlocal().selectMembership === "Collaboration" ? (
              <Button
                size="sm"
                // onClick={() => setActivePage("bookRegistration")}
              >
                Track Your Request
              </Button>
            ) : (
              <Button
                size="sm"
                className="p-2 m-2"
                onClick={() => setActivePage("b2bRequestTrack")}
              >
                Track Your Event
              </Button>
            )}
          </div>
        </div>
        <hr className="mb-2" />

        {activePage === "b2bConnect" ? (
          <>
            <Nav
              variant="tabs"
              activeKey={selectedTab}
              onSelect={handleTabSelect}
              className="d-flex text-center flex-nowrap"
            >
              <Nav.Item>
                <Nav.Link eventKey="about" className="fw-bolder">
                  About AECCI B2B Connect
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="presence" className="fw-bolder">
                  AECCI B2B Presence
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="livesession" className="fw-bolder">
                  Mega Event on Cards
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="initiatives" className="fw-bolder">
                  B2B Connect Initiatives
                </Nav.Link>
              </Nav.Item>
              {getAuthUserlocal().selectMembership === "Collaboration" && (
                <>
                  <Nav.Item>
                    <Nav.Link eventKey="protocol" className="fw-bolder">
                      Visiting Protocol
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="faq" className="fw-bolder">
                      Frequently Asked Questions
                    </Nav.Link>
                  </Nav.Item>
                </>
              )}
            </Nav>

            <Nav
              variant="tabs"
              activeKey={selectedTab}
              onSelect={handleTabSelect}
              className="d-flex text-center justify-content-center align-item-center flex-nowrap mt-2"
            >
              <Nav.Item>
                <Nav.Link eventKey="registration" className="fw-bolder">
                  B2B Event Registration
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <div className="tab-content mt-4">
              {selectedTab === "about" && (
                <AboutB2b setActivePage={setActivePage} />
              )}
              {selectedTab === "presence" && (
                <B2bPresence setActivePage={setActivePage} />
              )}
              {selectedTab === "livesession" && (
                <B2bLiveSession setActivePage={setActivePage} />
              )}
              {selectedTab === "initiatives" && (
                <B2bInitiatives setActivePage={setActivePage} />
              )}
              {selectedTab === "registration" && (
                <B2bDelegations setActivePage={setActivePage} />
              )}
              {selectedTab === "protocol" && (
                <VisitingProtocol
                  setActivePage={setActivePage}
                  setSelectedTab={setSelectedTab}
                />
              )}
              {selectedTab === "faq" && <FAQ setActivePage={setActivePage} />}
            </div>
          </>
        ) : activePage === "b2bRequestForm" ? (
          <B2bRequestForm setActivePage={setActivePage} />
        ) : activePage === "b2bRequestTrack" ? (
          <>
            <B2bEventTrack />
            <div className="mt-2 mx-2">
              <h6 className="fs-bold mt-3">
                AECCI B2B Connect Terms and Conditions of Participation:
              </h6>
              <div className="my-3">
                <div className="mb-2">
                  <div>
                    <span className="fw-bold">Booking: </span>
                    <span>
                      At the time of booking, please carefully read the number
                      of countries you want to register for as the fees are
                      applicable according to the number of countries chosen.
                    </span>
                  </div>
                </div>

                <div className="mb-2">
                  <div>
                    <span className="fw-bold">Payment: </span>
                    <span>
                      Fees for the booking confirmation will be deducted from
                      your wallet on the AECCI e-Platform. Please ensure to
                      maintain the minimum amount according to the number of
                      chosen countries.
                    </span>
                  </div>
                </div>

                <div className="mb-2">
                  <div>
                    <span className="fw-bold">Booking Confirmation: </span>
                    <span>
                      Once your booking is confirmed, you will receive a
                      confirmation email with all slot allotment details. Please
                      review this information carefully.
                    </span>
                  </div>
                </div>

                <div className="mb-2">
                  <div>
                    <span className="fw-bold">Non-Transferable: </span>
                    <span>
                      All bookings are non-transferable. The registration is
                      valid only for the individual participant or entity that
                      made the booking.
                    </span>
                  </div>
                </div>

                <div className="mb-2">
                  <div>
                    <span className="fw-bold">
                      Single Business Entitlement:{" "}
                    </span>
                    <span>
                      Each registration is valid for one specific business
                      entity. The registration does not extend to sister
                      concerns, group companies, or any other affiliated
                      entities. Discussions and access are limited to the
                      registered business only.
                    </span>
                  </div>
                </div>

                <div className="mb-2">
                  <div>
                    <span className="fw-bold">Slots-Allotment: </span>
                    <span>
                      Each business is suggested to adhere to the slots timings.
                      Delayed entries will be put on hold and given a chance at
                      the completion of the reserved slots.
                    </span>
                  </div>
                </div>

                <div className="fw-semibold mb-2 text-center">
                  By proceeding with your booking, you acknowledge and agree to
                  the above-given terms and conditions.
                </div>
              </div>
            </div>
          </>
        ) : activePage === "submitMessage" ? (
          <SubmitMessage setActivePage={setActivePage} />
        ) : activePage === "bookRegistration" ? (
          <BookRegistrationForm />
        ) : null}
        <QuickLinks />
      </Layout2>
    </Layout>
  );
};

const AboutB2b = ({ setActivePage }) => {
  return (
    <div className=" border">
      <img
        src={B2bConnectHeader}
        alt="b2b connect 2024"
        className="img-fluid w-100"
      />
      <hr className="m-0 p-0" />
      <div className="text-center">
        <div className="text-dark fw-bold fs-4 mt-3">
          Mega Event - AECCI B2B Connect 2024
        </div>
      </div>
      <div>
        <div className="fs-6 text-dark p-3 text-justify">
          AECCI understands the complications faced by a business in achieving
          its objectives. Most common challenge that businesses face is the
          management of time and cost as every big and small effort towards
          growth costs you spending huge on time, efforts and money Hence, AECCI
          B2B connect ensures to provide you the important services of business
          growth, under one roof and that too at your doorstep.
        </div>
        <div className="fs-6 text-dark p-3 text-justify">
          <strong className="text-primary">AECCI B2B Connect</strong> with
          expertise of our collaborative foreign partners at various governments
          and private institutions, spares you from spending hugely on
          air-fares, stall bookings at expos, taking permissions to fly abroad,
          long fights for trade claims settlements and so on. As part of our
          commitment to promote trade and investment, we have created B2B
          Connect, a platform that not only facilitates business-to-business
          connections and networking but also help you overcoming other business
          challenges.
        </div>
      </div>
      <div className="my-2">
        <Row className="justify-content-center">
          <Col xs="auto">
            {getAuthUserlocal().selectMembership === "Collaboration" ? (
              <Button
                size="sm"
                onClick={() => setActivePage("bookRegistration")}
              >
                Book Your Registration
              </Button>
            ) : (
              <Button size="sm" onClick={() => setActivePage("b2bRequestForm")}>
                SUBMIT YOUR REQUEST
              </Button>
            )}
          </Col>
          {/* <Col xs="auto">
        <Button size="sm">OUR COLLABORATIONS</Button>
      </Col> */}
        </Row>
      </div>
    </div>
  );
};

const B2bPresence = ({ setActivePage }) => {
  return (
    <div className="border">
      <div className="text-center mb-4">
        <div className="text-dark fw-bold fs-4 mt-3">AECCI Presence​</div>
        <div className="fs-6 text-dark p-3 text-justify">
          AECCI proves its dynamism by being the only chamber having 25
          collaborations at a moment. In other words we are present in 25
          countries to go hand-in-hand with you and we’ll ensure that you
          experience the smooth business.
        </div>
      </div>
      <CollaborationCountryList />
      <div className="text-center mt-2">
        <div className="fs-6 text-bold my-2">
          Exclusive collaborations are signed-up with all the listed countries
          and firms, any attempt to connect directly will be refused and
          reported to AECCI management.
        </div>
        <div className="text-sm fw-italic text-muted my-2">
          Any individual or company knowingly copying the content or the
          information presented here, will be liable for legal action.
        </div>
      </div>
      <div className="my-2">
        <Row className="justify-content-center">
          <Col xs="auto">
            {getAuthUserlocal().selectMembership === "Collaboration" ? (
              <Button
                size="sm"
                onClick={() => setActivePage("bookRegistration")}
              >
                Book Your Registration
              </Button>
            ) : (
              <Button size="sm" onClick={() => setActivePage("b2bRequestForm")}>
                SUBMIT YOUR REQUEST
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

const B2bLiveSession = ({ setActivePage }) => {
  return (
    <div className=" border">
      <div className="text-center">
        <div className="text-dark fw-bold fs-4 mt-3">Mega Event on Cards</div>
      </div>
      <div>
        <div className="p-2">
          <div className="fs-6 text-dark p-3 text-justify">
            <strong>AECCI B2B Connect</strong> is a distinct platform that
            enables Indian exporters, industrialists, organizations, and
            entrepreneurs to access new markets, form business partnerships, and
            connect with potential investors—all while you are sitting in your
            second home, i.e., AECCI.
          </div>
          <div className="fs-6 text-dark p-3 text-justify">
            Here is the Mega big event on cards,{" "}
            <strong>‘AECCI B2B Connect 2024’</strong> wherein our collaborative
            expert partners from various destinations will come handy with
            answers to all your business needs. The event will provide ample
            opportunities for consultation, business matches, and market
            studies.
          </div>
          <div className="fs-6 text-dark p-3 text-justify">
            Sparing you from spending hugely on foreign business tours, the
            event will bring our collaborative expert partners from 25 countries
            under one roof here in India.{" "}
            <strong>‘AECCI B2B Connect 2024’</strong> is a round table
            conference being organized this October wherein you will have direct
            access to our collaborative partners' expertise. We insist you
            select your preferred countries according to your need, and we
            ensure that all your concerns will be addressed.
          </div>
          <div className="fs-6 text-dark p-3 text-justify">
            Collaborations under AECCI’s B2B Connect with expert individuals and
            firms make it all smooth for you to plan and execute your expansion
            plan.
          </div>
        </div>
      </div>
      <div className="my-2">
        <Row className="justify-content-center">
          <Col xs="auto">
            {getAuthUserlocal().selectMembership === "Collaboration" ? (
              <Button
                size="sm"
                onClick={() => setActivePage("bookRegistration")}
              >
                Book Your Registration
              </Button>
            ) : (
              <Button size="sm" onClick={() => setActivePage("b2bRequestForm")}>
                SUBMIT YOUR REQUEST
              </Button>
            )}
          </Col>
          {/* <Col xs="auto">
        <Button size="sm">OUR COLLABORATIONS</Button>
      </Col> */}
        </Row>
      </div>
    </div>
  );
};

const B2bInitiatives = ({ setActivePage }) => {
  return (
    <div className=" border">
      <div className="text-center">
        <div className="text-dark fw-bold fs-4 mt-3">Our B2B Initiatives</div>
      </div>

      <div>
        <div className="fs-6 text-dark p-3 text-justify">
          We will ensure to walk hand-in-hand with you until your business
          objective is fulfilled. Our B2B partners, with their expertise as
          established practitioners and professionals in their respective
          countries, will assist you with any small or big business hurdle. They
          are capable of:
        </div>
        <div className="fs-6 text-dark p-3 text-justify">
          <ul>
            <li>Arranging meetings with clients.</li>
            <li>
              Arranging meetings with diplomats or government authorities.
            </li>
            <li>Assisting with your initial registration process.</li>
            <li>Guiding you on the right investment opportunities.</li>
            <li>Making you familiar with the local beliefs and practices.</li>
            <li>Guiding you on safe business terms with overseas companies.</li>
            <li>
              Conducting customer/supplier credibility checks “for & by” either
              side.
            </li>
            <li>Handling any trade disputes that may arise.</li>
            <li>Getting you meet with the trusted business agents.</li>

            <li>Resolving your International Trade Disputes.</li>
          </ul>
        </div>
      </div>
      <div className="my-2">
        <Row className="justify-content-center">
          <Col xs="auto">
            {getAuthUserlocal().selectMembership === "Collaboration" ? (
              <Button
                size="sm"
                onClick={() => setActivePage("bookRegistration")}
              >
                Book Your Registration
              </Button>
            ) : (
              <Button size="sm" onClick={() => setActivePage("b2bRequestForm")}>
                SUBMIT YOUR REQUEST
              </Button>
            )}
          </Col>
          {/* <Col xs="auto">
        <Button size="sm">OUR COLLABORATIONS</Button>
      </Col> */}
        </Row>
      </div>
    </div>
  );
};

const B2bDelegations = ({ setActivePage }) => {
  const [formData, setFormData] = useState({
    entity: "",
    companyName: "",
    profession: "",
    name: "",
    email: "",
    phoneNo: "",
    contactTimeFrom: "",
    contactTimeTo: "",
    selectedCountries: [],
    cinNumber: "",
    userMessage: "",
  });
  const [isCaptchaCorrect, setIsCaptchaCorrect] = useState(false);
  const [captchaValue, setCaptchaValue] = useState("");
  const [formSuccessful, setFormSuccessful] = useState(false);
  const [registerFormSuccess, setRegisterFormSuccess] = useState(false);
  const [dropdownCount, setDropdownCount] = useState(1);

  // Sample profession data
  const Profession = [
    "Freelancer",
    "Consultant",
    "Contractor",
    "Self-Employed",
  ];
  const collaboration_countrys = [
    "India",
    "USA",
    "China",
    "Germany",
    "France",
    "Japan",
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCountryChange = (index, e) => {
    const newCountries = [...formData.selectedCountries];
    newCountries[index] = e.target.value;
    setFormData({ ...formData, selectedCountries: newCountries });
  };

  const addDropdown = () => {
    setDropdownCount(dropdownCount + 1);
  };

  const handleInputChangeCin = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCaptchaChange = (e) => {
    setCaptchaValue(e.target.value);
  };

  const handleRegiFormSubmit = (e) => {
    e.preventDefault();
    // Form submission logic here
  };

  const resetForm = () => {
    setFormData({
      entity: "",
      companyName: "",
      profession: "",
      name: "",
      email: "",
      phoneNo: "",
      contactTimeFrom: "",
      contactTimeTo: "",
      selectedCountries: [],
      cinNumber: "",
      userMessage: "",
    });
    setCaptchaValue("");
    setIsCaptchaCorrect(false);
    setRegisterFormSuccess(false);
  };
  return (
    <div className=" border">
      <div className="text-center mb-4">
        <div className="text-dark fw-bold fs-4 mt-3">B2B Delegations</div>
      </div>
      <div>
        <div>
          <div className="fs-5 fw-bold text-dark px-3 text-justify">
            Scope of ‘AECCI B2B Connect 2024’
          </div>
          <div className="fs-6 text-dark p-3 text-justify">
            Dear Patron, ‘AECCI B2B Connect 2024’ event is being organized for
            you all, hence we find it important to give you an idea of the
            stages of event planning and execution. Here is the month-wise
            series:
          </div>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div className="fs-4">August</div>
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  This is the time to seek your interest. Please find the
                  request form attached; click on “Show your interest” and let
                  us know your preferences. Fill the form carefully and ensure
                  that you register for “AECCI B2B Connect 2024” before the last
                  date of registration, i.e., 31.08.2024.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <div className="fs-4">September</div>
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  The entire month will be spent coordinating with the
                  delegates. Their visit plans, VISA-facilitations, travel, and
                  stay arrangements will all be scheduled for this month. We
                  also encourage you to use September to prepare the issues you
                  want to address during AECCI B2B Connect 2024.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <div className="fs-4">October</div>
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  This is the month of the big event, i.e., AECCI B2B Connect
                  2024. During this month, you will sit across the table with
                  our collaborative expert partners from your preferred
                  countries and get satisfying solutions for your business
                  needs. Feel free to ask them anything as every possible
                  business question is welcomed. Your questions may relate to:
                </p>
                <ul>
                  <li>Procedures for entering their market</li>
                  <li>Required registrations</li>
                  <li>Business partner requirements</li>
                  <li>Market studies</li>
                  <li>Trade disputes</li>
                  <li>Any other relevant topics</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <div className="fs-6 fw-bold text-dark mt-4 text-center">
            Stay tuned with us as we keep updating you with the event progress.
          </div>
        </div>
        <div className="my-3">
          <Row className="justify-content-center">
            <Col xs="auto">
              {getAuthUserlocal().selectMembership === "Collaboration" ? (
                <Button
                  size="sm"
                  onClick={() => setActivePage("bookRegistration")}
                >
                  Book Your Registration
                </Button>
              ) : (
                <Button
                  size="sm"
                  onClick={() => setActivePage("b2bRequestForm")}
                >
                  SUBMIT YOUR REQUEST
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

const B2bEventTrack = ({ setActivePage }) => {
  const [b2bEventTrack, setB2bEventTrack] = useState([]);

  const fetchTrackData = async () => {
    try {
      const userId = getAuthUserlocal()._id;

      const response = await axios.get(
        getURLbyEndPointV2("getB2bEventRequest") + userId
      );
      if (response.status === 200) {
        const data = response.data;
        setB2bEventTrack(data.data);
      } else {
      }
    } catch (error) {
      console.error("Error fetching Event data:", error);
    }
  };

  useEffect(() => {
    fetchTrackData();
  }, []);

  const [searchTerm, setSearchTerm] = useState("");

  const filteredData = b2bEventTrack.filter((data) =>
    searchTerm !== ""
      ? data.eventType &&
        data.eventType.toLowerCase().includes(searchTerm.toLowerCase())
      : true
  );

  //pagination is here
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Set items per page to 5

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFiltered = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Calculate the range of pages to display
  const calculateRange = (currentPage, totalPages) => {
    let startPage = Math.max(1, currentPage - 2); // Adjusted to show 5 pages
    let endPage = Math.min(totalPages, currentPage + 2); // Adjusted to show 5 pages

    // Adjust if the range is too close to the beginning or end
    if (endPage - startPage < 4) {
      if (startPage === 1) {
        endPage = Math.min(5, totalPages); // Adjusted to show 5 pages
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 4, 1); // Adjusted to show 5 pages
      }
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = calculateRange(currentPage, totalPages);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  return (
    <div>
      <Table responsive striped bordered hover w-auto>
        <thead>
          <tr>
            <th
              style={{
                fontSize: "14px",
                whiteSpace: "nowrap",
              }}
            >
              Sr
              <br /> No.
            </th>
            <th
              style={{
                fontSize: "14px",
                whiteSpace: "nowrap",
              }}
              className="text-center"
            >
              Perticular
            </th>
            <th
              style={{
                fontSize: "14px",
                whiteSpace: "nowrap",
              }}
              className="text-center"
            >
              Selected Country Profile
            </th>

            <th
              style={{
                fontSize: "14px",
                whiteSpace: "nowrap",
              }}
              className="text-center"
            >
              Action
            </th>
          </tr>
        </thead>
        <>
          <tbody>
            {currentFiltered.length > 0 ? (
              <>
                {currentFiltered.map((data, index) => {
                  return (
                    <tr>
                      <td>{index + 1}.</td>
                      <td>
                        <div
                          style={{ width: "480px", maxWidth: "max-content" }}
                        >
                          Company: {data.companyName}.<br />
                          {data.entity}
                          <br />
                          Name: {data.name}
                          <br />
                          Email: {data.email} <br />
                          CIN: {data.cinNumber}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{ width: "240px", maxWidth: "max-content" }}
                        >
                          {data.country.map((country) => (
                            <CollaborationPopUpModalButton
                              key={country}
                              title={country}
                              body={getCountryCollaborationComponent(country)}
                              disabled={false}
                            />
                          ))}
                        </div>
                      </td>
                      <td
                        className={`text-center ${
                          data.isApproved === "Processing"
                            ? "text-primary"
                            : data.isApproved === "Rejected"
                            ? "text-danger"
                            : data.isApproved === "Approved"
                            ? "text-success"
                            : ""
                        }`}
                      >
                        {data.isApproved}.<br />
                        <span className="text-dark text-center">
                          {/* {data.remark} */}
                        </span>
                        <Button className="mt-2" size="sm">
                          Book Slot
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </>
      </Table>
      {/* //pagination------------------------------------
            ------------------------------------------------ */}
      {totalPages > 1 && (
        <div className="d-flex justify-content-start">
          <Pagination>
            <Pagination.Prev
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            />
            {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
              <Pagination.Item
                key={index + startPage}
                active={index + startPage === currentPage}
                onClick={() => handlePageChange(index + startPage)}
              >
                {index + startPage}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </div>
      )}
    </div>
  );
};

const B2bRequestForm = ({ setActivePage }) => {
  const [registerFormSuccess, setRegisterFormSuccess] = useState(false);
  const [formSuccessful, setFormSuccessful] = useState(false);
  const [isInputValid, setIsInputValid] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [dropdownCount, setDropdownCount] = useState(1);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [captchaValue, setCaptchaValue] = useState("");
  const [isCaptchaCorrect, setIsCaptchaCorrect] = useState(false);
  const captcha_length = 6;
  const initialFormData = {
    companyName: getAuthUserlocal().companyName,
    email: getAuthUserlocal().email,
    name: "",
    entity: "Business",
    profession: "",
    country: [],
    phoneNo: "",
    contactTimeFrom: "",
    contactTimeTo: "",
    cinNumber: getAuthUserlocal().inputNumber,
    industry: "",
    reqPath: "B2BePlatform",
    userMessage: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const timeSlots = [
    "10:00AM",
    "11:00AM",
    "12:00PM",
    "01:00PM",
    "02:00PM",
    "03:00PM",
    "04:00PM",
    "05:00PM",
    "06:00PM",
  ];

  const [availableTimesTo, setAvailableTimesTo] = useState(timeSlots);

  const handleCountryChange = (index, event) => {
    const newSelectedCountry = event.target.value;

    if (selectedCountries.includes(newSelectedCountry)) {
      alert("Country already selected. Please choose a different country!");
      return;
    }
    const newSelectedCountries = [...selectedCountries];
    newSelectedCountries[index] = newSelectedCountry;
    setSelectedCountries(newSelectedCountries);
    setFormData({
      ...formData,
      country: newSelectedCountries,
    });
  };

  const addDropdown = () => {
    if (dropdownCount < 6) {
      setDropdownCount(dropdownCount + 1);
      setSelectedCountries([...selectedCountries, ""]);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleCaptchaChange = (evt) => {
    setCaptchaValue(evt.target.value);
    if (validateCaptcha(evt.target.value, false)) {
      setIsCaptchaCorrect(true);
      return;
    }
    setIsCaptchaCorrect(false);
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setSelectedCountries([]);
    setDropdownCount(1);
    setCaptchaValue("");
    setIsCaptchaCorrect(false);
    setIsInputValid(true);
    setRegisterFormSuccess(false);
    setFormSuccessful(false);
  };

  const handleRegiFormSubmit = async (e) => {
    e.preventDefault();
    setRegisterFormSuccess(true);
    try {
      formData.phoneNo = parseInt(formData.phoneNo);
      if (isFormValid) {
        // Proceed
      } else {
        alert("CAPTCHA is invalid");
        return false;
      }

      const validateField = (field, fieldName, regex) => {
        if (
          field === undefined ||
          (typeof field === "string" && field.trim() === "")
        ) {
          console.log("Invalid Form, field:", field);
          alert(`Invalid Form, ${fieldName} can not be empty`);
          return false;
        }
        if (regex && !regex.test(field)) {
          console.log("Invalid Form, field:", field);
          alert(`Invalid Form, ${fieldName} is not in the correct format`);
          return false;
        }

        return true;
      };

      const validateNonZeroNumber = (value, fieldName) => {
        if (parseInt(value) === 0) {
          console.log(`Invalid Form, ${fieldName} cannot be 0`);
          alert(`Invalid Form, ${fieldName} cannot be 0`);
          return false;
        }
        return true;
      };
      // Validate common fields
      if (!validateField(formData.name, "Name")) return;
      if (!validateField(formData.email, "Email")) return;
      if (!validateField(formData.entity, "Entity")) return;
      if (!validateField(formData.reqPath, "Req path")) return;
      if (
        !validateField(
          formData.phoneNo.toString(),
          "Phone No.",
          /^(?:\d{10,15})$/
        )
      )
        return;
      if (!validateNonZeroNumber(formData.phoneNo, "Phone No.")) return;

      // Additional validation for Business entity
      if (formData.entity === "Business") {
        if (!validateField(formData.companyName, "Company Name")) return;
        if (!validateField(formData.cinNumber, "IEC CODE / CIN NO")) return;
      }

      const res = await axios.post(
        getURLbyEndPointV2("createB2BEventRequest"),
        {
          ...formData,
        }
      );

      if (res && res.data && res.data.status) {
        toast.success(res.data.message);
        setFormSuccessful(true);
        setRegisterFormSuccess(false);
        setFormData(initialFormData);
        setActivePage("submitMessage");
      } else {
        console.log("Response data:", res);
        toast.error(res?.data?.message || "An error occurred");
      }
    } catch (e) {
      console.log("Error caught:", e);
      toast.error(e?.response?.data?.message || "An error occurred");
    } finally {
      setRegisterFormSuccess(false);
    }
  };

  useEffect(() => {
    const filterTimes = () => {
      const selectedFromIndex = timeSlots.indexOf(formData.contactTimeFrom);
      if (selectedFromIndex === -1) {
        setAvailableTimesTo(timeSlots);
      } else {
        const newAvailableTimes = timeSlots.slice(selectedFromIndex + 1);
        setAvailableTimesTo(newAvailableTimes);
      }
    };

    filterTimes();
  }, [formData.contactTimeFrom]);

  useEffect(() => {
    const isValid = isCaptchaCorrect;
    setIsFormValid(isValid);
  }, [isCaptchaCorrect]);

  useEffect(() => {
    loadCaptchaEnginge(captcha_length);
  }, []);
  return (
    <>
      <div className="mx-auto">
        <div
          className="montserratFont"
          style={{
            background: "#E8F0FE",
            paddingBlock: "30px",
            paddingInline: "50px",
          }}
        >
          <form onSubmit={handleRegiFormSubmit}>
            <p className="mt-4" style={{ fontSize: "0.9em" }}>
              * indicates required fields
            </p>

            <Row>
              <Col>
                <div className="my-4">
                  <label className="fw-bold" style={{ fontSize: "0.9rem" }}>
                    Company Name *
                  </label>
                  <div className="d-flex flex-column">
                    <input
                      style={{ width: "auto", height: "auto" }}
                      className="form-control rounded-0"
                      onChange={handleInputChange}
                      value={formData.companyName}
                      name="companyName"
                      type="text"
                      disabled
                      maxLength={50}
                      required
                    />
                  </div>
                </div>
              </Col>
              <Col>
                <div className="my-4">
                  <label className="fw-bold" style={{ fontSize: "0.9rem" }}>
                    Select Your Industry *
                  </label>
                  <div className="d-flex flex-column">
                    <select
                      className="form-control rounded-0"
                      required
                      value={formData.industry}
                      onChange={handleInputChange}
                      name="industry"
                    >
                      <option value="">Select Industry...</option>
                      {industries.map((data, index) => (
                        <option key={index} value={data}>
                          {data}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="my-4">
              <Row>
                <Col>
                  <label className="fw-bold" style={{ fontSize: "0.9rem" }}>
                    Contact Person Name *
                  </label>
                  <div className="d-flex flex-column">
                    <input
                      style={{ width: "auto", height: "auto" }}
                      className="form-control rounded-0"
                      onChange={handleInputChange}
                      value={formData.name}
                      name="name"
                      type="text"
                      maxLength={50}
                      required
                    />
                  </div>
                </Col>
                <Col>
                  <label className="fw-bold" style={{ fontSize: "0.9rem" }}>
                    {formData.entity === "Business"
                      ? "Company Email Address *"
                      : "Email Address *"}
                  </label>
                  <div className="d-flex flex-column">
                    <input
                      style={{ width: "auto", height: "auto" }}
                      className="form-control rounded-0"
                      // onChange={handleInputChange}
                      value={formData.email}
                      name="email"
                      type="email"
                      disabled
                      maxLength={40}
                      required
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="my-4">
              <Row>
                <Col>
                  <label className="fw-bold" style={{ fontSize: "0.9rem" }}>
                    Phone Number *
                  </label>
                  <input
                    onInput={(e) => {
                      e.target.value = Math.max(
                        0,
                        parseInt(e.target.value) || 0
                      )
                        .toString()
                        .slice(0, 10);
                    }}
                    className="form-control rounded-0"
                    onChange={handleInputChange}
                    value={formData.phoneNo}
                    name="phoneNo"
                    type="text"
                    required
                  />
                </Col>
                <Col md={6}>
                  <label className="fw-bold" style={{ fontSize: "0.9rem" }}>
                    Contact Preferred Time *
                  </label>
                  <Row>
                    <Col xs={6} sm={6} md={6}>
                      <select
                        className="form-control rounded-0"
                        onChange={handleInputChange}
                        value={formData.contactTimeFrom}
                        name="contactTimeFrom"
                        required
                      >
                        <option value="">Select..</option>
                        {timeSlots
                          .filter((time) => time !== "06:00PM")
                          .map((time, index) => (
                            <option key={index} value={time}>
                              {time}
                            </option>
                          ))}
                      </select>
                    </Col>
                    <Col xs={6} sm={6} md={6}>
                      <select
                        className="form-control rounded-0"
                        onChange={handleInputChange}
                        value={formData.contactTimeTo}
                        name="contactTimeTo"
                        required
                        disabled={!formData.contactTimeFrom}
                      >
                        <option value="">Select..</option>
                        {availableTimesTo.map((time, index) => (
                          <option key={index} value={time}>
                            {time}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="my-4">
              <label className="fw-bold" style={{ fontSize: "0.9rem" }}>
                Select Country *
              </label>
              {[...Array(Math.ceil(dropdownCount / 3))].map((_, rowIndex) => (
                <Row key={rowIndex} className="mb-3">
                  {[...Array(3)].map((_, colIndex) => {
                    const index = rowIndex * 3 + colIndex;
                    return (
                      index < dropdownCount && (
                        <Col key={index}>
                          <select
                            className="form-control rounded-0"
                            required
                            value={selectedCountries[index]}
                            onChange={(e) => handleCountryChange(index, e)}
                            name={`country-${index}`}
                          >
                            <option value="">Select a country...</option>
                            {collaboration_countrys.map((country, i) => (
                              <option key={i} value={country}>
                                {country}
                              </option>
                            ))}
                          </select>
                        </Col>
                      )
                    );
                  })}
                </Row>
              ))}
              {dropdownCount < 6 && (
                <Button className="mt-3" size="sm" onClick={addDropdown}>
                  Do you want to add more countries?
                </Button>
              )}
              {dropdownCount === 6 && (
                <p className="mt-3 text-danger">
                  more than 6 countries? Contact to Chamber Desk
                </p>
              )}
            </div>

            {formData.entity === "Business" && (
              <div className="my-4">
                <Row>
                  <Col>
                    <label className="fw-bold" style={{ fontSize: "0.9rem" }}>
                      IEC CODE / CIN NO *
                    </label>
                    <input
                      style={{ height: "auto" }}
                      className="form-control rounded-0"
                      // onChange={handleInputChangeCin}
                      value={formData.cinNumber}
                      name="cinNumber"
                      type="text"
                      disabled
                      minLength={10}
                      maxLength={10}
                      required
                    />
                  </Col>
                </Row>
              </div>
            )}
            <div className="my-4">
              <label className="fw-bold" style={{ fontSize: "0.9rem" }}>
                Purpose of participation *
              </label>
              <Form.Control
                as="textarea"
                style={{
                  height: "auto",
                  resize: "none",
                }}
                className="form-control rounded-0"
                onChange={handleInputChange}
                value={formData.userMessage}
                name="userMessage"
                maxLength={140}
                rows={2}
                required
              />
            </div>

            <div className="d-flex flex-column justify-content-between align-items-start mb-3">
              <div className="d-flex align-items-start gap-3 text-success">
                <LoadCanvasTemplate className="text-success" />
                {isCaptchaCorrect && (
                  <AiFillCheckCircle className="icon-size text-success" />
                )}{" "}
              </div>

              <div className="w-50">
                <input
                  className="w-100 px-2 py-1 border-1"
                  placeholder="Type the given captcha word"
                  id="user_captcha_input"
                  name="user_captcha_input"
                  type="text"
                  maxLength={captcha_length}
                  onChange={handleCaptchaChange}
                  value={captchaValue}
                />
                {isCaptchaCorrect ? (
                  <p className="text-success">CAPTCHA is valid</p>
                ) : (
                  <p className="text-danger">CAPTCHA is invalid</p>
                )}
              </div>
            </div>
            <div className="d-flex gap-2">
              <div>
                <Button
                  variant="secondary"
                  className="m-1 px-5 py-2 rounded-1 border-0 fw-medium"
                  onClick={() => setActivePage("b2bConnect")}
                >
                  Back
                </Button>
              </div>
              <div>
                <Button
                  variant="danger"
                  className="m-1 px-5 py-2 rounded-1 border-0 fw-medium"
                  onClick={resetForm}
                >
                  Reset
                </Button>
              </div>
              <div>
                <Button
                  type="submit"
                  className="m-1 px-5 py-2 rounded-1 border-0 fw-medium "
                  disabled={registerFormSuccess}
                >
                  {!registerFormSuccess ? "Submit" : "Processing..."}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

const SubmitMessage = ({ setActivePage }) => {
  return (
    <div
      className=""
      style={{
        background: "#E8F0FE",
        paddingInline: "50px",
        paddingBlock: "50px",
      }}
    >
      <div style={{ marginTop: "10px" }}>
        <h2>Thank You for Your Request!</h2>
        <p>We have received your request and will contact you soon.</p>

        <div className=" my-4">
          <div className=" mb-2">
            <h4 className="card-title">AECCI B2B Connect 2024</h4>
            <p className="card-text">
              Join our big event in October 2024 to connect with 25+ foreign
              partners, addressing your business needs through consultations,
              market studies, business matches, and more.
            </p>
            <p className="card-text">
              Get ready to meet foreign experts and receive support from the
              comfort of your business.
            </p>
          </div>

          <div className=" my-4">
            <div className=" mb-2">
              <h4>OUR Publications</h4>
              <p>Access all chamber publications in our Digital Library:</p>
              <ul style={{ color: "#35afd3" }}>
                <li>
                  <a
                    style={{ color: "#35afd3" }}
                    target="_blank"
                    href="https://www.aecci.org.in/aecci-viewpoints/"
                    rel="noreferrer"
                  >
                    Daily Viewpoints
                  </a>
                </li>
                <li>
                  <a
                    style={{ color: "#35afd3" }}
                    target="_blank"
                    href="https://www.aecci.org.in/media/e-newsletters/"
                    rel="noreferrer"
                  >
                    Newsletters
                  </a>
                </li>
                <li>
                  <a
                    style={{ color: "#35afd3" }}
                    target="_blank"
                    href="https://www.aecci.org.in/media/publications/"
                    rel="noreferrer"
                  >
                    Annual Magazine
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FAQ = ({ setActivePage }) => {
  return (
    <div className="border p-4 rounded faq-container">
      <div className="mb-4">
        <h2 className="fw-bold">
          Frequently Asked Questions <FaQuestionCircle className="me-2" />
        </h2>
      </div>
      <div className="accordion" id="faqAccordion">
        <div className="faq-item mt-4">
          <h5 className="fw-bold text-dark">
            Q.1 What is the purpose of organising AECCI B2B Connect 2024?
          </h5>
          <p className="text-muted">
            Answer: Purpose of AECCI B2B Connect 2024 is to create the
            networking opportunities among the businesses of Asian Countries and
            to facilitate the participant’s business with delegates expertise.
          </p>
        </div>

        <div className="faq-item mt-4">
          <h5 className="fw-bold text-dark">
            Q.2 Who all will be the participants of the Event?
          </h5>
          <p className="text-muted">
            Answer: We have invited businesses who are in to international trade
            like, exporters & importers and the individual professionals,
            consultants, seeking foreign trade expertise.
          </p>
        </div>

        <div className="faq-item mt-4">
          <h5 className="fw-bold text-dark">
            Q.3 What is the minimum and maximum number of slots?
          </h5>
          <p className="text-muted">
            Answer: Event will be of minimum two and maximum three days.Per
            Country, Per day slots – minimum 10 and maximum 16.
          </p>
        </div>

        <div className="faq-item mt-4">
          <h5 className="fw-bold text-dark">
            Q.4 What assistance may I get for VISA issuance?
          </h5>
          <p className="text-muted">
            Answer: You can be provided with the invitation/invitation letter by
            the organiser-AECCI.
          </p>
        </div>

        <div className="faq-item mt-4">
          <h5 className="fw-bold text-dark">
            Q.5 Who will bear the cost of travel and stay of the delegates.
          </h5>
          <p className="text-muted">
            Answer: As stated in the cooperation agreement, none of the party
            carries any financial obligation towards each other. Hence, the
            charges are to be borne by the delegate firms coming to visit the
            event. All the charges w.r.t. the Air-Ticket, Hotel Stay, Local
            transportation or VISA fees etc are to be borne by the delegate
            only.
          </p>
        </div>

        <div className="faq-item mt-4">
          <h5 className="fw-bold text-dark">
            Q.6 Can I seek assistance for hotel and travel arrangements in
            India.
          </h5>
          <p className="text-muted">
            Answer: Yes! We will share the contacts of our nominated vendors for
            your convenience. If needed, AECCI can arrange the booking on behalf
            of the visitor.
          </p>
        </div>

        <div className="faq-item mt-4">
          <h5 className="fw-bold text-dark">
            Q.7 What is the maximum tenure to stay in India on Conference VISA
            basis?
          </h5>
          <p className="text-muted">
            Answer: AECCI will recommend for all the delegates a stay of 3 days
            minimum for delegation.
          </p>
        </div>

        <div className="faq-item mt-4">
          <h5 className="fw-bold text-dark">
            Q.8 How many of my colleagues can accompany me on for this
            conference?
          </h5>
          <p className="text-muted">
            Answer: Maximum two delegates will be allowed from each foreign
            company to join as delegates. Fees will be applicable on ‘Per
            Delegate” basis.
          </p>
        </div>

        <div className="faq-item mt-4">
          <h5 className="fw-bold text-dark">
            Q.9 Is there any specific documents that I need to carry during my
            visit?
          </h5>
          <p className="text-muted">
            Answer: Ensure you have all necessary documents related to personal
            and official credentials.
          </p>
        </div>

        <div className="faq-item mt-4">
          <h5 className="fw-bold text-dark">
            Q.10 What is the detailed itinerary of the visit?
          </h5>
          <p className="text-muted">
            Answer: Itinerary will be shared 30 days advance of the visit to
            India.
          </p>
        </div>

        <div className="faq-item mt-4">
          <h5 className="fw-bold text-dark">
            Q.11 Will translation or interpretation services be available if
            needed?
          </h5>
          <p className="text-muted">
            Answer: Yes! We can arrange if the requirement is placed in advance.
          </p>
        </div>

        <div className="faq-item mt-4">
          <h5 className="fw-bold text-dark">
            Q.12 When the applicable fees is to be paid?
          </h5>
          <p className="text-muted">
            Answer: You can pay the fees upon arrival in India but a day prior
            to the event. Fee is payable in INR.
          </p>
        </div>

        <div className="faq-item mt-4">
          <h5 className="fw-bold text-dark">
            Q.13 When we will get the Event-Passes?
          </h5>
          <p className="text-muted">
            Answer: Upon sharing the copy of VISA through AECCI B2B Platform you
            will be able to generate the <br /> Event Pass online
          </p>
        </div>

        <div className="faq-item mt-4">
          <h5 className="fw-bold text-dark">
            Q.14 Whom should I contact in case of emergency?
          </h5>
          <p className="text-muted">
            **Answer:** For immediate support, please reach out to the following
            contacts based on your needs:
            <br />
            <strong>
              For issues during Custom Clearance at Indian airports:
            </strong>
            <br />
            Ms. Rohini P.K. and Ms. Akshata Mulik - Handphone: 0091-8433720996
            <br />
            <strong>For issues related to stay or local transportation:</strong>
            <br />
            Mr. Harish Shetty - Handphone: 919004263236
            <br />
            <strong>
              For issues during interactions with business participants:
            </strong>
            <br />
            Ms. Swarn Lata - Handphone: 0091 9663355575
          </p>
        </div>

        <div className="faq-item mt-4">
          <h5 className="fw-bold text-dark">
            Q.15 What is the procedure for post-visit follow-up and reporting?
          </h5>
          <p className="text-muted">
            Answer: post visit follow-up and reporting will all be done through
            AECCI e-Platform.
          </p>
        </div>
      </div>
    </div>
  );
};

const CollaborationCountryList = () => {
  const halfIndex = Math.ceil(collaboration_countrys.length / 2);
  const firstHalf = collaboration_countrys.slice(0, halfIndex);
  const secondHalf = collaboration_countrys.slice(halfIndex);
  return (
    <Row className="p-2">
      <h3 className="text-center mb-3">Our Collaboration</h3>
      <Col md={6}>
        <div className="border rounded shadow-sm p-3">
          {/* <h5 className="text-center">Our Partners</h5> */}
          <ul>
            {firstHalf.map((country, index) => (
              <li key={index} className="mb-2">
                <span className="fs-6">{country}</span>
              </li>
            ))}
          </ul>
        </div>
      </Col>
      <Col md={6}>
        <div className="border rounded shadow-sm p-3">
          {/* <h5 className="text-center">Our Partners</h5> */}
          <ul>
            {secondHalf.map((country, index) => (
              <li key={index} className="mb-2">
                <span className="fs-6">{country}</span>
              </li>
            ))}
          </ul>
        </div>
      </Col>
    </Row>
  );
};

const VisitingProtocol = ({ setActivePage, setSelectedTab }) => {
  const [showProtocol, setShowProtocol] = useState(false);
  const [showGuide, setShowGuide] = useState(false);
  const [showCountry, setShowCountry] = useState(false);
  return (
    <div className="border p-2">
      <div className="text-dark fw-bold fs-2 px-2 mt-3">
        Protocol for International Visits
      </div>
      <div>
        <div className="fs-6 text-dark px-3 text-justify">
          <p>
            This page is intended for foreign delegates attending AECCI B2B
            Connect 2024.
          </p>

          <p>
            This guide provides essential information to help delegates prepare
            for the meeting and facilitate their participation. It includes
            details on booked slots, slot timings, interaction sessions, and
            meeting-specific agendas.
          </p>

          <p>
            AECCI B2B Connect 2024, a business delegation scheduled in the last
            week of October-2024. This will be organized on “one-to-one”
            interaction basis at the Courtyard, Navi Mumbai (India).
          </p>
        </div>

        <div className="text-dark fw-bold fs-4 px-2 mt-3">Protocol Support</div>
        <div className="fs-6 text-dark px-3 text-justify">
          Due to time constraints the Meeting will consider substantive and will
          follow in the following manner:
        </div>
        <div className="fs-6 text-dark px-3 py-2 text-justify">
          <ul>
            <li>
              To accommodate the time differences and distances between AECCI,
              delegates, and participants, accounts will be created for each
              delegate on the AECCI e-platform.
            </li>
            <li>
              Pre-event FAQs, based on issues raised by participants, will be
              uploaded to each delegate's profile on the e-platform.
            </li>
            <li>
              During the event, each business participant will be allocated a
              30-minute session to discuss with their chosen country's delegate.
            </li>
            <li>
              To overcome language barriers and enhance participant
              understanding, resolutions provided by delegates will be uploaded
              to the e-platform.
            </li>
            <li>
              After the event, country-specific statements may need to be
              summarized and submitted to AECCI through the e-platform.
            </li>
          </ul>
        </div>

        {/* <div className="fs-6 text-dark px-3 text-justify">
          If you are considering drafting an agreement with a visiting
          institution, please see our international agreements page for
          information
        </div> */}
        <div className="contact-info mt-3">
          <div className="text-dark fw-bold fs-4 px-2 pb-2">
            Contact Information
          </div>
          <div className="fs-6 text-dark px-3">
            <h6 className="fw-semibold mb-1">
              <strong>Name:</strong> Mrs. Swarn Dhiman, ( Executive Director )
            </h6>
            <p className="fw-semibold mb-1">
              ( AECCI B2B Connect Event Team Leader )
            </p>
            <div className="mb-2">
              <p className="mb-0">
                <strong>Board lines:</strong> +91-22-412 711 45 | 46
              </p>
              <p className="mb-0">
                <strong>Mobile/WhatsApp:</strong> +91-8433720996
              </p>
              <p className="mb-0">
                <strong>Email: </strong>
                <a
                  href="mailto:ed@aecci.org.in"
                  className="text-primary text-decoration-none"
                >
                  ed@aecci.org.in
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="contact-info mt-3">
          <div className="text-dark fw-bold fs-4 px-2 pb-2">Resources</div>
          <div className="fs-6 text-dark px-3">
            <h5 className="fw-semibold">Preparing for Visiting Delegations</h5>
            <div className="fs-6 text-dark px-3 pt-2 text-justify">
              <ul>
                <li>Visit agenda</li>
                <li>Delegates profile</li>
                <li>
                  <span
                    onClick={() => setSelectedTab("about")}
                    style={{
                      cursor: "pointer",
                      color: "blue",
                      textDecoration: "underline",
                    }} // Optional styling
                  >
                    General briefing for Delegate
                  </span>
                </li>
                <li>
                  Things to carry - Copy of Delegates' passport, Identity card
                  from your bar association, Identity card provided to you by
                  the AECCI., for the event,
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="text-dark fw-bold fs-4 px-2 mt-2">Protocol Guides</div>
        <div className="fs-6 text-dark px-3 pt-2 text-justify">
          <ul>
            <li>
              <a
                onClick={() => setShowProtocol(true)}
                className="text-primary fw-bold  cursor-pointer"
              >
                Delegation Protocol
              </a>
            </li>
            <li>
              <a
                onClick={() => setShowGuide(true)}
                className="text-primary fw-bold  cursor-pointer"
              >
                Guide to Participate
              </a>
            </li>
            <PopupModalText
              title="Delegation Protocol"
              show={showProtocol}
              onHide={() => setShowProtocol(false)}
              body={<DelegationProtocol />}
            />

            <PopupModalText
              title="INFORMATION FOR DELEGATES"
              show={showGuide}
              onHide={() => setShowGuide(false)}
              body={<DelegationGuide />}
            />
          </ul>
        </div>
        <div className="text-dark fw-bold fs-4 px-2 mt-2">
          World Country Information
        </div>
        <div className="fs-6 text-dark px-3 pt-2 text-justify">
          <ul>
            <li>
              <a
                onClick={() => setShowCountry(true)}
                className="text-primary fw-bold  cursor-pointer"
              >
                Visting Collaboration Country List
              </a>
            </li>
          </ul>
          <PopupModalText
            title="Collaboration Country List"
            show={showCountry}
            onHide={() => setShowCountry(false)}
            body={
              <>
                <CollaborationCountryList />
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};

const DelegationProtocol = () => {
  const handlePrint = () => {
    // Select the element to print
    const element = document.getElementById("printable-content");

    // Define options for html2pdf
    const options = {
      margin: 1,
      filename: "Delegation_Protocol.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "A4", orientation: "portrait" },
    };

    // Generate PDF from HTML content
    html2pdf()
      .from(element)
      .set(options)
      .outputPdf("blob")
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const printWindow = window.open(url, "_blank");
        if (printWindow) {
          printWindow.addEventListener("load", function () {
            printWindow.focus();
            printWindow.print();
          });
        }
      });
  };

  return (
    <Container className="my-4">
      <div id="printable-content">
        <section>
          <h4 className="mb-3">Registration and Confirmation</h4>
          <ol>
            <li>
              Delegates must complete the registration process and submit all
              required documents by <strong>31.08.2024</strong>.
            </li>
            <li>
              Confirmation of participation will be sent upon successful
              registration and receipt of all necessary documents.
            </li>
          </ol>
        </section>

        <section className="mt-4">
          <h4 className="mb-3">Registration Fees</h4>
          <ul>
            <li>
              Registration fees is given in INR/Per delegate (equivalent to USD
              100).
            </li>
            <li>
              The fee includes space, meeting arrangements, conference
              materials, Meals during scheduled event, etc
            </li>
            <li>
              The fees do not include air-fare, accommodation, local
              transportation or off-time meals/food and beverages. Etc.
            </li>
            <li>
              Event fee, can be paid upon arrival in India but a day prior to
              the event Fee is payable in INR.
            </li>
            <li>
              While we do our best to set the event fee well in advance &
              maintain the same, we reserve the right to change costs due to
              extraordinary circumstances beyond our control.
            </li>
          </ul>
        </section>

        <section className="mt-4">
          <h4 className="mb-3">Visa and Travel</h4>
          <ul>
            <li>
              Delegates are responsible for obtaining the appropriate visa for
              travel to India. AECCI can provide an invitation letter if needed.
            </li>
            <li>
              Delegates must ensure their passport is valid for at least six
              months from the date of entry into India.
            </li>
            <li>
              Travel and accommodation arrangements are the sole responsibility
              of the delegates. AECCI may provide recommendations for
              accommodation and local transport.
            </li>
          </ul>
          <p className="fw-semibold">
            If you encounter any issues or need assistance, please feel free to
            reach out to Mr. Harish Shetty. You can contact him at{" "}
            <a href="tel:+919004263236">+91 9004263236</a> or email him at{" "}
            <a href="mailto:hr@aecci.org.in">hr@aecci.org.in</a>.
          </p>
        </section>

        <section className="mt-4">
          <h4 className="mb-3">Health and Safety</h4>
          <ul>
            <li>
              Delegates must adhere to all local health and safety regulations.
            </li>
            <li>
              It is recommended that delegates have appropriate travel insurance
              that includes health coverage.
            </li>
          </ul>
        </section>

        <section className="mt-4">
          <h4 className="mb-3">Changes to the Itinerary and Event</h4>
          <ul>
            <li>
              While every effort will be made to carry out the event as planned,
              AECCI reserves the right to cancel, terminate, or alter the event
              in any way, including itinerary, activities, and excursions, at
              any time and for any reason, with or without notice.
            </li>
            <li>
              In the event of termination or cancellation, AECCI shall notify
              any affected Participant as soon as reasonably possible. Rest
              assured that should any changes be needed, we commit to delivering
              an experience as outstanding as the one originally planned.
            </li>
          </ul>
        </section>

        <section className="mt-4">
          <h4 className="mb-3">Liability</h4>
          <ul>
            <li>
              The Organization shall not be liable for any loss, injury, or
              damage to personal property incurred by delegates during the
              event. It is the sole responsibility of the delegates to secure
              appropriate insurance coverage to protect against any potential
              risks or liabilities that may arise during their participation in
              the event. The Organization disclaims any responsibility or
              obligation to compensate for such losses, injuries, or damages,
              and delegates hereby acknowledge and agree to this limitation of
              liability.
            </li>
          </ul>
          <p className="fw-semibold">
            For any concerns, you may reach out to our legal team: Ms. Rohini
            P.K. and Ms. Akshata Mulik. You can contact them at{" "}
            <a href="tel:+918433720996">+91 8433720996</a> or via email at{" "}
            <a href="mailto:legal@aecci.org.in">legal@aecci.org.in</a>.
          </p>
        </section>

        <section className="mt-4">
          <h4 className="mb-3">Payments, Cancellations, and Refunds</h4>
          <ul>
            <li>Event fee of USD 100 is due at the time of application. </li>
            <li>
              Event fee, can be paid upon arrival in India but a day prior to
              the event Fee is payable in INR.
            </li>
            <li>An invoice will be issued upon receiving the event fee.</li>
            <li>
              All cancellations must be received in writing (email). Per-person
              cancellation fees are based on the date of the cancellation
              notice.
            </li>
          </ul>
        </section>

        <section className="mt-4">
          <h4 className="mb-3">Cancellation and Refund Guidelines</h4>
          <p className="fw-semibold">
            Wish to cancel your registration? Mention your booking reference
            number, full name & email ID, and proceed for cancellation.
          </p>
        </section>

        <section className="mt-4">
          <h5>Cancellation Policy</h5>
          <p className="fw-semibold">
            <strong>45 – 60 days prior to the delegation convene date:</strong>{" "}
            50% of Program Cost
          </p>
          <p className="fw-semibold">
            <strong>31 – 45 days prior to the delegation convene date:</strong>{" "}
            75% of Program Cost
          </p>
          <p className="fw-semibold">
            <strong>
              0-31 days or less prior to the delegation convene date:
            </strong>{" "}
            100% of Program Cost
          </p>
          <p className="fw-bold fs-6">
            If cancellation of the program is led by AECCI, a full refund will
            be provided to you.
          </p>
        </section>

        <section className="mt-4">
          <h4 className="mb-3">Code of Conduct</h4>
          <p className="fw-normal">
            Delegates are expected to conduct themselves professionally and
            respectfully. Behavior that causes discomfort or is seen as
            inflammatory towards any individual associated with this program
            will not be tolerated. This includes, but is not limited to,
            inappropriate comments, behavior, physical contact, racial slurs,
            gender comments, or alcohol-related conduct.
          </p>
          <p className="fw-normal">
            Any disruptive behavior may result in removal from the conference at
            the discretion of AECCI. In such cases, you may be asked to separate
            from the delegation and no longer participate in any activity,
            transportation, or meals.
          </p>
          <p className="fw-normal">
            In the event you are asked to remove yourself from the program, no
            refund for unused portions of the program will be provided, and all
            costs associated with your removal will be your responsibility.
          </p>
        </section>

        <section className="mt-4">
          <h5>Governing Law</h5>
          <p className="fw-normal">
            <strong>A.</strong> The terms and conditions of the event shall be
            governed exclusively by the laws of India, without regard to its
            conflict of law principles.
          </p>
          <p className="fw-normal">
            <strong>B.</strong> Any disputes arising out of or relating to the
            event shall be first attempted to be resolved through friendly
            consultations between the delegations of the respective countries.
          </p>
          <p className="fw-normal">
            <strong>C.</strong> If an amicable settlement cannot be reached, any
            dispute or difference arising out of or relating to the event shall
            be subject to the exclusive jurisdiction of the judicial courts in
            the Republic of India. Any breach of these terms and conditions
            shall be governed, construed, and interpreted in accordance with the
            laws of the Republic of India.
          </p>
        </section>

        <section className="mt-4">
          <h5>General</h5>
          <p className="fw-normal">
            In signing this form, the Participant, individually and on behalf of
            his/her office, heirs, successors, assigns, and personal
            representatives, hereby releases and forever discharges AECCI and
            its employees, agents, officers, trustees, and representatives from
            any and all liability whatsoever for any and all damages, losses,
            costs, or injuries (including death) Participant sustains to person
            or property or both.
          </p>
          <p className="fw-normal">
            This release includes, but is not limited to, any claims, demands,
            actions, causes of action, fines, judgments, damages, expenses, and
            costs, including attorney’s fees and costs, which arise out of,
            result from, occur during, or are connected in any manner with
            Participant’s participation in the Program, any activities, or
            excursions, irrespective of whether they are sponsored, supervised,
            or controlled by AECCI.
          </p>
          <p className="fw-normal">
            Exceptions to this release include losses caused by the gross
            negligence or gross and willful misconduct of the Representatives.
            Participant also agrees to fully indemnify and hold harmless the
            Representatives from and against any losses.
          </p>
        </section>
      </div>
      <div className="d-flex justify-content-center">
        <Button onClick={handlePrint} className="mt-4">
          Print
        </Button>
      </div>
    </Container>
  );
};

const DelegationGuide = () => {
  const countries = [
    "Algeria",
    "Bangladesh",
    "Bahamas",
    "Bosnia & Herzegovina",
    "Brazil",
    "Cambodia",
    "China",
    "Denmark",
    "Egypt",
    "Ethiopia",
    "Greece",
    "Mexico",
    "Nigeria",
    "Oman",
    "Pakistan",
    "South Sudan",
    "Sri Lanka",
    "Tanzania",
    "Thailand",
    "Turkey",
    "United Kingdom",
    "UAE",
    "Uzbekistan",
  ];

  const handlePrint = () => {
    // Select the element to print
    const element = document.getElementById("printable-content");

    // Define options for html2pdf
    const options = {
      margin: 1,
      filename: "Delegation_Protocol.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "A4", orientation: "portrait" },
    };

    // Generate PDF from HTML content
    html2pdf()
      .from(element)
      .set(options)
      .outputPdf("blob")
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const printWindow = window.open(url, "_blank");
        if (printWindow) {
          printWindow.addEventListener("load", function () {
            printWindow.focus();
            printWindow.print();
          });
        }
      });
  };

  return (
    <Container className="my-4">
      <div id="printable-content">
        <h4 className="mb-3 text-center">
          Guide to Participate- AECCI B2B Connect 2024.
        </h4>

        <section className="mt-4">
          <p className="fw-semibold">
            Note: This page is primarily for foreign delegates joining us for{" "}
            <span className="fw-bold"> AECCI B2B Connect 2024.</span>
          </p>
          <p className="fw-semibold">
            This guide includes information to assist delegates attending the
            meeting with their preparations and to facilitate their
            participation. The document includes useful information on slots
            booked, slot-timings, interaction meets, meeting wise agenda.
          </p>
          <p className="fw-semibold">
            AECCI B2B Connect 2024- A Mega event is going to be held in the last
            week of October 2024. This will be on “One to one” table discussion
            basis at The Courtyard, Navi Mumbai (India).
          </p>
          <ul>
            <li>Event Date will be announced by 25 th August, 2024.</li>
            <li>Event will be of three days.</li>

            <li>Timing of the event will be 9:30 to 6 Pm</li>
            <li>Two delegates will be allowed from each collaborative firm.</li>
            <li>
              Delegates country flags and company flex will be displayed at the
              venue.
            </li>
            <li>
              Each country will be interacting with Minimum 10 business slots or
              maximum 16 business slots each day,
            </li>
            <li>Each slot will be of 30 minutes.</li>
            <li>
              Information of the participating businesses will be visible to the
              delegates on the e-Platform.
            </li>
            <li>
              Considering the hectic schedule event will focus on table-meets
              only and no other presentations or public speaking will be
              organised.
            </li>
          </ul>
        </section>

        <section className="mt-4">
          <h6 className="mb-3 fw-bold">
            Due to time constraints the Meeting will consider substantive and
            will follow in the following manner:
          </h6>
          <ol>
            <li>
              Considering the time and distance between AECCI, delegates, and
              participants, accounts are created on the AECCI e-platform for
              each delegate.
            </li>
            <li>
              Pre-hand FAQs based on issues received from participants will be
              uploaded to delegates' profiles on the e-platform.
            </li>
            <li>
              During the event, each business participant will be given 30
              minutes for table discussions with their chosen country’s
              delegate.
            </li>
            <li>
              To avoid language barriers and ensure better understanding,
              resolutions given by the delegates will be preferred to be
              uploaded on the e-platform.
            </li>
            <li>
              Country-wise statements may need to be summarized to AECCI through
              the e-platform after the event's completion.
            </li>
          </ol>
        </section>

        <section className="mt-4">
          <p>
            To participate in the event, delegates are requested to complete the{" "}
            <strong>Delegates Request Form</strong> available online through the
            AECCI e-Platform.
          </p>

          <p>
            the delegates should be registered online as soon as possible and no
            later than <strong>31st August, 2024</strong>.
          </p>

          <p>List of participating countries is given here:</p>
        </section>

        <section className="mt-3">
          <Container>
            <Row>
              {countries.map((country, index) => (
                <Col key={index} xs={6} md={3} className="mb-2">
                  {country}
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      </div>
      <div className="d-flex justify-content-center">
        <Button onClick={handlePrint} className="mt-4">
          Print
        </Button>
      </div>
    </Container>
  );
};

const BookRegistrationForm = ({ setActivePage }) => {
  const [totalAmt, setTotalAmt] = useState(0);

  const initialFormData = {
    passports: [
      {
        firstName: "",
        lastName: "",
        gender: "",
        birthDate: "",
        designation: "",
        passportNo: "",
        issueDate: "",
        expiryDate: "",
        missionAddress: "",
        delegatePhoto: null,
        passportCopy: null,
      },
    ],
    amount: "",
    isMember: "Member",
    //files
    applicationForm: null,
    registrationForm: null,
    companyProfile: null,
    collaborationAgreement: null,
    businessLicense: null,
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleAddPassport = () => {
    if (formData.passports.length < 2) {
      // Check if there are less than 2 passports
      setFormData((prevState) => ({
        ...prevState,
        passports: [
          ...prevState.passports,
          {
            id: Date.now(),
            firstName: "",
            lastName: "",
            gender: "",
            birthDate: "",
            designation: "",
            passportNo: "",
            issueDate: "",
            expiryDate: "",
            missionAddress: "",
            delegatePhoto: null,
            passportCopy: null,
          },
        ],
      }));
    }
  };

  const handleRemovePassport = (index) => {
    if (formData.passports.length > 1) {
      // Ensure at least one row remains
      setFormData((prevState) => {
        const updatedPassports = prevState.passports.filter(
          (_, i) => i !== index
        );
        return {
          ...prevState,
          passports: updatedPassports,
        };
      });
    }
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      const updatedPassports = [...prevFormData.passports];
      updatedPassports[index] = {
        ...updatedPassports[index],
        [name]: value,
      };
      return {
        ...prevFormData,
        passports: updatedPassports,
      };
    });
  };

  const handleFileUpload = (field, file, index) => {
    if (file) {
      if (field === "passportCopy" && file.type !== "application/pdf") {
        console.error("File Should be PDF");
        alert("File Should be PDF");
        return;
      }
      if (field === "delegatePhoto" && !file.type.startsWith("image/")) {
        console.error("File Should be an Image");
        alert("File Should be an Image");
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        console.error("File size exceeds 5MB");
        alert("File size exceeds 5MB");
        return;
      }

      setFormData((prevData) => {
        const updatedData = { ...prevData };

        if (index !== undefined) {
          // Handle passport data updates
          const updatedPassports = [...prevData.passports];
          updatedPassports[index] = {
            ...updatedPassports[index],
            [field]: file,
          };
          updatedData.passports = updatedPassports;
        } else {
          // Handle other file uploads
          updatedData[field] = file;
        }

        return updatedData;
      });
    }
  };

  const handleRadioChange = (value) => {
    setFormData({
      ...formData,
      isMember: value,
    });
  };

  const calculateTotalAmount = () => {
    // Add country-specific amount
    if (formData.isMember === "Member") {
      formData.amount = 100;
    } else {
      formData.amount = 250;
    }

    return formData.amount;
  };

  const handleRegisterFormSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    return;

    try {
      const validateField = (field, fieldName, regex) => {
        if (
          field === undefined ||
          (typeof field === "string" && field.trim() === "")
        ) {
          console.log("Invalid Form, field:", field);
          alert(`Invalid Form, ${fieldName} cannot be empty`);
          return false;
        }
        if (regex && !regex.test(field)) {
          console.log("Invalid Form, field:", field);
          alert(`Invalid Form, ${fieldName} is not in the correct format`);
          return false;
        }

        return true;
      };

      const validateFile = (file, fieldName) => {
        if (!file) {
          console.log(`Invalid Form, ${fieldName} is required`);
          alert(`Invalid Form, ${fieldName} is required`);
          return false;
        }
        return true;
      };

      // Validate passport data
      for (const passport of formData.passports) {
        if (
          !validateField(passport.firstName, "Passport First Name") ||
          !validateField(passport.lastName, "Passport Last Name") ||
          !validateField(passport.gender, "Passport Gender") ||
          !validateField(passport.birthDate, "Passport Birth Date") ||
          !validateField(passport.designation, "Passport Designation") ||
          !validateField(passport.passportNo, "Passport Number") ||
          !validateField(passport.issueDate, "Passport Issue Date") ||
          !validateField(passport.expiryDate, "Passport Expiry Date") ||
          !validateField(passport.missionAddress, "Passport Mission Address") ||
          !validateFile(passport.delegatePhoto, "Delegate Photo") ||
          !validateFile(passport.passportCopy, "Passport Copy")
        ) {
          alert(
            "Please fill out all passport fields and upload necessary files."
          );
          return;
        }
      }

      // Validate file uploads
      const fileFields = [
        { field: formData.applicationForm, name: "Application Form" },
        { field: formData.registrationForm, name: "Registration Form" },
        { field: formData.companyProfile, name: "Company Profile" },
        {
          field: formData.collaborationAgreement,
          name: "Collaboration Agreement",
        },
        { field: formData.businessLicense, name: "Business License" },
      ];

      for (const { field, name } of fileFields) {
        if (!validateFile(field, name)) return;
      }

      // Calculate total amount
      const totalAmount = calculateTotalAmount();

      // Prepare the data to be submitted
      const submissionData = {
        ...formData,
        amount: totalAmount,
      };

      // Send the data to the server
      const res = await axios.post(
        getURLbyEndPointV2("createInvitationLetter"),
        submissionData
      );

      if (res && res.data && res.data.status) {
        toast.success(res.data.message);
        // Handle successful submission (e.g., reset form, navigate to another page, etc.)
      } else {
        console.log("Response data:", res);
        toast.error(res?.data?.message || "An error occurred");
      }
    } catch (e) {
      console.log("Error caught:", e);
      toast.error(e?.response?.data?.message || "An error occurred");
    }
  };

  useEffect(() => {
    const totalAmt = calculateTotalAmount();

    setTotalAmt(totalAmt);

    // Update formData only if the amount has changed
    if (formData.amount !== totalAmt) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        amount: totalAmt,
      }));
    }
  }, [formData, setTotalAmt, setFormData]);

  return (
    <>
      <div className="border rounded-2 p-3">
        <h6>
          We are delighted to welcome you to the Mega-Event
          <span className="fw-semibold"> “AECCI B2B Connect 2024”</span>,
          scheduled to be held in October 2024, in Navi Mumbai india.
        </h6>
        <h6>
          This conference promises to be an exceptional opportunity to connect
          with Indian business community and engage in valuable networking.
        </h6>
        <h6>
          To facilitate a smooth registration process, we kindly request you to
          complete the registration form:
        </h6>
      </div>

      <table
        className="table table-bordered my-2"
        style={{ tableLayout: "fixed" }}
      >
        <colgroup>
          <col style={{ width: "100%" }} />
        </colgroup>
        <tr className="align-middle">
          <th
            style={{ backgroundColor: "#0b3b5d", color: "white" }}
            scope="col"
            className="text-center align-middle"
          >
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "#0b3b5d", color: "white" }}
            >
              <h5 className="mb-0 text-center flex-grow-1">
                BOOK YOUR REGISTRATION
              </h5>
            </div>
          </th>
        </tr>
      </table>
      <Table bordered striped>
        <thead>
          <tr>
            <th className="fw-medium" style={{ width: "10%" }}>
              Sr No
            </th>
            <th className="fw-medium" style={{ width: "70%" }}>
              DOCUMENT REQUIRED
            </th>
            <th className="fw-medium" style={{ width: "20%" }}>
              UPLOAD
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <div className="d-flex align-items-center">
                <h6>1</h6>
              </div>
            </td>
            <td
              className="fw-bold text-secondary align-middle text-start"
              style={{ width: "50%" }}
            >
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip>
                        Please download the form, fill-in, sign & stamp and
                        reupload.
                      </Tooltip>
                    }
                  >
                    <img src={InfoImg} style={{ height: "48px" }} />
                  </OverlayTrigger>
                  Registration form
                </div>
                <BiSolidFilePdf
                  className="icon-size text-danger clickable cursor-pointer"
                  onClick={() =>
                    window.open(pdfLinks["DelegateRegistrationForm"], "_blank")
                  }
                />{" "}
              </div>
            </td>
            <td className="align-middle" style={{ width: "50%" }}>
              <div className="d-flex justify-content-center align-items-center">
                <div className="me-3">
                  <label
                    className={
                      formData.registrationForm
                        ? "btn btn-success"
                        : "btn btn-primary"
                    }
                    htmlFor="registrationForm"
                  >
                    {formData.registrationForm
                      ? "Uploaded"
                      : "Upload Registration Form"}
                  </label>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    name="registrationForm"
                    id="registrationForm"
                    accept=".pdf"
                    onChange={(e) =>
                      handleFileUpload("registrationForm", e.target.files[0])
                    }
                  />
                </div>
                {formData.registrationForm && (
                  <a
                    href={URL.createObjectURL(formData.registrationForm)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BsEye className="icon-size" />
                  </a>
                )}
              </div>
            </td>
          </tr>
          {formData.passports.map((data, docIndex) => (
            <tr key={docIndex}>
              <td>
                <div className="d-flex align-items-center">
                  <h6>{docIndex + 1}</h6>
                </div>
              </td>
              <td
                className="fw-bold text-secondary align-middle text-start"
                style={{ width: "50%" }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip>
                          Fill in the passport details carefully and exactly as
                          per Passport.
                        </Tooltip>
                      }
                    >
                      <img
                        src={InfoImg}
                        style={{ height: "48px" }}
                        alt="Info"
                      />
                    </OverlayTrigger>
                    Passport Details
                  </div>
                  {docIndex === formData.passports.length - 1 && (
                    <div className="d-flex justify-content-between align-items-center">
                      {formData.passports.length < 2 && (
                        <Button
                          className="setup-bg-color"
                          size="sm"
                          onClick={handleAddPassport}
                        >
                          +
                        </Button>
                      )}
                      {formData.passports.length > 1 && (
                        <Button
                          className="setup-bg-color"
                          size="sm"
                          onClick={() => handleRemovePassport(docIndex)}
                        >
                          -
                        </Button>
                      )}
                    </div>
                  )}
                </div>

                <Row className="mb-3">
                  <Col md={4}>
                    <Form.Group controlId={`firstName_${docIndex}`}>
                      <Form.Label>First Name*</Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => handleInputChange(e, docIndex)}
                        value={data.firstName}
                        name="firstName"
                        maxLength={40}
                        required
                        placeholder="Enter First Name"
                      />
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    <Form.Group controlId={`lastName_${docIndex}`}>
                      <Form.Label>Last Name*</Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => handleInputChange(e, docIndex)}
                        value={data.lastName}
                        name="lastName"
                        maxLength={40}
                        required
                        placeholder="Enter Last Name"
                      />
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    <Form.Group controlId={`gender_${docIndex}`}>
                      <Form.Label>Gender*</Form.Label>
                      <Form.Select
                        onChange={(e) => handleInputChange(e, docIndex)}
                        value={data.gender}
                        name="gender"
                        required
                      >
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3 align-items-end">
                  <Col md={4}>
                    <Form.Group controlId={`birthDate_${docIndex}`}>
                      <Form.Label>Date of Birth*</Form.Label>
                      <Form.Control
                        type="date"
                        onChange={(e) => handleInputChange(e, docIndex)}
                        value={data.birthDate}
                        name="birthDate"
                        required
                      />
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    <Form.Group controlId={`designation_${docIndex}`}>
                      <Form.Label>Designation*</Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => handleInputChange(e, docIndex)}
                        value={data.designation}
                        name="designation"
                        maxLength={40}
                        required
                        placeholder="Enter Designation"
                      />
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    <div>
                      <label
                        className={
                          data.delegatePhoto
                            ? "btn btn-success"
                            : "btn btn-primary"
                        }
                        htmlFor={`delegatePhoto_${docIndex}`}
                      >
                        Delegate Photograph*
                      </label>
                      <input
                        style={{ display: "none" }}
                        type="file"
                        name="delegatePhoto"
                        id={`delegatePhoto_${docIndex}`}
                        className="m-0"
                        accept="image/*"
                        onChange={(e) =>
                          handleFileUpload(
                            "delegatePhoto",
                            e.target.files[0],
                            docIndex
                          )
                        }
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md={4}>
                    <Form.Group controlId={`passportNo_${docIndex}`}>
                      <Form.Label>Passport Number*</Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => handleInputChange(e, docIndex)}
                        value={data.passportNo}
                        name="passportNo"
                        maxLength={40}
                        required
                        placeholder="Enter Passport No"
                      />
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    <Form.Group controlId={`issueDate_${docIndex}`}>
                      <Form.Label>Date of Issue*</Form.Label>
                      <Form.Control
                        type="date"
                        onChange={(e) => handleInputChange(e, docIndex)}
                        value={data.issueDate}
                        name="issueDate"
                        required
                      />
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    <Form.Group controlId={`expiryDate_${docIndex}`}>
                      <Form.Label>Date of Expiry*</Form.Label>
                      <Form.Control
                        type="date"
                        onChange={(e) => handleInputChange(e, docIndex)}
                        value={data.expiryDate}
                        name="expiryDate"
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Group controlId={`missionAddress_${docIndex}`}>
                      <Form.Label>Nearest Indian Mission Address*</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={2}
                        onChange={(e) => handleInputChange(e, docIndex)}
                        value={data.missionAddress}
                        name="missionAddress"
                        maxLength={180}
                        required
                        placeholder="Add Your Nearest Indian Mission Address"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </td>

              <td className="align-middle" style={{ width: "40%" }}>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="me-3">
                    <label
                      className={
                        data.passportCopy
                          ? "btn btn-success"
                          : "btn btn-primary"
                      }
                      htmlFor={`passportCopy_${docIndex}`}
                    >
                      {data.passportCopy ? "Uploaded" : "Upload Passport Copy"}
                    </label>
                    <input
                      style={{ display: "none" }}
                      type="file"
                      name="passportCopy"
                      id={`passportCopy_${docIndex}`}
                      accept=".pdf"
                      onChange={(e) =>
                        handleFileUpload(
                          "passportCopy",
                          e.target.files[0],
                          docIndex
                        )
                      }
                    />
                  </div>
                </div>
              </td>
            </tr>
          ))}
          <tr>
            <td>
              <div className="d-flex align-items-center">
                <h6>3</h6>
              </div>
            </td>
            <td
              className="fw-bold text-secondary align-middle text-start"
              style={{ width: "50%" }}
            >
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip>
                        Add the details as per your Company/Business
                        registration certificate.
                      </Tooltip>
                    }
                  >
                    <img src={InfoImg} style={{ height: "48px" }} />
                  </OverlayTrigger>
                  Company / Organization Profile*
                </div>
              </div>
            </td>
            <td className="align-middle" style={{ width: "50%" }}>
              <div className="d-flex justify-content-center align-items-center">
                <div className="me-3">
                  <label
                    className={
                      formData.companyProfile
                        ? "btn btn-success"
                        : "btn btn-primary"
                    }
                    htmlFor="companyProfile"
                  >
                    {formData.companyProfile
                      ? "Uploaded"
                      : "Upload Company Profile"}
                  </label>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    name="companyProfile"
                    id="companyProfile"
                    accept=".pdf"
                    onChange={(e) =>
                      handleFileUpload("companyProfile", e.target.files[0])
                    }
                  />
                </div>
                {formData.companyProfile && (
                  <a
                    href={URL.createObjectURL(formData.companyProfile)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BsEye className="icon-size" />
                  </a>
                )}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="d-flex align-items-center">
                <h6>4</h6>
              </div>
            </td>
            <td
              className="fw-bold text-secondary align-middle text-start"
              style={{ width: "50%" }}
            >
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip>
                        Add Details as per Collaboration Agreement
                      </Tooltip>
                    }
                  >
                    <img src={InfoImg} style={{ height: "48px" }} />
                  </OverlayTrigger>
                  Collaboration Agreement*
                </div>
              </div>
            </td>

            <td className="align-middle" style={{ width: "50%" }}>
              <div className="d-flex justify-content-center align-items-center">
                <div className="me-3">
                  <label
                    className={
                      formData.collaborationAgreement
                        ? "btn btn-success"
                        : "btn btn-primary"
                    }
                    htmlFor="collaborationAgreement"
                  >
                    {formData.collaborationAgreement
                      ? "Uploaded"
                      : "Upload Collaboration Agreement"}
                  </label>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    name="collaborationAgreement"
                    id="collaborationAgreement"
                    accept=".pdf"
                    onChange={(e) =>
                      handleFileUpload(
                        "collaborationAgreement",
                        e.target.files[0]
                      )
                    }
                  />
                </div>
                {formData.collaborationAgreement && (
                  <a
                    href={URL.createObjectURL(formData.collaborationAgreement)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BsEye className="icon-size" />
                  </a>
                )}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="d-flex align-items-center">
                <h6>5</h6>
              </div>
            </td>
            <td
              className="fw-bold text-secondary align-middle text-start"
              style={{ width: "50%" }}
            >
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip>
                        Enclose your Company or firm registration certificate.
                      </Tooltip>
                    }
                  >
                    <img src={InfoImg} style={{ height: "48px" }} />
                  </OverlayTrigger>
                  Commercial business License copy*
                </div>
              </div>
            </td>

            <td className="align-middle" style={{ width: "50%" }}>
              <div className="d-flex justify-content-center align-items-center">
                <div className="me-3">
                  <label
                    className={
                      formData.businessLicense
                        ? "btn btn-success"
                        : "btn btn-primary"
                    }
                    htmlFor="businessLicense"
                  >
                    {formData.businessLicense ? "Uploaded" : "Upload File"}
                  </label>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    name="businessLicense"
                    id="businessLicense"
                    accept=".pdf"
                    onChange={(e) =>
                      handleFileUpload("businessLicense", e.target.files[0])
                    }
                  />
                </div>
                {formData.businessLicense && (
                  <a
                    href={URL.createObjectURL(formData.businessLicense)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BsEye className="icon-size" />
                  </a>
                )}
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <div className="d-flex">
                <div className="w-50 border-end">
                  <h6>DELEGATION FEE:</h6>
                  {formData.passports.length > 0 && (
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center w-50">
                        <Form.Label
                          htmlFor="inputPassword5"
                          className="ms-2 h6 text-secondary"
                        >
                          Delegate 1
                        </Form.Label>
                      </div>
                      <div className="w-50 align-items-center mb-1 fw-semibold">
                        | USD {formData.amount}
                      </div>
                    </div>
                  )}
                  {formData.passports.length > 1 && (
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center w-50">
                        <Form.Label
                          htmlFor="inputPassword5"
                          className="ms-2 h6 text-secondary"
                        >
                          Delegate 2
                        </Form.Label>
                      </div>
                      <div className="w-50 align-items-center mb-1 fw-semibold">
                        | USD {formData.amount}
                      </div>
                    </div>
                  )}
                </div>
                <div className="w-50 ms-2">
                  <h6>Member:</h6>
                  <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center w-50">
                      <Form.Check
                        type="radio"
                        id="member"
                        name="isMember"
                        value="Member"
                        checked={formData.isMember === "Member"}
                        onChange={() => handleRadioChange("Member")}
                        defaultChecked
                      />
                      <Form.Label
                        htmlFor="member"
                        className="ms-2 h6 text-secondary"
                      >
                        Member
                      </Form.Label>
                    </div>
                    <div className="w-50 align-items-center mb-1 fw-semibold">
                      | USD 100 Per Delegate
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center w-50">
                      <Form.Check
                        type="radio"
                        id="nonMember"
                        name="isMember"
                        value="Non Member"
                        checked={formData.isMember === "Non Member"}
                        onChange={() => handleRadioChange("Non Member")}
                      />
                      <Form.Label
                        htmlFor="nonMember"
                        className="ms-2 h6 text-secondary"
                      >
                        Non Member
                      </Form.Label>
                    </div>
                    <div className="w-50 align-items-center mb-1 fw-semibold">
                      | USD 250 Per Delegate
                    </div>
                  </div>
                  <span className="text-info">
                    Registration fees are to be paid in INR, upon arrival in
                    India and a day prior to the Event.
                  </span>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <div className="d-flex align-items-center w-100 justify-content-center">
                <h6>
                  <span className="text-secondary">Total Amount</span> | USD{" "}
                  {formData.passports.length * parseFloat(formData.amount)}
                </h6>
              </div>
            </td>
          </tr>

          <tr>
            <td colSpan={3}>
              <div className="d-flex justify-content-center gap-5">
                <Button variant="secondary" type="button" disabled>
                  Reset
                </Button>
                <Button
                  variant="success"
                  type="submit"
                  onClick={handleRegisterFormSubmit}
                >
                  SUBMIT
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default B2bConnect;

const pdfLinks = {
  DelegateRegistrationForm:
    "https://aecci-bucket-new.s3.amazonaws.com/Visa+Recommendation+Letter/TemplateRecommLetters/Delegate_registration_form.pdf",
};
