import React, { useState, useEffect } from "react";
import Layout from "../../components/Layouts/Layouts/Layout";
import Layout2 from "../../components/Layouts/Layouts/Layout2";
import DashboardHeader from "../../components/basic/DashboardHeader";
import { toast } from "react-hot-toast";
import { getURLbyEndPoint } from "../../store/api";
import axios from "axios";
import { getAuthUserlocal } from "../../store/services";
import { useAuth } from "../../context/auth";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import infoImg from "../../assets/infoimg.png";

const CompanyPages = () => {
  const authUserLocal = getAuthUserlocal();
  const userId = authUserLocal ? authUserLocal._id : null;

  const [auth, setAuth] = useAuth();

  const [cdFormData, setCDFormData] = useState({
    address1: "",
    address2: "",
    city: "",
    country: "",
    state: "",
    pinCode: "",
    registeredBank: "",
    registeredAccountNo: "",
    IFSCCode: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCDFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleCompanyFormSubmit = async (e) => {
    e.preventDefault();

    if (!cdFormData.companyName) {
      toast.error("Company Name is required.");
      return;
    }

    if (!cdFormData.numberOfEmployees) {
      toast.error("Number of Employees is required.");
      return;
    }

    if (!cdFormData.businessCategory) {
      toast.error("Business Category is required.");
      return;
    }

    if (!cdFormData.howDidYouKnowAboutUs) {
      toast.error("How did you know about us? is required.");
      return;
    }

    if (!cdFormData.telephoneNo) {
      toast.error("Company Telephone is required.");
      return;
    }

    if (!cdFormData.email) {
      toast.error("Company Email is required.");
      return;
    }

    if (!cdFormData.websiteAdd) {
      toast.error("Company Website is required.");
      return;
    }

    // Additional validation for pinCode (6 digits)
    if (cdFormData.pinCode && !/^\d{6}$/.test(cdFormData.pinCode)) {
      toast.error("Invalid Pin Code. It should be 6 digits.");
      return;
    }

    const websiteRegex =
      /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}([/?#].*)?$/;
    if (!websiteRegex.test(cdFormData.websiteAdd)) {
      toast.error("Invalid Website Address. Please enter a valid URL.");
      return;
    }

    try {
      cdFormData.pinCode = parseInt(cdFormData.pinCode);
      cdFormData.numberOfEmployees = parseInt(cdFormData.numberOfEmployees);

      const res = await axios.put(
        getURLbyEndPoint("updateCompanyDetails") + userId,
        {
          ...cdFormData,
        }
      );
      toast.success(res.data.message, { autoClose: 20000 });
    } catch (e) {
      console.log("error is here", e);
      if (e.response && e.response.data && e.response.data.message) {
        toast.error(e.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const businessCategories = [
    "Professional",
    "Proprietor",
    "Partner",
    "LLP",
    "Public Ltd",
    "Bank",
    "Private Limited",
    "Education Institute",
    "Others",
  ];
  //social media setting all data--------------------------
  // ------------------------------------------------------
  // -------------------------------------------------------
  // -------------------------------------------------------
  const [socialMedia, setSocialMedia] = useState({
    facebook: "",
    linkedIn: "",
    twitter: "",
  });
  const socialMediaRegex = {
    facebook: /https:\/\/www\.facebook\.com\/[a-zA-Z0-9.-]+/,
    linkedIn: /https:\/\/www\.linkedin\.com\/(in|company)\/[a-zA-Z0-9-]+/,
    twitter: /https:\/\/twitter\.com\/[a-zA-Z0-9_]+/,
    // add other fields and corresponding regex patterns...
  };
  const handleSocialMediaInput = (e) => {
    const { name, value } = e.target;
    setSocialMedia((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const validateSocialMedia = () => {
    for (const [key, value] of Object.entries(socialMedia)) {
      if (value && !socialMediaRegex[key].test(value)) {
        alert(`Invalid ${key.toUpperCase()} URL`);
        return false;
      }
    }
    return true;
  };
  const handleSocialMediaSubmit = async (e) => {
    e.preventDefault();

    if (!validateSocialMedia()) {
      return;
    }
    try {
      const res = await axios.post(
        getURLbyEndPoint("updateSocialMedia") + userId,
        {
          ...socialMedia,
        }
      );

      if (res.data.status) {
        alert(res.data.message);
      } else {
        alert("Something went wrong!");
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong!");
    }
  };

  // const subCategories = [
  //   "Exporter",
  //   "CHA",
  //   "Manufacturer",
  //   "Freight forwarders",
  //   "Shipping and Logistics",
  //   "Doctor",
  //   "Consultant",
  //   "Lawyer",
  //   "Accountant",
  //   "Architect",
  //   "Engineer",
  //   "Sole Proprietorship",
  //   "Small Business Owner",
  //   "Pharmaceutical",
  //   "Independent Contractor",
  //   "General Partner",
  //   "Limited Partner",
  //   "Services",
  //   "Retail",
  //   "University",
  //   "Training Institute",
  //   "Finance",
  //   "Healthcare",
  //   "Consumer Goods",
  //   "Others",
  // ];
  useEffect(() => {
    // Make the GET API call here
    const fetchData = async () => {
      try {
        const res = await axios.get(
          getURLbyEndPoint("getCompanyDetails") + userId,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        const data = res.data.data;

        if (res.data.status) {
          setCDFormData(data);
          setSocialMedia({
            facebook: data.facebook || "",
            linkedIn: data.linkedIn || "",
            twitter: data.twitter || "",
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        alert("something went Wrong!!");
      }
    };

    fetchData();
  }, [auth.token]);
  return (
    <>
      <Layout>
        <Layout2>
          <DashboardHeader />
          <div className="d-flex flex-column dashboardBlock">
            <div>
              <div className="d-flex align-items-center">
                {" "}
                <h4 className="py-4">COMPANY DETAILS</h4>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>
                      The inclusion of correct information for the Company Name,
                      along with other details of the company, is kindly
                      requested herein.
                    </Tooltip>
                  }
                >
                  <img
                    src={infoImg}
                    style={{ height: "6vh" }}
                    alt=""
                    srcset=""
                  />
                </OverlayTrigger>
              </div>
              <hr className="mt-1 mb-4" />

              <div action="" className="">
                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">COMPANY NAME</label>
                  <input
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    value={cdFormData.companyName}
                    name="companyName"
                    type="text"
                    readOnly
                    required
                  />
                </div>
                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">NO. OF EMPLOYEES</label>
                  <input
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    value={cdFormData.numberOfEmployees}
                    name="numberOfEmployees"
                    type="number"
                    readOnly
                  />
                </div>
                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">BUSINESS CATEGORY</label>
                  <input
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    value={cdFormData.businessCategory}
                    name="businessCategory"
                    type="text"
                    readOnly
                  />
                </div>
                {/* <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">SUB CATEGORY</label>
                  <input
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    value={cdFormData.subCategory}
                    name="subCategory"
                    type="text"
                    readOnly
                  />
                </div> */}
                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6 w-25">
                    WHERE DID YOU HERE ABOUT US?
                  </label>
                  <input
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    value={cdFormData.howDidYouKnowAboutUs}
                    name="howDidYouKnowAboutUs"
                    type="text"
                    required
                    disabled
                  />
                </div>

                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">TELEPHONE NO.</label>
                  <input
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    value={cdFormData.telephoneNo}
                    name="telephoneNo"
                    type="number"
                    readOnly
                    pattern="[0-9]{1,15}"
                  />
                </div>
                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">COMPANY E-MAIL</label>
                  <input
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    value={cdFormData.email}
                    name="email"
                    type="email"
                    readOnly
                  />
                </div>
                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">WEBSITE</label>
                  <input
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    value={cdFormData.websiteAdd}
                    name="websiteAdd"
                    type="text"
                    readOnly
                  />
                </div>
              </div>
            </div>
            <form onSubmit={handleCompanyFormSubmit}>
              <div>
                <div className="d-flex align-items-center">
                  {" "}
                  <h4 className="py-4">COMPANY ADDRESS</h4>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip>
                        Registered addresses of the company according to GST
                        certificates must be mentioned here. If required any
                        amendments in future then the user must update the same
                        at Chamber desk.
                      </Tooltip>
                    }
                  >
                    <img
                      src={infoImg}
                      style={{ height: "6vh" }}
                      alt=""
                      srcset=""
                    />
                  </OverlayTrigger>
                </div>
                <hr className="mt-1 mb-4" />

                <div className="mx-2">
                  <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                    <label className="fw-semibold fs-6">ADDRESS 1</label>
                    <input
                      style={{ width: "60%" }}
                      className="form-control rounded-0 ms-5"
                      onChange={handleInputChange}
                      value={cdFormData.address1}
                      name="address1"
                      type="text"
                      required
                    />
                  </div>
                  <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                    <label className="fw-semibold fs-6">ADDRESS 2</label>
                    <input
                      style={{ width: "60%" }}
                      className="form-control rounded-0 ms-5"
                      onChange={handleInputChange}
                      value={cdFormData.address2}
                      name="address2"
                      type="text"
                      required
                    />
                  </div>
                  <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                    <label className="fw-semibold fs-6">CITY</label>
                    <input
                      style={{ width: "60%" }}
                      className="form-control rounded-0 ms-5"
                      onChange={handleInputChange}
                      value={cdFormData.city}
                      name="city"
                      type="text"
                      required
                    />
                  </div>

                  <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                    <label className="fw-semibold fs-6">COUNTRY</label>
                    <input
                      style={{ width: "60%" }}
                      className="form-control rounded-0 ms-5"
                      onChange={handleInputChange}
                      value={cdFormData.country}
                      name="country"
                      type="text"
                      required
                    />
                  </div>
                  <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                    <label className="fw-semibold fs-6">STATE</label>
                    <input
                      style={{ width: "60%" }}
                      className="form-control rounded-0 ms-5"
                      onChange={handleInputChange}
                      value={cdFormData.state}
                      name="state"
                      type="text"
                      required
                    />
                  </div>
                  <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                    <label className="fw-semibold fs-6">PINCODE</label>
                    <input
                      style={{ width: "60%" }}
                      className="form-control rounded-0 ms-5"
                      onChange={handleInputChange}
                      value={cdFormData.pinCode}
                      name="pinCode"
                      type="number"
                      required
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex align-items-center">
                  {" "}
                  <h4 className="py-4">BANK DETAILS</h4>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip>
                        Registered addresses of the company according to GST
                        certificates must be mentioned here. If required any
                        amendments in future then the user must update the same
                        at Chamber desk.
                      </Tooltip>
                    }
                  >
                    <img
                      src={infoImg}
                      style={{ height: "6vh" }}
                      alt=""
                      srcset=""
                    />
                  </OverlayTrigger>
                </div>
                <hr className="mt-1 mb-4" />
                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">BANK NAME</label>
                  <input
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={cdFormData.registeredBank}
                    name="registeredBank"
                    type="text"
                    required
                  />
                </div>
                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">ACCOUNT NO.</label>
                  <input
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={cdFormData.registeredAccountNo}
                    name="registeredAccountNo"
                    type="text"
                    required
                  />
                </div>
                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">IFSC CODE</label>
                  <input
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={cdFormData.IFSCCode}
                    name="IFSCCode"
                    type="text"
                    required
                  />
                </div>
                <div className=" d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-primary mt-4 p-2 w-25"
                    style={{ backgroundColor: "#0b3b5d", border: "0" }}
                  >
                    UPDATE
                  </button>
                </div>
              </div>
            </form>
            <form onSubmit={handleSocialMediaSubmit}>
              <div>
                <h4 className="py-4">SOCIAL MEDIA</h4>
              </div>
              <hr className="mt-1 mb-4" />

              <div className="mx-2">
                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">FACEBOOK</label>
                  <input
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleSocialMediaInput}
                    value={socialMedia.facebook}
                    placeholder="Enter Facebook URL (e.g., https://www.facebook.com/exampleusername)"
                    name="facebook"
                    type="text"
                    required
                  />
                </div>
                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">LINKED IN</label>
                  <input
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleSocialMediaInput}
                    value={socialMedia.linkedIn}
                    name="linkedIn"
                    type="text"
                    placeholder="Enter LinkedIn URL (e.g., https://www.linkedin.com/in/exampleprofile)"
                    required
                  />
                </div>
                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">TWITTER</label>
                  <input
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleSocialMediaInput}
                    value={socialMedia.twitter}
                    name="twitter"
                    type="text"
                    placeholder="Enter Twitter URL (e.g., https://twitter.com/examplehandle)"
                    required
                  />
                </div>
                <div className="d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-primary mt-4 p-2 w-25"
                    style={{ backgroundColor: "#0b3b5d", border: "0" }}
                  >
                    UPDATE
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Layout2>
      </Layout>
    </>
  );
};

export default CompanyPages;
