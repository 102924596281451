import React from 'react'
import Layout from '../../components/Layouts/Layouts/Layout'
import Layout2 from '../../components/Layouts/Layouts/Layout2'

const ComingSoon = () => {
  return (
   <Layout>
    <Layout2>
    <h4>
        COMING SOON.....
    </h4>
    </Layout2>
   </Layout>
  )
}

export default ComingSoon