import React, { useState, useEffect } from "react";
import Layout from "../../components/Layouts/Layouts/Layout";
import Layout2 from "../../components/Layouts/Layouts/Layout2";
import DashboardHeader from "../../components/basic/DashboardHeader";
import { RiBankFill } from "react-icons/ri";
import Badge from "react-bootstrap/Badge";
import { BiSolidEdit } from "react-icons/bi";
import Table from "react-bootstrap/Table";
import { FaInfoCircle } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router";
import { BsEye } from "react-icons/bs";
import { getAuthUserlocal } from "../../store/services";
import axios from "axios";
import { getURLbyEndPoint } from "../../store/api";
import { useAuth } from "../../context/auth";
import Pagination from "react-bootstrap/Pagination";
import { BsFileEarmarkExcelFill } from "react-icons/bs";
import { BiSolidBank } from "react-icons/bi";
import { RiRefreshLine } from "react-icons/ri";
import * as XLSX from "xlsx";
import { Dropdown } from "react-bootstrap";

const Wallet = () => {
  const navigate = useNavigate();
  const [addAmount, setAddAmount] = useState(false);
  const [walletHistory, setWalletHistory] = useState("");
  const [auth, setAuth] = useAuth();
  const userId = auth ? auth.user : null;

  const [formFlag, setFormFlag] = useState(false);
  const [formData, setFormData] = useState({
    utrNo: "",
    amount: 0,
    paymentScreenShot: null,
  });
  const [errors, setErrors] = useState({
    bankName: "",
    ifscCode: "",
    name: "",
    accountNumber: "",
  });

  const ifscCodeRegex = /^[A-Za-z]{4}\d{7}$/;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "", // Reset error when user types
    });
  };

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleFileUpload = (field, file) => {
    console.log(file);
    if (file.size <= 3 * 1024 * 1024) {
      setFormData((prevData) => ({
        ...prevData,
        [field]: file,
      }));
    } else {
      console.error("File size exceeds 3MB");
      alert("File size exceeds 3MB");
    }
  };

  const handleAddFund = async (e) => {
    e.preventDefault();
    setFormFlag(true);

    const validationRulesProceedPayment = {
      utrNo: {
        message: "Please Enter UTR Number.",
        isValid: () => formData.utrNo.trim() !== "",
      },
      Amount: {
        message: "Please Enter Amount.",
        isValid: () =>
          formData.amount !== 0 && formData.amount.toString()[0] !== "0",
      },
      Amount: {
        message: "Please Enter Amount More Than 500.",
        isValid: () => formData.amount >= 500,
      },
      paymentScreenShot: {
        message: "Please Enter Payment Screenshot.",
        isValid: () => formData.paymentScreenShot !== null,
      },
      utrNo: {
        message: "Please Enter a Valid UTR Number.",
        isValid: () => /^[A-Za-z0-9]{1,22}$/.test(formData.utrNo.trim()),
      },
    };

    for (const field in validationRulesProceedPayment) {
      const { message, isValid } = validationRulesProceedPayment[field];
      if (!isValid()) {
        alert(message);
        setFormFlag(false);
        return;
      }
    }

    // const userId = getAuthUserlocal()._id;

    //making form
    const apiFormData = new FormData();
    apiFormData.append("utrNo", formData.utrNo);
    apiFormData.append("amount", formData.amount);
    apiFormData.append("paymentScreenShot", formData.paymentScreenShot);

    try {
      const res = await axios.post(
        getURLbyEndPoint("addFundToWallet") + userId,
        apiFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.data.status) {
        alert(res.data.message);
        setFormData({
          utrNo: "",
          amount: "",
          paymentScreenShot: null,
        });
        setAddAmount(false);
        setFormFlag(false);

        window.location.reload();
      } else {
        alert("Something went Wrong!");
      }
    } catch (error) {
      alert("Server is not responding!!");
      if (error.response) {
        console.error("Response error:", error.response.data);
        console.error("Status code:", error.response.status);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Request setup error:", error.message);
      }
    } finally {
      setFormFlag(false);
    }
  };

  function storeAuthUserlocal(userDatalocal) {
    localStorage.setItem("authUser", JSON.stringify(userDatalocal));
  }

  const [isClicked, setIsClicked] = useState(false);

  const handleRefreshBalace = async (userId) => {
    try {
      const userId = getAuthUserlocal()._id;
      if (!userId) {
        alert("user not defind");
        return;
      }

      const res = await axios.get(getURLbyEndPoint("refreshBal") + userId);

      if (res.data.status) {
        const userDetails = getAuthUserlocal();
        userDetails.balance = res.data.balance;
        storeAuthUserlocal(userDetails);
      }
      alert(res.data.message);
    } catch (error) {
      alert("Server is not responding!!");
    } finally {
      setIsClicked(false);
    }
  };

  const none = () => {
    // Validation logic
    const newErrors = {};
    if (!formData.bankName) {
      newErrors.bankName = "Please select a bank";
    }
    if (!formData.ifscCode) {
      newErrors.ifscCode = "IFSC Code is required";
    } else if (!ifscCodeRegex.test(formData.ifscCode)) {
      newErrors.ifscCode = "Invalid IFSC Code format";
    }
    if (!formData.name) {
      newErrors.name = "Name is required";
    }
    if (!formData.accountNumber) {
      newErrors.accountNumber = "Account Number is required";
    }

    if (Object.keys(newErrors).length === 0) {
      // Proceed with your logic if there are no errors
      console.log("Form Data:", formData);
    } else {
      // Display validation errors
      setErrors(newErrors);
    }
  };

  //making excel file
  const exportToExcel = () => {
    const dataForExcel = walletHistory.map((item, index) => ({
      "SR. NO": index + 1,
      Date: formatDate(item.createdAt),
      Particulars: item.Particular,
      Amount: item.amount,
      "Approved Date": formatDateAndTime(item.updatedAt),
    }));

    const ws = XLSX.utils.json_to_sheet(dataForExcel);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Wallet History");

    // Save the file
    XLSX.writeFile(wb, "wallet_history.xlsx");
  };

  useEffect(() => {
    // Make the GET API call here
    const fetchData = async () => {
      try {
        const res = await axios.get(
          getURLbyEndPoint("getWalletHistory") + userId,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        const data = res.data.data;

        if (res.data.status) {
          setWalletHistory(data);
        }

        console.log(walletHistory);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [auth.token]);

  //pagination functions------------------
  // ----------------------------------------
  // ----------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentWalletHistory = walletHistory.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    setCurrentPage(1); // Reset to the first page
  };

  const renderPaginationItems = () => {
    const totalItems = walletHistory.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const maxVisiblePages = 5;

    let startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
    let endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

    if (endPage - startPage < maxVisiblePages - 1) {
      startPage = Math.max(endPage - maxVisiblePages + 1, 1);
    }

    const pageItems = [];

    if (startPage > 1) {
      pageItems.push(
        <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
          {1}
        </Pagination.Item>
      );
      if (startPage > 2) {
        pageItems.push(<Pagination.Ellipsis key="startEllipsis" />);
      }
    }

    for (let pageNumber = startPage; pageNumber <= endPage; pageNumber++) {
      pageItems.push(
        <Pagination.Item
          key={pageNumber}
          active={pageNumber === currentPage}
          onClick={() => handlePageChange(pageNumber)}
        >
          {pageNumber}
        </Pagination.Item>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageItems.push(<Pagination.Ellipsis key="endEllipsis" />);
      }
      pageItems.push(
        <Pagination.Item
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    return pageItems;
  };

  return (
    <Layout>
      <Layout2>
        <DashboardHeader />
        <div className="d-flex flex-column dashboardBlock">
          <div>
            <h4 className="mt-3">Wallet</h4>
            <hr className="mb-4" />
            <div className="card">
              <div className="card-header">
                <div className="d-flex mb-4">
                  <div
                    style={{ height: "5vh", width: "auto" }}
                    className="me-3"
                  >
                    <RiBankFill className="h-100 w-100" />
                  </div>
                  <div>
                    <h6 className="mb-1">
                      Your Registerd Bank Account Details
                    </h6>
                    <span>Please ensure the bank details are accurate</span>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <h4 className="me-3 ">
                    <Badge bg="info" className="px-3">
                      Verified
                    </Badge>
                  </h4>
                  <BiSolidEdit
                    className="cursor-pointer"
                    onClick={() => navigate("/company-details")}
                    style={{ height: "3vh", width: "auto" }}
                  />
                </div>

                {/* edit button modal--------------------------
                -------------------------------------------
                ------------------------------------------
                ------------------------------------------- */}

                <div className="w-100 d-flex">
                  <div className="w-50">
                    <h5>
                      Bank Name :{" "}
                      <strong>{getAuthUserlocal().registeredBank}</strong>
                    </h5>
                    <h5>
                      Name : <strong>{getAuthUserlocal().companyName}</strong>
                    </h5>
                  </div>
                  <div className="w-50">
                    <h5>
                      IFSC Code : <strong>{getAuthUserlocal().IFSCCode}</strong>
                    </h5>
                    <h5>
                      Account No. :{" "}
                      <strong>{getAuthUserlocal().registeredAccountNo}</strong>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {addAmount ? (
                  <Form className="mb-3" onSubmit={handleAddFund}>
                    <div className="mb-3 d-flex justify-content-between">
                      <h5>Add Amount</h5>
                      <a href="#" onClick={() => setAddAmount(false)}>
                        Show History
                      </a>
                    </div>{" "}
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h6>UTR NO.</h6>
                      <Form.Control
                        className="w-75"
                        type="text"
                        placeholder="Enter Utr No."
                        required
                        value={formData.utrNo}
                        onChange={(e) =>
                          handleInputChange("utrNo", e.target.value)
                        }
                        maxLength={25}
                      />
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h6>AMOUNT</h6>
                      <Form.Control
                        className="w-75"
                        type="text"
                        maxLength={6}
                        placeholder="Enter Amount"
                        required
                        value={formData.amount}
                        onChange={(e) => {
                          const inputValue = e.target.value;

                          // Check if the input is a number or an empty string
                          if (/^\d*$/.test(inputValue) || inputValue === "") {
                            // Check if the first digit is not zero
                            if (
                              inputValue.length === 0 ||
                              (inputValue.length === 1 && inputValue !== "0")
                            ) {
                              handleInputChange("amount", inputValue);
                            } else {
                              // Remove leading zeros
                              handleInputChange(
                                "amount",
                                inputValue.replace(/^0+/, "")
                              );
                            }
                          }
                        }}
                      />
                    </div>
                    <div className="d-flex align-items-center mb-3">
                      <h6 className="me-3">PAYMENT ScreenShot (MAX 3MB)</h6>
                      <div className="me-3">
                        <label
                          className={
                            formData.paymentScreenShot
                              ? "btn btn-success"
                              : "btn btn-primary"
                          }
                          htmlFor="paymentScreenShot"
                        >
                          {formData.paymentScreenShot
                            ? "Uploaded"
                            : "Choose File"}
                        </label>
                        <input
                          style={{
                            display: "none",
                          }}
                          type="file"
                          accept="image/*"
                          name="paymentScreenShot"
                          id="paymentScreenShot"
                          onChange={(e) =>
                            handleFileUpload(
                              "paymentScreenShot",
                              e.target.files[0]
                            )
                          }
                        />
                      </div>
                      {formData.paymentScreenShot && (
                        <a
                          href={URL.createObjectURL(formData.paymentScreenShot)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <BsEye className="icon-size" />
                        </a>
                      )}
                    </div>
                    <div className="text-center">
                      <Button
                        variant="success"
                        className="px-5"
                        type="submit"
                        disabled={formFlag}
                      >
                        Proceed Payment
                      </Button>
                    </div>
                  </Form>
                ) : (
                  <>
                    <div className="d-flex justify-content-between mb-3">
                      <div className="d-flex align-items-center">
                        <h5 className="mb-0 mr-2">
                          Balance:{" "}
                          <strong>₹ {getAuthUserlocal().balance}.00</strong>
                        </h5>
                        <RiRefreshLine
                          className={`icon-size cursor-pointer text-secondary ${
                            isClicked ? "rotate-continuous" : ""
                          }`}
                          onClick={() => {
                            setIsClicked(true);
                            handleRefreshBalace();
                          }}
                        />
                      </div>
                      <div className="d-flex gap-2">
                        <div>
                          <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic">
                              Items per page: {itemsPerPage}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {[5, 10, 20, 50].map((value) => (
                                <Dropdown.Item
                                  key={value}
                                  onClick={() =>
                                    handleItemsPerPageChange(value)
                                  }
                                >
                                  {value}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <Button
                          variant="info"
                          onClick={() => setAddAmount(true)}
                        >
                          {" "}
                          <b>+ Add Balance</b>
                        </Button>{" "}
                      </div>
                    </div>
                    <Table striped bordered className="text-center mb-4">
                      <thead>
                        <tr>
                          <th>SR. NO</th>
                          <th>Date</th>
                          <th>Particulars</th>
                          <th>Amount</th>
                          <th>Approved Date</th>
                        </tr>
                      </thead>
                      {walletHistory.length > 0 ? (
                        <tbody>
                          {currentWalletHistory.map(
                            (transaction, relativeIndex) => (
                              <tr key={indexOfFirstItem + relativeIndex}>
                                <td className="align-middle">
                                  {" "}
                                  {indexOfFirstItem + relativeIndex + 1}.
                                </td>
                                <td className="align-middle">
                                  {formatDate(transaction.createdAt)}
                                </td>
                                <td className="align-middle">
                                  <div>{transaction.Particular}</div>
                                  {transaction.remark && (
                                    <div>
                                      {transaction.remark
                                        .split("\n")
                                        .map((line, index) => (
                                          <span
                                            key={index}
                                            className="text-secondary"
                                          >
                                            {line}
                                            <br />
                                          </span>
                                        ))}
                                    </div>
                                  )}
                                </td>
                                <td
                                  className={`align-middle ${
                                    transaction.paymentType === "credit"
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                >
                                  <b>{`${
                                    transaction.paymentType === "credit"
                                      ? "+"
                                      : "-"
                                  }${transaction.amount}`}</b>
                                </td>

                                <td className="align-middle">
                                  <h6
                                    className={
                                      transaction.status === "Rejected"
                                        ? "text-danger"
                                        : "text-success"
                                    }
                                  >
                                    {transaction.status}
                                  </h6>

                                  {transaction.status !== "Processing" && (
                                    <div className="fw-bold text-center">
                                      <span
                                        className={
                                          transaction.status === "Rejected"
                                            ? "text-danger"
                                            : "text-success"
                                        }
                                      >
                                        {formatDateAndTime(
                                          transaction.updatedAt
                                        )}
                                      </span>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan="5" className="text-center">
                              No data available
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </Table>

                    {/* Pagination component */}
                    <div className="d-flex justify-content-between align-items-center">
                      <Pagination>
                        <Pagination.Prev
                          onClick={() => handlePageChange(currentPage - 1)}
                          disabled={currentPage === 1}
                        />
                        {renderPaginationItems()}
                        <Pagination.Next
                          onClick={() => handlePageChange(currentPage + 1)}
                          disabled={
                            currentPage ===
                            Math.ceil(walletHistory.length / itemsPerPage)
                          }
                        />
                      </Pagination>
                      <BsFileEarmarkExcelFill
                        className="icon-size text-success"
                        onClick={exportToExcel}
                      />
                    </div>
                  </>
                )}

                <div>
                  <div className="d-flex align-items-center mb-2">
                    <FaInfoCircle className="icon-size me-2" />
                    <h5>WALLET Informative notes:</h5>
                  </div>
                  <ul>
                    <li>
                      Payments should be initiated only from the company&#39;s
                      registered &amp; authorized bank account.{" "}
                    </li>
                    <li>A wallet balance of at least Rs. 3000 is necessary.</li>
                    <li>
                      Access to services will be suspended if the wallet balance
                      falls below Rs. 600 until the minimum amount is deposited.
                    </li>
                    <li>
                      Amounts will be deducted based on the services used, and
                      you have the flexibility to download the ledger as needed.
                    </li>

                    <li>
                      Please provide the correct screenshot of the payment proof
                      upon making the payment.
                    </li>
                    <li>
                      Include the appropriate payment UTR No. in the transaction
                      details.
                    </li>
                    <li>
                      The beneficiary Account displayed on this platform is
                      unique to you only and is not be shared with anyone else.
                    </li>
                    <li>
                      For any additional assistance, contact Chamber desk.
                    </li>
                  </ul>{" "}
                  <div className="container">
                    <h4>
                      <BiSolidBank /> Chamber Account Details
                    </h4>
                    <ul>
                      <li>
                        Account Name: Asian Exporters Chamber of Comm and Ind
                      </li>
                      <li>Bank Name: YES Bank</li>
                      <li>A/C No: 02168 87000 00780 ( Current Account)</li>
                      <li>Branch Name: CBD Belapur</li>
                      <li>IFSC Code: YESB0000216</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout2>
    </Layout>
  );
};

export default Wallet;

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const formattedDate =
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "/" +
    date.getDate().toString().padStart(2, "0") +
    "/" +
    date.getFullYear();

  return formattedDate;
};

const formatDateAndTime = (dateString) => {
  if (!dateString) return "";

  const date = new Date(dateString);
  const formattedDate = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }).format(date);

  return formattedDate;
};
