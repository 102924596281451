import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import Layout2 from "../../../components/Layouts/Layouts/Layout2";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import InfoImg from "../../../assets/infoimg.png";
import { BsEye } from "react-icons/bs";
import { MdModeEditOutline } from "react-icons/md";
import infoImg from "../../../assets/infoimg.png";
import {
  Table,
  Form,
  Modal,
  Button,
  Card,
  Badge,
  OverlayTrigger,
  FloatingLabel,
  Tooltip,
  Pagination,
  Spinner,
} from "react-bootstrap";
import { BiSolidFilePdf } from "react-icons/bi";
import { FaInfoCircle } from "react-icons/fa";
import { MdTouchApp } from "react-icons/md";
import { getAuthUserlocal } from "../../../store/services";
import axios from "axios";
import { getURLbyEndPoint } from "../../../store/api";
import { countriesAsRegion, titleOptions } from "../../../store/AllListData";
import { IoMdDownload } from "react-icons/io";
import FileImg from "../../../assets/File.jpg";
import { FcDocument } from "react-icons/fc";

import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiFillCheckSquare,
  AiFillCloseSquare,
} from "react-icons/ai";
import { RiBankFill } from "react-icons/ri";

import { states_list } from "../../../pages/auth/Register/AllRegiPageLists";
import { PopUpModalButton } from "../../../components/basic/ModalComponent";

const RecommendationLetter = () => {
  const [selectedDesignation, setSelectedDesignation] = useState("");
  const [submitFlag, setSubmitFlag] = useState(false);
  const [totalAmt, setTotalAmt] = useState(0);
  const [formFlag, setFormFlag] = useState(false);
  const [tableFlag, setTableFlag] = useState(true);
  const [ApiRecomFlag, setApiRecomFlag] = useState(false);

  const [formData, setFormData] = useState({
    region: "",
    country: "",
    addressTo: "",
    fullAddress: "",
    designation: "",
    placeOfIssue: "",
    title: "",
    surname: "",
    givenName: "",
    passportNo: "",
    purposeOfVisit: "",
    dateOfIssue: "",
    dateOfExpiry: "",
    requestingLetter: null,
    invitationLetter: null,
    copyOfPassport: null,
    exportPerformance: null,
    comProfile: null,
    additionalDoc: null,
    refDoc: null,
    deliveryMode: "Digital Copy",
    amount: totalAmt,
  });

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleFileUpload = (field, file) => {
    if (file.type === "application/pdf" && file.size <= 3 * 1024 * 1024) {
      setFormData((prevData) => ({
        ...prevData,
        [field]: file,
      }));
    } else {
      console.error("File Should be Pdf and size exceeds 5MB");
      alert("File Should be Pdf and size exceeds 5MB");
    }
  };

  const handlePaymentScreenShot = (field, file) => {
    if (file.type.startsWith("image/") && file.size <= 3 * 1024 * 1024) {
      setFormData((prevData) => ({
        ...prevData,
        [field]: file,
      }));
    } else {
      console.error("File should be an image and size should be less than 3MB");
      alert("File should be an image and size should be less than 3MB");
    }
  };

  const handleCheckboxChange = (field) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: !prevFormData[field],
    }));
  };
  const handleRadioChange = (value) => {
    setFormData({
      ...formData,
      deliveryMode: value,
    });
  };

  //calculate total amount

  const regionAmounts = {
    Asian: 1000,
    Schengen: 2000,
    Others: 2000,
  };
  const calculateTotalAmount = () => {
    let total = regionAmounts[formData.region] || 0;

    // Add country-specific amount
    if (formData.deliveryMode === "Courier/Postal Charge") {
      total += 350;
    }

    return total;
  };

  //-------------------------------ADD NEW FORM----------------------
  //-------------------------------------------------------------------
  // --------------------------------------------------------------------

  //Proceed paymenent all functions are here
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleProceedPayment = () => {
    setSubmitFlag(true);
    handleClose();
  };

  //handle final submissin here
  const handleSubmit = async (e) => {
    setApiRecomFlag(true);

    e.preventDefault();

    const validationRules = {
      region: {
        message: "Please Enter Region.",
        isValid: () => formData.region.trim() !== "",
      },
      country: {
        message: "Please Enter Country.",
        isValid: () => formData.country.trim() !== "",
      },
      addressTo: {
        message: "Please Enter Address To.",
        isValid: () => formData.addressTo.trim() !== "",
      },
      fullAddress: {
        message: "Please Enter Full Address.",
        isValid: () => formData.fullAddress.trim() !== "",
      },
      designation: {
        message: "Please Enter Designation.",
        isValid: () => formData.designation.trim() !== "",
      },
      placeOfIssue: {
        message: "Please Enter Place of Issue.",
        isValid: () => formData.placeOfIssue.trim() !== "",
      },
      title: {
        message: "Please Enter Title.",
        isValid: () => formData.title.trim() !== "",
      },
      surname: {
        message: "Please Enter Surname.",
        isValid: () => formData.surname.trim() !== "",
      },
      givenName: {
        message: "Please Enter Given Name.",
        isValid: () => formData.givenName.trim() !== "",
      },
      passportNo: {
        message: "Please Enter a Valid Indian Passport Number.",
        isValid: () => {
          const indianPassportRegex = /^[A-Z]{1}[0-9]{7}$/;
          return indianPassportRegex.test(formData.passportNo.trim());
        },
      },
      purposeOfVisit: {
        message: "Please Enter Purpose of Visit.",
        isValid: () => formData.purposeOfVisit.trim() !== "",
      },
      dateOfIssue: {
        message: "Please Enter Date of Issue.",
        isValid: () => formData.dateOfIssue.trim() !== "",
      },
      dateOfExpiry: {
        message:
          "Please Enter a Valid Date of Expiry (at least 6 months in the future).",
        isValid: () => {
          const currentDate = new Date();
          const enteredDate = new Date(formData.dateOfExpiry);

          // Check if the entered date is at least 6 months in the future
          const sixMonthsFromNow = new Date();
          sixMonthsFromNow.setMonth(currentDate.getMonth() + 6);

          return enteredDate >= sixMonthsFromNow;
        },
      },
      requestingLetter: {
        message: "Please Upload Requesting Letter.",
        isValid: () => formData.requestingLetter !== null,
      },
      invitationLetter: {
        message: "Please Upload Invitation Letter.",
        isValid: () => formData.invitationLetter !== null,
      },
      copyOfPassport: {
        message: "Please Upload Copy of Passport.",
        isValid: () => formData.copyOfPassport !== null,
      },
      exportPerformance: {
        message: "Please Upload Export Performance.",
        isValid: () => formData.exportPerformance !== null,
      },
      comProfile: {
        message: "Please Upload Company Profile.",
        isValid: () => formData.comProfile !== null,
      },
      additionalDoc: {
        message: "Please Upload Additional Document.",
        isValid: () =>
          formData.country === "Saudi Arabia"
            ? formData.additionalDoc !== null
            : true,
      },
      refDoc: {
        message: "Please Upload Reference Document.",
        isValid: () =>
          formData.country === "Saudi Arabia" ? formData.refDoc !== null : true,
      },
      deliveryMode: {
        message: "Please Choose a Delivery Mode Option.",
        isValid: () =>
          formData.deliveryMode === "Digital Copy" ||
          formData.deliveryMode === "Collect From Chamber" ||
          formData.deliveryMode === "Courier/Postal Charge",
      },

      Amount: {
        message: "Please Enter Amount.",
        isValid: () => formData.amount !== 0,
      },
    };

    for (const field in validationRules) {
      const { message, isValid } = validationRules[field];
      if (!isValid()) {
        alert(message);
        return;
      }
    }

    try {
      const userId = getAuthUserlocal()._id;
      const formDataForApi = new FormData();

      // Append files to the FormData
      formDataForApi.append("requestingLetter", formData.requestingLetter);
      formDataForApi.append("invitationLetter", formData.invitationLetter);
      formDataForApi.append("copyOfPassport", formData.copyOfPassport);
      formDataForApi.append("exportPerformance", formData.exportPerformance);
      formDataForApi.append("comProfile", formData.comProfile);
      formDataForApi.append("additionalDoc", formData.additionalDoc);
      formDataForApi.append("refDoc", formData.refDoc);

      // Append other non-file fields
      for (const key in formData) {
        if (
          key !== "requestingLetter" &&
          key !== "invitationLetter" &&
          key !== "copyOfPassport" &&
          key !== "exportPerformance" &&
          key !== "comProfile" &&
          key !== "additionalDoc" &&
          key !== "refDoc"
        ) {
          formDataForApi.append(key, formData[key]);
        }
      }

      //validation for balance
      if (getAuthUserlocal().balance - totalAmt < 500) {
        alert("Low balance");
        return;
      }

      const res = await axios.post(
        getURLbyEndPoint("createRecommendationLetter") + userId,
        formDataForApi
      );

      if (res.data.status) {
        alert(res.data.message);
        setTableFlag(true);
        setFormFlag(false);
        setApiRecomFlag(false);

        window.location.reload();
      } else {
        alert("Failed to Request for visa recommendation");
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        alert(e.response.data.message);
      } else {
        alert("Server is not responding");
      }
    } finally {
      setApiRecomFlag(false);
    }
  };

  //function for getting data from database
  const [visaRecommendationLetterData, setVisaRecommendationLetterData] =
    useState([]);

  const fetchVisaRecommendationLetterData = async () => {
    try {
      const userId = getAuthUserlocal()._id;

      const response = await axios.get(
        getURLbyEndPoint("getVisaRecommendationData") + userId
      );
      if (response.status === 200) {
        const data = response.data;
        setVisaRecommendationLetterData([...data.data]);

        if (response.data.data.length === 0) {
          // Set flags accordingly when the array is empty
          setFormFlag(true);
          setTableFlag(false);
        }
      } else {
        console.error("Failed to fetch registered users data.");
        // alert("Failed to fetch registered users data.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // alert("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchVisaRecommendationLetterData();
  }, []);

  useEffect(() => {
    const totalAmt = calculateTotalAmount();

    setTotalAmt(totalAmt);

    // Update formData only if the amount has changed
    if (formData.amount !== totalAmt) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        amount: totalAmt,
      }));
    }
  }, [formData, setTotalAmt, setFormData]);

  //filtering here
  const [searchTerm, setSearchTerm] = useState("");

  const filteredData = visaRecommendationLetterData.filter((data) =>
    searchTerm !== ""
      ? data.documentNo &&
        data.documentNo.toLowerCase().includes(searchTerm.toLowerCase())
      : true
  );

  //pagination is here
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Set items per page to 5

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFiltered = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Calculate the range of pages to display
  const calculateRange = (currentPage, totalPages) => {
    let startPage = Math.max(1, currentPage - 2); // Adjusted to show 5 pages
    let endPage = Math.min(totalPages, currentPage + 2); // Adjusted to show 5 pages

    // Adjust if the range is too close to the beginning or end
    if (endPage - startPage < 4) {
      if (startPage === 1) {
        endPage = Math.min(5, totalPages); // Adjusted to show 5 pages
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 4, 1); // Adjusted to show 5 pages
      }
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = calculateRange(currentPage, totalPages);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    window.scrollTo(0, 100);
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    window.scrollTo(0, 100);
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  if (!Array.isArray(visaRecommendationLetterData)) {
    console.error("visaRecommendationLetterData is not an array");
    return null;
  }
  return (
    <>
      <Layout>
        <Layout2>
          <DashboardHeader />
          <div>
            {formFlag && (
              <Form className="d-flex flex-column" onSubmit={handleSubmit}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center mt-3">
                    {" "}
                    <h4 className="">VISA RECOMMENDATION LETTER</h4>
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip>
                          At the time of applying for recommendation letter make
                          sure all the details are filled in carefully.
                        </Tooltip>
                      }
                    >
                      <img
                        src={infoImg}
                        style={{ height: "6vh" }}
                        alt=""
                        srcset=""
                      />
                    </OverlayTrigger>
                  </div>

                  <div className="align-middle">
                    <a
                      href="#"
                      className="fs-4"
                      onClick={() => {
                        setFormFlag(false);
                        setTableFlag(true);
                      }}
                    >
                      {/* <img
                        src={FileImg}
                        height="100%"
                        width="100%"
                        className="icon-size img-fluid"
                        alt=""
                        srcset=""
                      />{" "} */}
                      <FcDocument className="icon-size" />
                      Track
                    </a>
                  </div>
                </div>
                <hr className="mt-2 mb-4" />
                <div className="ps-3 py-3 bg-body-secondary mb-2">
                  <h5>APPLICATION FOR RECOMMENDATION LETTER</h5>
                </div>
                <div className="d-flex justify-content-between w-100">
                  <div className="d-flex w-100">
                    <div className="d-flex w-50">
                      <Form.Label className="fw-semibold fs-6 w-50">
                        Region*
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        style={{ width: "45%" }}
                        value={formData.region}
                        required
                        onChange={(e) => {
                          handleInputChange("region", e.target.value);
                          handleInputChange("country", ""); // Reset country when region changes
                        }}
                      >
                        <option value="">Select Region</option>
                        {Object.keys(countriesAsRegion).map((region, index) => (
                          <option key={index} value={region}>
                            {region}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                    <div className="d-flex w-50 justify-content-end">
                      <Form.Label className="fw-semibold fs-6 w-50">
                        Country*
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        style={{ width: "45%" }}
                        value={formData.country}
                        required
                        onChange={(e) =>
                          handleInputChange("country", e.target.value)
                        }
                        disabled={!formData.region} // Disable when no region is selected
                      >
                        <option value="">Select Country</option>
                        {formData.region && countriesAsRegion[formData.region]
                          ? countriesAsRegion[formData.region].map(
                              (country, index) => (
                                <option key={index} value={country}>
                                  {country}
                                </option>
                              )
                            )
                          : null}
                      </Form.Select>
                    </div>
                  </div>
                  {/* <div className="mb-1 py-2 d-flex justify-content-between align-items-start w-25">
              <label className="fw-semibold fs-6 w-100">Region*</label>
            </div> */}
                  {/* <div className="mb-1 py-2 d-flex justify-content-between align-items-start gap-5 w-75">
              <input className="form-control rounded-0 w-25" type="text" />
              <label className="fw-semibold fs-6 w-25">Country*</label>
              <input className="form-control rounded-0 w-25" type="text" />
            </div> */}
                </div>
                <div className=" mb-1 py-2 d-flex justify-content-between align-items-start">
                  <Form.Label className="fw-semibold fs-6 w-25">
                    Addresses to
                  </Form.Label>

                  <Form.Select
                    aria-label="Default select example"
                    className="w-75"
                    required
                    value={formData.addressTo}
                    onChange={(e) =>
                      handleInputChange("addressTo", e.target.value)
                    }
                  >
                    <option value="">Select Address to</option>
                    <option value="High Comission">High Comission</option>
                    <option value="Embassy">Embassy</option>
                    <option value="Consulate">Consulate</option>
                  </Form.Select>
                </div>
                <div className="mb-1 d-flex justify-content-between align-items-start">
                  <Form.Label className="fw-semibold fs-6 w-25">
                    Full Address
                  </Form.Label>
                  <div className=" w-75">
                    <Form.Control
                      as="textarea"
                      required
                      type="text"
                      rows={4}
                      style={{ resize: "none" }}
                      placeholder={
                        formData.addressTo === "High Comission"
                          ? `High Commission of the Federal Republic of Nigeria,\nEP-4, Chandragupta Marg\nChanakyapuri\nNew Delhi - 110021`
                          : formData.addressTo === "Embassy"
                          ? `Embassy of Afghanistan\n5/50F, Shantipath\nChanakyapuri\nNew Delhi-110021`
                          : formData.addressTo === "Consulate"
                          ? `The Royal Consulate General of Saudi Arabia\n4th Floor, Maker Tower,\nF Wing, Cuffe Parade,\nMumbai, Maharashtra 400005`
                          : ``
                      }
                      value={formData.fullAddress}
                      onChange={(e) =>
                        handleInputChange("fullAddress", e.target.value)
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          const lines = e.target.value.split("\n");
                          if (lines.length >= 4) {
                            e.preventDefault();
                          }
                        }
                      }}
                    />
                    <span className="text-danger">
                      Note: Enter the Correct Location address of High
                      Commissions/ Embassy/ Consulate
                    </span>
                  </div>
                </div>

                <div className="ps-3 py-3 bg-body-secondary mb-2 mt-3">
                  <h5>DESIGNATION & PASSPORT DETAILS</h5>
                </div>
                <div className="d-flex w-100 mb-2">
                  <div className="d-flex w-50">
                    <Form.Label className="fw-semibold fs-6 w-50">
                      Designation*
                    </Form.Label>
                    <div style={{ width: "45%" }}>
                      <Form.Select
                        aria-label="Default select example"
                        required
                        value={formData.designation}
                        onChange={(e) =>
                          handleInputChange("designation", e.target.value)
                        }
                      >
                        <option value="">Select Designation</option>
                        <option value="proprietor">Proprietor</option>
                        <option value="Partner">Partner</option>
                        <option value="Director">Director</option>
                        <option value="Chairman">Chairman</option>
                        <option value="Managing Director">
                          Managing Director
                        </option>
                        <option value="Chief Executive Officer (CEO)">
                          Chief Executive Officer (CEO)
                        </option>
                        <option value="Chief Financial Officer (CFO)">
                          Chief Financial Officer (CFO)
                        </option>
                        <option value="Chief Human Resources Officer (CHRO)">
                          Chief Human Resources Officer (CHRO)
                        </option>
                        <option value="Secretary">Secretary</option>
                        <option value="Chief Operating Officer (COO)">
                          Chief Operating Officer (COO)
                        </option>
                        <option value="Chief Technology Officer (CTO)">
                          Chief Technology Officer (CTO)
                        </option>
                        <option value="President">President</option>
                        <option value="Vice President">Vice President</option>
                        <option value="Manager">Manager</option>
                      </Form.Select>
                      <span className="text-danger">
                        As per Invitation letter
                      </span>
                    </div>
                  </div>
                  <div className="d-flex w-50 justify-content-end">
                    <Form.Label className="fw-semibold fs-6 w-50">
                      Place of Issue*
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      style={{ width: "45%", height: "max-content" }}
                      value={formData.placeOfIssue}
                      required
                      onChange={(e) =>
                        handleInputChange("placeOfIssue", e.target.value)
                      }
                    >
                      <option value="">Select State</option>
                      {states_list.map((state, index) => (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </div>
                <div className="d-flex w-100 mb-2">
                  <div className="d-flex w-50">
                    <Form.Label className="fw-semibold fs-6 w-50">
                      Title*
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      style={{ width: "45%", height: "max-content" }}
                      value={formData.title}
                      required
                      onChange={(e) =>
                        handleInputChange("title", e.target.value)
                      }
                    >
                      <option value="">Select Title</option>
                      {titleOptions.map((title, index) => (
                        <option key={index} value={title}>
                          {title}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <div className="d-flex w-50 justify-content-end">
                    <Form.Label className="fw-semibold fs-6 w-50">
                      Surname*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      style={{ width: "45%" }}
                      required
                      value={formData.surname}
                      onChange={(e) =>
                        handleInputChange(
                          "surname",
                          e.target.value.toUpperCase()
                        )
                      }
                    />
                  </div>
                </div>
                <div className="d-flex w-100 mb-2">
                  <div className="d-flex w-50">
                    <Form.Label className="fw-semibold fs-6 w-50">
                      Given Name*
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      style={{ width: "45%" }}
                      value={formData.givenName}
                      onChange={(e) =>
                        handleInputChange(
                          "givenName",
                          e.target.value.toUpperCase()
                        )
                      }
                    />
                  </div>
                </div>
                <div className="d-flex w-100 mb-2">
                  <div className="d-flex w-50">
                    <Form.Label className="fw-semibold fs-6 w-50">
                      Passport No.*
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      style={{ width: "45%" }}
                      value={formData.passportNo}
                      maxLength={8}
                      onChange={(e) =>
                        handleInputChange("passportNo", e.target.value)
                      }
                    />
                  </div>
                  <div className="d-flex w-50 justify-content-end">
                    <Form.Label className="fw-semibold fs-6 w-50">
                      Purpose of Visit*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      style={{ width: "45%" }}
                      value={formData.purposeOfVisit}
                      onChange={(e) =>
                        handleInputChange("purposeOfVisit", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="d-flex w-100 mb-2">
                  <div className="d-flex w-50">
                    <Form.Label className="fw-semibold fs-6 w-50">
                      Date of Issue*
                    </Form.Label>
                    <Form.Control
                      required
                      type="date"
                      style={{ width: "45%" }}
                      value={formData.dateOfIssue}
                      onChange={(e) =>
                        handleInputChange("dateOfIssue", e.target.value)
                      }
                    />
                  </div>
                  <div className="d-flex w-50 justify-content-end">
                    <Form.Label className="fw-semibold fs-6 w-50">
                      Date of Expiry*
                    </Form.Label>
                    <Form.Control
                      required
                      type="date"
                      style={{ width: "45%" }}
                      value={formData.dateOfExpiry}
                      onChange={(e) =>
                        handleInputChange("dateOfExpiry", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="text-danger">
                  Note: The Passport must have a minimum validity of six months.
                </div>
                {/* //document submisssions starts here 
          =========================================
          =========================================
          =========================================
          */}
                <div className="d-flex flex-column">
                  <div className="ps-3 py-3 bg-body-secondary mt-3">
                    <div className="d-flex align-items-center">
                      <h5>SUBMIT YOUR DOCUMENT</h5>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip>
                            When uploading the specified documents to the
                            portal, please have all scanned copies ready. Before
                            uploading, ensure you&#39;ve reviewed the
                            information buttons next to each tab and adhere to
                            the maximum file size of 5MB. Refer to the provided
                            sample pictures for guidance.
                          </Tooltip>
                        }
                      >
                        <img
                          src={infoImg}
                          style={{ height: "6vh" }}
                          alt=""
                          srcset=""
                        />
                      </OverlayTrigger>
                    </div>
                  </div>

                  {/* table starts here=================
            ================================== */}
                  <Table striped bordered hover>
                    <thead>
                      <tr className="text-center">
                        <th scope="col">Documents Required</th>
                        <th scope="col">Choose file</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* ---------Requesting letter----------- */}
                      <tr>
                        <td
                          className="fw-bold text-secondary align-middle text-start"
                          style={{ width: "60%" }}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                  <Tooltip>
                                    Requesting Letter must be printed on the
                                    Applicants Letterhead as per the format
                                    given.
                                  </Tooltip>
                                }
                              >
                                <img src={InfoImg} style={{ height: "48px" }} />
                              </OverlayTrigger>
                              Requesting letter
                            </div>
                            <BiSolidFilePdf
                              className="icon-size text-danger clickable cursor-pointer"
                              onClick={() =>
                                window.open(
                                  pdfLinks["Request Letter"],
                                  "_blank"
                                )
                              }
                            />{" "}
                          </div>
                        </td>

                        <td className="align-middle" style={{ width: "40%" }}>
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="me-3">
                              <label
                                className={
                                  formData.requestingLetter
                                    ? "btn btn-success"
                                    : "btn btn-primary"
                                }
                                htmlFor="requestingLetter"
                              >
                                {formData.requestingLetter
                                  ? "Uploaded"
                                  : "Upload File"}
                              </label>
                              <input
                                style={{ display: "none" }}
                                type="file"
                                name="requestingLetter"
                                id="requestingLetter"
                                accept=".pdf"
                                onChange={(e) =>
                                  handleFileUpload(
                                    "requestingLetter",
                                    e.target.files[0]
                                  )
                                }
                              />
                            </div>
                            {formData.requestingLetter && (
                              <a
                                href={URL.createObjectURL(
                                  formData.requestingLetter
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <BsEye className="icon-size" />
                              </a>
                            )}
                          </div>
                        </td>
                      </tr>
                      {/* ---------invitation letter----------- */}
                      {formData.requestingLetter && (
                        <tr>
                          <td
                            className="fw-bold text-secondary align-middle text-start"
                            style={{ width: "60%" }}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <OverlayTrigger
                                  placement="right"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={
                                    <Tooltip>
                                      For invitation letters in languages other
                                      than English, members must translate the
                                      copies and upload the file.
                                    </Tooltip>
                                  }
                                >
                                  <img
                                    src={InfoImg}
                                    style={{ height: "48px" }}
                                  />
                                </OverlayTrigger>
                                Invitation letter from Overseas
                              </div>
                              <BiSolidFilePdf
                                className="icon-size text-danger clickable cursor-pointer"
                                onClick={() =>
                                  window.open(
                                    pdfLinks["Invitation Letter"],
                                    "_blank"
                                  )
                                }
                              />{" "}
                            </div>
                          </td>

                          <td className="align-middle" style={{ width: "40%" }}>
                            <div className="d-flex justify-content-center align-items-center">
                              <div className="me-3">
                                <label
                                  className={
                                    formData.invitationLetter
                                      ? "btn btn-success"
                                      : "btn btn-primary"
                                  }
                                  htmlFor="invitationLetter"
                                >
                                  {formData.invitationLetter
                                    ? "Uploaded"
                                    : "Upload File"}
                                </label>
                                <input
                                  style={{ display: "none" }}
                                  type="file"
                                  name="invitationLetter"
                                  id="invitationLetter"
                                  accept=".pdf"
                                  onChange={(e) =>
                                    handleFileUpload(
                                      "invitationLetter",
                                      e.target.files[0]
                                    )
                                  }
                                />
                              </div>
                              {formData.invitationLetter && (
                                <a
                                  href={URL.createObjectURL(
                                    formData.invitationLetter
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <BsEye className="icon-size" />
                                </a>
                              )}
                            </div>
                          </td>
                        </tr>
                      )}
                      {/* ---------copy pf passport----------- */}
                      {formData.invitationLetter && (
                        <tr>
                          <td
                            className="fw-bold text-secondary align-middle text-start"
                            style={{ width: "60%" }}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <OverlayTrigger
                                  placement="right"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={
                                    <Tooltip>
                                      Upload the copy as per the format. In case
                                      of re-issued/duplicate Passports,
                                      photocopy of the new passport will be
                                      required with endorsement of previous
                                      passport.
                                    </Tooltip>
                                  }
                                >
                                  <img
                                    src={InfoImg}
                                    style={{ height: "48px" }}
                                  />
                                </OverlayTrigger>
                                Passport Copy
                              </div>
                              <BiSolidFilePdf
                                className="icon-size text-danger clickable cursor-pointer"
                                onClick={() =>
                                  window.open(
                                    pdfLinks["Passport Copy"],
                                    "_blank"
                                  )
                                }
                              />{" "}
                            </div>
                          </td>

                          <td className="align-middle" style={{ width: "40%" }}>
                            <div className="d-flex justify-content-center align-items-center">
                              <div className="me-3">
                                <label
                                  className={
                                    formData.copyOfPassport
                                      ? "btn btn-success"
                                      : "btn btn-primary"
                                  }
                                  htmlFor="copyOfPassport"
                                >
                                  {formData.copyOfPassport
                                    ? "Uploaded"
                                    : "Upload File"}
                                </label>
                                <input
                                  style={{ display: "none" }}
                                  type="file"
                                  name="copyOfPassport"
                                  id="copyOfPassport"
                                  accept=".pdf"
                                  onChange={(e) =>
                                    handleFileUpload(
                                      "copyOfPassport",
                                      e.target.files[0]
                                    )
                                  }
                                />
                              </div>
                              {formData.copyOfPassport && (
                                <a
                                  href={URL.createObjectURL(
                                    formData.copyOfPassport
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <BsEye className="icon-size" />
                                </a>
                              )}
                            </div>
                          </td>
                        </tr>
                      )}
                      {/* ---------Export performance of Financial year----------- */}
                      {formData.copyOfPassport && (
                        <tr>
                          <td
                            className="fw-bold text-secondary align-middle text-start"
                            style={{ width: "60%" }}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <OverlayTrigger
                                  placement="right"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={
                                    <Tooltip>
                                      Document must be duly attested by the
                                      Bank.
                                    </Tooltip>
                                  }
                                >
                                  <img
                                    src={InfoImg}
                                    style={{ height: "48px" }}
                                  />
                                </OverlayTrigger>
                                Export performance of Last Financial Year
                              </div>
                            </div>
                          </td>

                          <td className="align-middle" style={{ width: "40%" }}>
                            <div className="d-flex justify-content-center align-items-center">
                              <div className="me-3">
                                <label
                                  className={
                                    formData.exportPerformance
                                      ? "btn btn-success"
                                      : "btn btn-primary"
                                  }
                                  htmlFor="exportPerformance"
                                >
                                  {formData.exportPerformance
                                    ? "Uploaded"
                                    : "Upload File"}
                                </label>
                                <input
                                  style={{ display: "none" }}
                                  type="file"
                                  name="exportPerformance"
                                  id="exportPerformance"
                                  accept=".pdf"
                                  onChange={(e) =>
                                    handleFileUpload(
                                      "exportPerformance",
                                      e.target.files[0]
                                    )
                                  }
                                />
                              </div>
                              {formData.exportPerformance && (
                                <a
                                  href={URL.createObjectURL(
                                    formData.exportPerformance
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <BsEye className="icon-size" />
                                </a>
                              )}
                            </div>
                          </td>
                        </tr>
                      )}
                      {/* ---------Company Profile----------- */}
                      {formData.exportPerformance && (
                        <tr>
                          <td
                            className="fw-bold text-secondary align-middle text-start"
                            style={{ width: "60%" }}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <OverlayTrigger
                                  placement="right"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={
                                    <Tooltip>
                                      Please upload the company&#39;s profile
                                      brochure/manual or mention the scope of
                                      work in the company&#39;s letter and
                                      upload the file here.
                                    </Tooltip>
                                  }
                                >
                                  <img
                                    src={InfoImg}
                                    style={{ height: "48px" }}
                                  />
                                </OverlayTrigger>
                                Company Profile
                              </div>
                            </div>
                          </td>

                          <td className="align-middle" style={{ width: "40%" }}>
                            <div className="d-flex justify-content-center align-items-center">
                              <div className="me-3">
                                <label
                                  className={
                                    formData.comProfile
                                      ? "btn btn-success"
                                      : "btn btn-primary"
                                  }
                                  htmlFor="comProfile"
                                >
                                  {formData.comProfile
                                    ? "Uploaded"
                                    : "Upload File"}
                                </label>
                                <input
                                  style={{ display: "none" }}
                                  type="file"
                                  name="comProfile"
                                  id="comProfile"
                                  accept=".pdf"
                                  onChange={(e) =>
                                    handleFileUpload(
                                      "comProfile",
                                      e.target.files[0]
                                    )
                                  }
                                />
                              </div>
                              {formData.comProfile && (
                                <a
                                  href={URL.createObjectURL(
                                    formData.comProfile
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <BsEye className="icon-size" />
                                </a>
                              )}
                            </div>
                          </td>
                        </tr>
                      )}
                      {/* ---------additional documents----------- */}
                      {formData.country === "Saudi Arabia" &&
                        formData.comProfile && (
                          <>
                            <tr>
                              <td
                                className="fw-bold text-secondary align-middle text-start"
                                style={{ width: "60%" }}
                              >
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <OverlayTrigger
                                      placement="right"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip>
                                          <p className="text-start">
                                            {" "}
                                            If the recommendation Letter is
                                            issued for Saudi Arabia, applicant
                                            is required to submit the additional
                                            Documents as follows:
                                            <br />
                                            1. Bank Guarantee showing that
                                            Applicant/ Manager is also
                                            authorized to operate a bank account
                                            for or on behalf of firm/company for
                                            at least last one year. <br />
                                            2. ITR copy for previous two years{" "}
                                            <br />
                                            3. Salary certificate issued by the
                                            firm on its letterhead.
                                          </p>
                                        </Tooltip>
                                      }
                                    >
                                      <img
                                        src={InfoImg}
                                        style={{ height: "48px" }}
                                      />
                                    </OverlayTrigger>
                                    Additional Documents
                                  </div>
                                </div>
                              </td>

                              <td
                                className="align-middle"
                                style={{ width: "40%" }}
                              >
                                <div className="d-flex justify-content-center align-items-center">
                                  <div className="me-3">
                                    <label
                                      className={
                                        formData.additionalDoc
                                          ? "btn btn-success"
                                          : "btn btn-primary"
                                      }
                                      htmlFor="additionalDoc"
                                    >
                                      {formData.additionalDoc
                                        ? "Uploaded"
                                        : "Upload File"}
                                    </label>
                                    <input
                                      style={{ display: "none" }}
                                      type="file"
                                      name="additionalDoc"
                                      id="additionalDoc"
                                      onChange={(e) =>
                                        handleFileUpload(
                                          "additionalDoc",
                                          e.target.files[0]
                                        )
                                      }
                                    />
                                  </div>
                                  {formData.additionalDoc && (
                                    <a
                                      href={URL.createObjectURL(
                                        formData.additionalDoc
                                      )}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <BsEye className="icon-size" />
                                    </a>
                                  )}
                                </div>
                              </td>
                            </tr>
                            {/* ---------refrence documents----------- */}
                            <tr>
                              <td
                                className="fw-bold text-secondary align-middle text-start"
                                style={{ width: "60%" }}
                              >
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <OverlayTrigger
                                      placement="right"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip>
                                          <p className="text-start">
                                            If the recommendation Letter is
                                            issued for Saudi Arabia, applicant
                                            is required to submit the Reference
                                            Documents as follows:
                                            <br />
                                            1. Undertaking by the company’s
                                            authorized signatory.
                                            <br />
                                            2. Signature of firm’s authorized
                                            representative on the requesting
                                            letter
                                          </p>
                                        </Tooltip>
                                      }
                                    >
                                      <img
                                        src={InfoImg}
                                        style={{ height: "48px" }}
                                      />
                                    </OverlayTrigger>
                                    Reference Document
                                  </div>
                                </div>
                              </td>

                              <td
                                className="align-middle"
                                style={{ width: "40%" }}
                              >
                                <div className="d-flex justify-content-center align-items-center">
                                  <div className="me-3">
                                    <label
                                      className={
                                        formData.refDoc
                                          ? "btn btn-success"
                                          : "btn btn-primary"
                                      }
                                      htmlFor="refDoc"
                                    >
                                      {formData.refDoc
                                        ? "Uploaded"
                                        : "Upload File"}
                                    </label>
                                    <input
                                      style={{ display: "none" }}
                                      type="file"
                                      name="refDoc"
                                      id="refDoc"
                                      onChange={(e) =>
                                        handleFileUpload(
                                          "refDoc",
                                          e.target.files[0]
                                        )
                                      }
                                    />
                                  </div>
                                  {formData.refDoc && (
                                    <a
                                      href={URL.createObjectURL(
                                        formData.refDoc
                                      )}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <BsEye className="icon-size" />
                                    </a>
                                  )}
                                </div>
                              </td>
                            </tr>
                          </>
                        )}
                      {/* ---------payment summary----------- */}
                      {formData.comProfile && (
                        <>
                          <tr>
                            <td colSpan={2}>
                              <div className="d-flex">
                                <div className="w-50 border-end">
                                  <h6>FEE:</h6>
                                  <div className="d-flex align-items-center">
                                    <div className="d-flex align-items-center w-50">
                                      {formData.region === "Asian" ? (
                                        <AiFillCheckCircle className="text-success fs-4" />
                                      ) : (
                                        <div className="ms-4"></div>
                                      )}
                                      <Form.Label
                                        htmlFor="inputPassword5"
                                        className="ms-2 h6 text-secondary"
                                      >
                                        Asian Countries
                                      </Form.Label>
                                    </div>
                                    <div className="w-50 align-items-center mb-1 fw-semibold">
                                      | Rs. 1000/-
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <div className="d-flex align-items-center w-50">
                                      {formData.region === "Schengen" ? (
                                        <AiFillCheckCircle className="text-success fs-4" />
                                      ) : (
                                        <div className="ms-4"></div>
                                      )}
                                      <Form.Label
                                        htmlFor="inputPassword5"
                                        className="ms-2 h6 text-secondary"
                                      >
                                        Schengen Countries
                                      </Form.Label>
                                    </div>
                                    <div className="w-50 align-items-center mb-1 fw-semibold">
                                      | Rs. 2000/-
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center mt-2">
                                    <div className="d-flex align-items-center w-50">
                                      {formData.region === "Others" ? (
                                        <AiFillCheckCircle className="text-success fs-4" />
                                      ) : (
                                        <div className="ms-4"></div>
                                      )}
                                      <Form.Label
                                        htmlFor="inputPassword5"
                                        className="ms-2 h6 text-secondary"
                                      >
                                        Others
                                      </Form.Label>
                                    </div>
                                    <div className="w-50 align-items-center mb-1 fw-semibold">
                                      | Rs. 2000/-
                                    </div>
                                  </div>
                                  {formData.region === "Others" &&
                                    formData.country && (
                                      <h6 className="text-info">
                                        Country : {formData.country}
                                      </h6>
                                    )}
                                </div>
                                <div className="w-50 ms-2">
                                  <h6>Delivery Mode:</h6>
                                  <div className="d-flex align-items-center">
                                    <div className="d-flex align-items-center w-50">
                                      <Form.Check
                                        type="radio"
                                        id="digitalCopy"
                                        name="deliveryMode"
                                        value="Digital Copy"
                                        checked={
                                          formData.deliveryMode ===
                                          "Digital Copy"
                                        }
                                        onChange={() =>
                                          handleRadioChange("Digital Copy")
                                        }
                                        defaultChecked
                                      />
                                      <Form.Label
                                        htmlFor="digitalCopy"
                                        className="ms-2 h6 text-secondary"
                                      >
                                        Digital Copy
                                      </Form.Label>
                                    </div>
                                    <div className="w-50 align-items-center mb-1 fw-semibold">
                                      | Free
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <div className="d-flex align-items-center w-50">
                                      <Form.Check
                                        type="radio"
                                        id="collectFromChamber"
                                        name="deliveryMode"
                                        value="Collect From Chamber"
                                        checked={
                                          formData.deliveryMode ===
                                          "Collect From Chamber"
                                        }
                                        onChange={() =>
                                          handleRadioChange(
                                            "Collect From Chamber"
                                          )
                                        }
                                      />
                                      <Form.Label
                                        htmlFor="collectFromChamber"
                                        className="ms-2 h6 text-secondary"
                                      >
                                        Collect From Chamber
                                      </Form.Label>
                                    </div>
                                    <div className="w-50 align-items-center mb-1 fw-semibold">
                                      <h6> | Free</h6>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <div className="d-flex align-items-center w-50">
                                      <Form.Check
                                        type="radio"
                                        id="courierPostal"
                                        name="deliveryMode"
                                        value="Courier/Postal Charge"
                                        checked={
                                          formData.deliveryMode ===
                                          "Courier/Postal Charge"
                                        }
                                        onChange={() =>
                                          handleRadioChange(
                                            "Courier/Postal Charge"
                                          )
                                        }
                                      />
                                      <Form.Label
                                        htmlFor="courierPostal"
                                        className="ms-2 h6 text-secondary"
                                      >
                                        Courier/Postal Charge
                                      </Form.Label>
                                    </div>
                                    <div className="w-50 align-items-center mb-1 fw-semibold">
                                      <h6> | Rs. 350/-</h6>
                                    </div>
                                  </div>
                                  {formData.deliveryMode ===
                                    "Collect From Chamber" && (
                                    <span className="text-info">
                                      In the event that the recommendation
                                      letter is obtained from the chamber, the
                                      applicant must submit all the
                                      aforementioned supporting documents to the
                                      chamber desk.
                                    </span>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <div className="d-flex align-items-center w-100 justify-content-center">
                                <h6>
                                  {" "}
                                  <span className="text-secondary">
                                    Total Amount
                                  </span>{" "}
                                  | Rs. {formData.amount}/-
                                </h6>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <div className="d-flex justify-content-center gap-5">
                                <Button
                                  variant="secondary"
                                  type="button"
                                  onClick={handleShow}
                                >
                                  PROCEED PAYMENT
                                </Button>
                                <Button
                                  variant="success"
                                  type="submit"
                                  disabled={!submitFlag || ApiRecomFlag}
                                >
                                  {ApiRecomFlag ? (
                                    <div className="px-4">
                                      <Spinner />
                                    </div>
                                  ) : (
                                    "SUBMIT"
                                  )}
                                </Button>

                                {/*Modal for submitting payment here---------------------------- */}
                                <Modal
                                  show={show}
                                  onHide={handleClose}
                                  size="lg"
                                  aria-labelledby="contained-modal-title-vcenter"
                                  centered
                                >
                                  <Form>
                                    <>
                                      <Modal.Header closeButton>
                                        <Modal.Title>
                                          PAYMENT PROCESS
                                        </Modal.Title>
                                      </Modal.Header>
                                      <Modal.Body>
                                        <Card>
                                          <Card.Header>
                                            {" "}
                                            {/* <div className="d-flex mb-4">
                                              <div
                                                style={{
                                                  height: "5vh",
                                                  width: "auto",
                                                }}
                                                className="me-3"
                                              >
                                                <RiBankFill className="h-100 w-100" />
                                              </div>
                                              <div>
                                                <h6 className="mb-1">
                                                  Your Registerd Bank Acoount
                                                  Details
                                                </h6>
                                                <span>
                                                  Please ensure the above bank
                                                  details are accurate
                                                </span>
                                              </div>
                                            </div> */}
                                            {/* <h4 className="me-3 ">
                                              <Badge bg="info" className="px-3">
                                                Verified
                                              </Badge>
                                            </h4> */}
                                            <div className="w-100 d-flex">
                                              <div className="w-50">
                                                <h6>
                                                  <strong>
                                                    {
                                                      getAuthUserlocal()
                                                        .companyName
                                                    }
                                                  </strong>
                                                </h6>
                                                <h6>
                                                  Membership No. :{" "}
                                                  <strong className="text-secondary">
                                                    {
                                                      getAuthUserlocal()
                                                        .memberShipNo
                                                    }{" "}
                                                  </strong>
                                                </h6>
                                              </div>
                                              <div className="w-50">
                                                <h6>
                                                  Wallet Balance :{" "}
                                                  <strong className="text-secondary">
                                                    ₹{" "}
                                                    {getAuthUserlocal().balance}
                                                    .00
                                                  </strong>
                                                </h6>
                                                <h6>
                                                  valid Upto :{" "}
                                                  <strong className="text-secondary">
                                                    {
                                                      getAuthUserlocal()
                                                        .validUpTo
                                                    }
                                                  </strong>
                                                </h6>
                                              </div>
                                            </div>
                                          </Card.Header>
                                          <Card.Body>
                                            <div className="d-flex flex-column align-items-center justify-content-center">
                                              {/* <div className="d-flex justify-content-between w-100">
                                                <h5>
                                                  Debit Amount :{" "}
                                                  <strong className="text-secondary">
                                                    200
                                                  </strong>
                                                </h5>
                                                <h5>
                                                  Payment Towards :{" "}
                                                  <strong className="text-secondary">
                                                    e-co
                                                  </strong>
                                                </h5>
                                              </div> */}
                                              <div className="w-100 mb-3 d-flex justify-content-between">
                                                {/* <div className="d-flex justify-content-between align-items-center mb-2">
                                                  <h6>UTR NO.</h6>
                                                  <Form.Control
                                                    className="w-75"
                                                    type="text"
                                                    placeholder="Enter Utr No."
                                                    required
                                                    value={formData.utrNo}
                                                    onChange={(e) =>
                                                      handleInputChange(
                                                        "utrNo",
                                                        e.target.value
                                                      )
                                                    }
                                                    maxLength={12}
                                                  />
                                                </div> */}

                                                {/* <div className="d-flex justify-content-between align-items-center mb-2">
                                                  <h6>AMOUNT</h6>
                                                  <Form.Control
                                                    className="w-75"
                                                    type="text"
                                                    placeholder="Enter Amount"
                                                    required
                                                    value={formData.amount}
                                                    disabled
                                                  />
                                                </div> */}

                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Debit Amount"
                                                  className="mb-3"
                                                >
                                                  <Form.Control
                                                    type="email"
                                                    placeholder="name@example.com"
                                                    className="fw-semibold"
                                                    value={totalAmt}
                                                    disabled
                                                  />
                                                </FloatingLabel>
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Payment Towards"
                                                  className="mb-3"
                                                >
                                                  <Form.Control
                                                    type="email"
                                                    placeholder="name@example.com"
                                                    className="fw-semibold"
                                                    value="Recommendation Letter"
                                                    disabled
                                                  />
                                                </FloatingLabel>
                                                {/* <div className="d-flex align-items-center">
                                                  <h6 className="me-3">
                                                    PAYMENT ScreenShot (MAX 3MB)
                                                  </h6>
                                                  <div className="me-3">
                                                    <label
                                                      className={
                                                        formData.paymentScreenShot
                                                          ? "btn btn-success"
                                                          : "btn btn-primary"
                                                      }
                                                      htmlFor="paymentScreenShot"
                                                    >
                                                      {formData.paymentScreenShot
                                                        ? "Uploaded"
                                                        : "Choose File"}
                                                    </label>
                                                    <input
                                                      style={{
                                                        display: "none",
                                                      }}
                                                      type="file"
                                                      accept="image/*"
                                                      name="paymentScreenShot"
                                                      id="paymentScreenShot"
                                                      onChange={(e) =>
                                                        handlePaymentScreenShot(
                                                          "paymentScreenShot",
                                                          e.target.files[0]
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  {formData.paymentScreenShot && (
                                                    <a
                                                      href={URL.createObjectURL(
                                                        formData.paymentScreenShot
                                                      )}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      <BsEye className="icon-size" />
                                                    </a>
                                                  )}
                                                </div> */}
                                              </div>
                                              <div className="border rounded-2 p-3 w-100">
                                                <div className="d-flex align-items-center mb-2">
                                                  <FaInfoCircle className="icon-size me-2" />
                                                  <h5>Informative Note:</h5>
                                                </div>
                                                <ul>
                                                  <li>
                                                    In Case of Rejection, Amount
                                                    will be credited to your
                                                    Wallet.
                                                  </li>
                                                  <li>
                                                    You can monitor Transaction
                                                    entries in your wallet.
                                                  </li>

                                                  <li>
                                                    For any Assistance, contact
                                                    Chamber Desk.
                                                  </li>
                                                </ul>{" "}
                                              </div>
                                            </div>
                                          </Card.Body>
                                          {/* <Card.Footer>
                                            {" "}
                                            <div className="d-flex align-items-center">
                                              <MdTouchApp className="icon-size me-2 mb-3" />
                                              <h6>
                                                Pay to below bank account using
                                                IMPS, RTGS, NEFT
                                              </h6>
                                            </div>
                                            <div className="w-100 d-flex">
                                              <div className="w-50">
                                                <h6>
                                                  Bank Name :{" "}
                                                  <strong className="text-secondary">
                                                    IDFC BANK
                                                  </strong>
                                                </h6>
                                                <h6>
                                                  Name :{" "}
                                                  <strong className="text-secondary">
                                                    Maoom Shaikh
                                                  </strong>
                                                </h6>
                                              </div>
                                              <div className="w-50">
                                                <h6>
                                                  IFSC Code :{" "}
                                                  <strong className="text-secondary">
                                                    FFFFFFFFF
                                                  </strong>
                                                </h6>
                                                <h6>
                                                  Account No. :{" "}
                                                  <strong className="text-secondary">
                                                    IDFC BANK
                                                  </strong>
                                                </h6>
                                              </div>
                                            </div>
                                          </Card.Footer> */}
                                        </Card>
                                      </Modal.Body>
                                      <Modal.Footer className="d-flex justify-content-center text-center">
                                        <Button
                                          variant="success"
                                          type="button"
                                          onClick={handleProceedPayment}
                                        >
                                          CONFIRM PAYMENT
                                        </Button>
                                      </Modal.Footer>
                                    </>
                                  </Form>
                                </Modal>
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </Table>
                  <div className="border rounded-2 p-3">
                    <div className="d-flex align-items-center mb-2">
                      <FaInfoCircle className="icon-size me-2" />
                      <h5
                        className="align-middle"
                        style={{ verticalAlign: "middle" }}
                      >
                        INSTRUCTIONS:
                      </h5>
                    </div>
                    <ul>
                      <li>
                        In Case of Rejection, Amount will be credited to your
                        Wallet.
                      </li>
                      <li>
                        Towards payment transaction detail shown in wallet.
                      </li>
                      <li>
                        Application No. to this transaction will be generated
                        after submission and the debit entries can be tracked in
                        your wallet.
                      </li>
                      <li>For any Assistance, contact Chamber Desk.</li>
                    </ul>{" "}
                  </div>
                </div>
              </Form>
            )}

            {tableFlag && (
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center mt-3">
                  {" "}
                  <h4 className="">VISA RECOMMENDATION LETTER</h4>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip>
                        At the time of applying for recommendation letter make
                        sure all the details are filled in carefully.
                      </Tooltip>
                    }
                  >
                    <img
                      src={infoImg}
                      style={{ height: "6vh" }}
                      alt="Info img"
                      srcset=""
                    />
                  </OverlayTrigger>
                </div>
                <hr className="mt-2 mb-3" />
                <div className="d-flex justify-content-between mb-2">
                  <h4 className="text-secondary">Track Your Status</h4>
                  <input
                    className="form-control w-25"
                    type="search"
                    placeholder="Search By Document No."
                    aria-label="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <Table striped bordered hover>
                  <thead className="text-center">
                    <tr>
                      <th className="align-middle">Sr. No</th>
                      <th className="align-middle">Time</th>
                      <th className="align-middle">Applicant Details</th>
                      <th className="align-middle">Delivery Mode</th>
                      <th className="align-middle">Status</th>
                      <th className="align-middle">Document</th>
                    </tr>
                  </thead>
                  {currentFiltered.length > 0 ? (
                    <>
                      {currentFiltered.map((data, index) => (
                        <tbody>
                          <tr
                            key={filteredData.length - indexOfFirstItem - index}
                          >
                            <td>
                              {filteredData.length - indexOfFirstItem - index}.
                            </td>
                            <td>
                              {" "}
                              <div className="fw-bold text-center">
                                Request starts:
                                <br />
                                <span className="text-danger">
                                  {new Intl.DateTimeFormat("en-GB", {
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                  }).format(new Date(data.createdAt))}
                                </span>
                              </div>
                            </td>

                            <td>
                              <div className="text-start max-content">
                                <h6>
                                  Applicant Name :{" "}
                                  <span className="text-secondary">
                                    {data.title} {data.givenName} {data.surname}
                                  </span>
                                </h6>
                                <h6>
                                  Designation :{" "}
                                  <span className="text-secondary">
                                    {" "}
                                    {data.designation}
                                  </span>
                                </h6>
                                <h6>
                                  Country :{" "}
                                  <span className="text-secondary">
                                    {" "}
                                    {data.country}
                                  </span>
                                </h6>

                                {/* Add other address properties from data */}
                              </div>
                            </td>
                            <td>
                              {data.deliveryMode === "Digital Copy" && "E-copy"}
                              {data.deliveryMode === "Courier/Postal Charge" &&
                                "Courier/Postal"}
                              {data.deliveryMode === "Collect From Chamber" &&
                                "Chamber Desk"}
                            </td>
                            <td>
                              <div>
                                <h6 className="text-success mb-2">
                                  {data.status}{" "}
                                </h6>
                                <div className="d-flex flex-column">
                                  {data.acceptedAt && (
                                    <h6 className="mb-0">
                                      Accepted At:{" "}
                                      <span className="text-secondary">
                                        {data.acceptedAt &&
                                          data.acceptedAt.replace("T", " ")}
                                      </span>
                                    </h6>
                                  )}

                                  {data.remark && (
                                    <h6 className="mb-0">
                                      Remark:{" "}
                                      <span className="text-secondary">
                                        {data.remark}
                                      </span>
                                    </h6>
                                  )}
                                </div>
                              </div>
                            </td>

                            <td>
                              <h6 className="mb-1 text-small text-nowrap">
                                Doc No. : {""}{" "}
                                <span className="text-secondary">
                                  {data.documentNo}
                                </span>
                              </h6>

                              {data.deliveryMode === "Digital Copy" && (
                                <>
                                  <div className="mb-1">
                                    <PopUpModalButton
                                      title="Visa Letter"
                                      fileLink={data.visaLetter}
                                      disabled={!(data.status === "Approved")}
                                    />
                                  </div>

                                  <PopUpModalButton
                                    title="Receipt"
                                    fileLink={data.receiptFile}
                                    disabled={!(data.status === "Approved")}
                                  />
                                </>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={11} className="text-center">
                          No Matching Data
                        </td>
                      </tr>
                    </tbody>
                  )}
                </Table>
                <div className="d-flex justify-content-between">
                  <div>
                    {/* 
            //pagination------------------------------------
            ------------------------------------------------ */}
                    {totalPages > 1 && (
                      <div className="d-flex justify-content-start">
                        <Pagination>
                          <Pagination.Prev
                            onClick={handlePrevPage}
                            disabled={currentPage === 1}
                          />
                          {Array.from(
                            { length: endPage - startPage + 1 },
                            (_, index) => (
                              <Pagination.Item
                                key={index + startPage}
                                active={index + startPage === currentPage}
                                onClick={() =>
                                  handlePageChange(index + startPage)
                                }
                              >
                                {index + startPage}
                              </Pagination.Item>
                            )
                          )}
                          <Pagination.Next
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                          />
                        </Pagination>
                      </div>
                    )}
                  </div>

                  <div>
                    <Button
                      type="button"
                      onClick={() => {
                        setFormFlag(true);
                        setTableFlag(false);
                      }}
                    >
                      + ADD NEW FORM
                    </Button>
                  </div>
                </div>{" "}
              </div>
            )}
          </div>
        </Layout2>
      </Layout>
    </>
  );
};

export default RecommendationLetter;

const pdfLinks = {
  "Request Letter":
    "https://aecci-bucket-new.s3.amazonaws.com/Visa+Recommendation+Letter/TemplateRecommLetters/VisaRequestLetter.pdf",
  "Invitation Letter":
    "https://aecci-bucket-new.s3.amazonaws.com/Visa+Recommendation+Letter/TemplateRecommLetters/VisaInvitationLetter.pdf",
  "Passport Copy":
    "https://aecci-bucket-new.s3.amazonaws.com/Visa+Recommendation+Letter/TemplateRecommLetters/VisaPassportCopy.pdf",
};
