import React, { useState } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import Layout2 from "../../../components/Layouts/Layouts/Layout2";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import { toast } from "react-hot-toast";
import { getURLbyEndPoint } from "../../../store/api";
import axios from "axios";
import { getAuthUserlocal } from "../../../store/services";
import { useAuth } from "../../../context/auth";
import { SketchPicker, BlockPicker } from "react-color";
import Button from "react-bootstrap/Button";

import temp1 from "../../../assets/internationalcollab/intercollab1.png";

const authUserLocal = getAuthUserlocal();
const userId = authUserLocal ? authUserLocal._id : null;

const B2BRegistration = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const [sketchPickerColor, setSketchPickerColor] = useState({
    r: "241",
    g: "112",
    b: "19",
    a: "1",
  });
  // destructuring rgba from state
  const { r, g, b, a } = sketchPickerColor;

  //creating state to store our color and also set color using onChange event for block picker
  const [blockPickerColor, setBlockPickerColor] = useState("#37d67a");
  const [b2bFormData, setB2BFormData] = useState({
    companyName: "",
    category: "",
    email: "",
    PurposeOfSelect: "",
    themeColor: "rgba(241, 112, 19, 1)",
    companyImg: null,
    dateTitle: "",
    description1: "",
    description2: "",
    description3: "",
    description4: "",
    location: "",
    websiteLink: "",
  });

  const imageUrl =
    b2bFormData.companyImg instanceof File
      ? URL.createObjectURL(b2bFormData.companyImg)
      : "";

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setB2BFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const MAX_FILE_SIZE_BYTES = 500 * 1024; // 500KB in bytes

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Check if the file size is less than or equal to 5 MB (in bytes)
      const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

      if (file.size <= maxSize) {
        // Update the form data with the selected image
        setB2BFormData({
          ...b2bFormData,
          companyImg: file,
        });

        // Set the selected image in the state
        setSelectedImage(URL.createObjectURL(file));
      } else {
        // File size exceeds the limit, you can handle this according to your requirements
        console.error(
          "File size exceeds the limit (5 MB). Please select a smaller file."
        );
      }
    }
  };

  const handleb2bformsubmission = async (e) => {
    e.preventDefault();

    // Create a new FormData object
    const formData = new FormData();

    // Append all form data to FormData
    Object.entries(b2bFormData).forEach(([key, value]) => {
      formData.append(key, value);
    });

    console.log(b2bFormData);
    try {
      const res = await axios.post(
        getURLbyEndPoint("clientB2BColloboration") + userId,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important for file upload
          },
        }
      );
      if (res.data.status) {
        alert("Form submitted successfully!!");
        window.location.reload();
        console.log(b2bFormData);
      }
    } catch (e) {
      console.log(e);
      toast.error("wrong enteries are entered");
    }
  };

  return (
    <>
      <Layout>
        <Layout2>
          <DashboardHeader />
          <form
            className="d-flex flex-column"
            onSubmit={handleb2bformsubmission}
          >
            <h4 className="mt-4">B2B Collabration Portal</h4>
            <hr className="mt-2 mb-4" />

            {/* //start here */}
            <div className="d-flex gap-3">
              <div className="w-50">
                <div className="mb-1 py-2 d-flex flex-column justify-content-between align-items-start">
                  <label className="fw-semibold fs-6 mb-3">COMPANY NAME</label>
                  <input
                    className="form-control rounded-0 "
                    onChange={handleInputChange}
                    value={b2bFormData.companyName}
                    name="companyName"
                    type="text"
                    required
                  />
                </div>
                <div className="mb-1 py-2 d-flex flex-column justify-content-between align-items-start">
                  <label className="fw-semibold fs-6 mb-3">CATEGORY</label>
                  <input
                    className="form-control rounded-0 "
                    onChange={handleInputChange}
                    value={b2bFormData.category}
                    name="category"
                    type="text"
                    required
                  />
                </div>
                <div className="mb-1 py-2 d-flex flex-column justify-content-between align-items-start">
                  <label className="fw-semibold fs-6 mb-3">EMAIL ID</label>
                  <input
                    className="form-control rounded-0 "
                    onChange={handleInputChange}
                    value={b2bFormData.email}
                    name="email"
                    type="email"
                    required
                  />
                </div>
                <div className="mb-1 py-2 d-flex flex-column justify-content-between align-items-start">
                  <label className="fw-semibold fs-6 mb-3">
                    PURPOSE OF REGISTRATION
                  </label>
                  <select
                    className="form-control rounded-0"
                    onChange={handleInputChange}
                    value={b2bFormData.PurposeOfSelect}
                    name="PurposeOfSelect"
                    required
                  >
                    <option value="">Select an option</option>
                    <option value="Exhibitions">Exhibitions</option>
                    <option value="Event Promotion">Event Promotion</option>
                    <option value="Partnership">
                      Professional & Trade Partnership
                    </option>
                  </select>
                </div>
                {/* <div className="mb-1 py-2 d-flex flex-column justify-content-between align-items-start">
                  <label className="fw-semibold fs-6 mb-3">
                    COLLABORATION TITTLE
                  </label>
                  <input
                    className="form-control rounded-0 "
                    onChange={handleInputChange}
                    value={b2bFormData.collaborationTitle}
                    name="collaborationTitle"
                    type="text"
                    required
                  />
                </div> */}
                <div className="mb-1 py-2 d-flex flex-column justify-content-between align-items-start">
                  <label className="fw-semibold fs-6 mb-3">THEME COLOUR</label>
                  <SketchPicker
                    onChange={(color) => {
                      const themeColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                      setSketchPickerColor(color.rgb);
                      // Set theme color in b2bFormData
                      setB2BFormData((prevFormData) => ({
                        ...prevFormData,
                        themeColor: themeColor,
                      }));
                    }}
                    color={sketchPickerColor}
                  />
                </div>
              </div>
              <div
                className="w-50 p-2 d-flex flex-column justify-content-between"
                style={{ border: "1px solid #000" }}
              >
                <div
                  className="h-25 mb-2"
                  style={{
                    border: "1px solid #000",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: `url(${selectedImage}) center/cover no-repeat`, // Set background image
                  }}
                >
                  <label htmlFor="fileInput" style={{ cursor: "pointer" }}>
                    Upload your company logo
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    accept="image/*" // Allow only image files
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </div>

                <div className="my-2">
                  <input
                    className="form-control rounded-0"
                    onChange={handleInputChange}
                    value={b2bFormData.dateTitle}
                    name="dateTitle"
                    placeholder="Add date: November 07-09, 2023 ot Title"
                    maxLength={20}
                    type="text"
                    required
                    style={{ border: "1px solid #000" }}
                  />
                </div>

                <div
                  className="h-50 mb-2 pt-2 px-2 d-flex flex-column align-items-center justify-content-between"
                  style={{
                    border: "1px solid #000",
                  }}
                >
                  <textarea
                    className="form-control rounded-0 mb-2"
                    onChange={handleInputChange}
                    value={b2bFormData.description1}
                    name="description1"
                    placeholder="Add some description"
                    type="text"
                    maxLength={150}
                    required
                    style={{ border: "1px solid #000", height: "20%" }}
                  />{" "}
                  <textarea
                    className="form-control rounded-0 mb-2"
                    onChange={handleInputChange}
                    value={b2bFormData.description2}
                    name="description2"
                    placeholder="Add some description"
                    maxLength={150}
                    type="text"
                    required
                    style={{ border: "1px solid #000", height: "20%" }}
                  />{" "}
                  <textarea
                    className="form-control rounded-0 mb-2"
                    onChange={handleInputChange}
                    value={b2bFormData.description3}
                    name="description3"
                    placeholder="Add some description"
                    maxLength={150}
                    type="text"
                    required
                    style={{ border: "1px solid #000", height: "20%" }}
                  />{" "}
                  <textarea
                    className="form-control rounded-0 mb-2"
                    onChange={handleInputChange}
                    value={b2bFormData.description4}
                    name="description4"
                    placeholder="Add some description"
                    maxLength={150}
                    type="text"
                    required
                    style={{ border: "1px solid #000", height: "20%" }}
                  />{" "}
                </div>

                <div className="mt-2 mb-2">
                  <input
                    className="form-control rounded-0"
                    onChange={handleInputChange}
                    value={b2bFormData.location}
                    name="location"
                    placeholder="Location"
                    type="text"
                    maxLength={30}
                    required
                    style={{ border: "1px solid #000" }}
                  />
                </div>
                <div className="my-2">
                  <input
                    className="form-control rounded-0"
                    onChange={handleInputChange}
                    value={b2bFormData.websiteLink}
                    name="websiteLink"
                    placeholder="Website link"
                    type="text"
                    required
                    style={{ border: "1px solid #000" }}
                  />
                </div>
              </div>
            </div>

            {/* end here */}
            <div className=" d-flex justify-content-end gap-4">
              <div style={{ width: "20%" }}>
                <div style={{ width: "100%" }}>
                  <Button
                    type="button"
                    className="mt-4 p-2 border-none w-100"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    style={{ backgroundColor: "#ed9808" }}
                  >
                    PREVIEW
                  </Button>{" "}
                </div>
                {/* Modal */}
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabIndex={-1}
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">
                          Preview
                        </h1>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        />
                      </div>
                      <div className="modal-body">
                        <div
                          className="pt-3 text-center"
                          style={{
                            backgroundColor: b2bFormData.themeColor, // Use the themeColor from your form data
                          }}
                        >
                          {imageUrl && (
                            <img
                              src={imageUrl}
                              className="px-4 object-fit-cover"
                              style={{ width: "100%", maxHeight: "20rem" }}
                              alt=""
                            />
                          )}
                          <h3 className="py-3 text-light">
                            {b2bFormData.dateTitle}
                          </h3>
                          <div className="bg-light mx-4 text-black px-2 py-2">
                            <h6>♦ {b2bFormData.description1}</h6>
                            <h6>♦ {b2bFormData.description2}</h6>
                            <h6>♦ {b2bFormData.description3}</h6>
                            <h6>♦ {b2bFormData.description4}</h6>
                            <h5>📌{b2bFormData.location}</h5>
                          </div>
                          <div>
                            <div className="py-3">
                              <button
                                type="button"
                                class="btn btn-outline-light"
                              >
                                Read more
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Button
                type="submit"
                className="mt-4 p-2 border-none"
                style={{ width: "20%", backgroundColor: "#0b3b5d" }}
              >
                UPDATE
              </Button>
            </div>
          </form>
        </Layout2>
      </Layout>
    </>
  );
};

export default B2BRegistration;
